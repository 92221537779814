.Breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 0;
  opacity: 0.6;
  font-size: 13px;
  font-weight: 500; }
  .Breadcrumbs a {
    text-decoration: none; }
    .Breadcrumbs a:hover {
      text-decoration: underline; }

.Button {
  margin: 10px 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid transparent;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  color: #fff;
  outline: 0;
  cursor: pointer;
  transition: 150ms all ease-in-out;
  position: relative; }
  .Button:focus {
    outline: 1px dashed #777; }
  .Button .ContentLoader {
    position: absolute;
    left: 6px;
    padding: 0; }
    .Button .ContentLoader .ContentLoader-spinner {
      margin-right: 10px;
      border-top-color: #ffffff; }
    .Button .ContentLoader .ContentLoader-text {
      display: none; }
  .Button-size-sm {
    border-radius: 2px;
    font-size: 12px;
    font-weight: 400;
    height: 24px;
    padding: 0 20px; }
    .Button-size-sm .ContentLoader-spinner {
      width: 10px;
      height: 10px;
      border-width: 1px; }
  .Button-size-md {
    border-radius: 3px;
    font-size: 13px;
    font-weight: 500;
    height: 34px;
    padding: 0 30px; }
    .Button-size-md .ContentLoader-spinner {
      width: 13px;
      height: 13px;
      border-width: 1px; }
  .Button-size-lg {
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    height: 44px;
    padding: 0 40px; }
    .Button-size-lg .ContentLoader-spinner {
      width: 15px;
      height: 15px;
      border-width: 1px; }
  .Button-theme-default {
    background: #ffffff;
    border: 1px solid #ccc;
    color: #555; }
    .Button-theme-default .ContentLoader-spinner {
      border-top-color: #464854 !important; }
  .Button-theme-info {
    background: #2e86de;
    border: 1px solid #1f72c4; }
  .Button-theme-success {
    background: #10ac84;
    border: 1px solid #0d8767; }
  .Button-theme-warning {
    background: #ff9f43;
    border: 1px solid #ff8a1a; }
  .Button-theme-error {
    background: #ee5253;
    border: 1px solid #ea2d2e; }
  .Button-theme-link {
    background: transparent;
    border: none;
    color: #464854;
    text-transform: initial;
    box-shadow: none; }
    .Button-theme-link .ContentLoader-spinner {
      border-top-color: #464854 !important; }
  .Button-feature-unavailable {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eee;
    border: 1px solid #ccc;
    color: #555; }
    .Button-feature-unavailable svg.Button-disabled-icon {
      margin-right: 5px; }
  .Button:hover {
    opacity: 0.9; }
  .Button:disabled {
    opacity: 0.3;
    cursor: not-allowed; }
    .Button:disabled:hover {
      opacity: 0.3; }

.Checkbox {
  display: inline-block;
  margin-right: 20px; }
  .Checkbox.Checkbox-disabled label {
    color: #707070; }
  .Checkbox label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-weight: 400;
    font-size: 13px;
    color: #464854;
    text-align: left; }
  .Checkbox input {
    position: relative;
    -webkit-appearance: none;
            appearance: none;
    height: 18px;
    width: 18px;
    border: 1px solid #e3e3e3;
    outline: none;
    vertical-align: bottom;
    cursor: pointer;
    margin-right: 6px;
    background-color: #fff;
    margin-left: 0; }
    .Checkbox input.has-error {
      border-color: #ee5253; }
    .Checkbox input:hover, .Checkbox input:focus {
      border: 1px solid #44486c; }
    .Checkbox input:checked {
      border: 1px solid #44486c;
      background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MCA1MCI+PHRpdGxlPmljb25zPC90aXRsZT48cG9seWdvbiBwb2ludHM9IjEgMzIuMzUgNi4zOCAyNC43MSAxOS41MyAzMy45OCA0MS4zNiAzIDQ5IDguMzggMjEuNzkgNDcgMSAzMi4zNSIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: center center; }
    .Checkbox input:disabled {
      opacity: 0.3;
      cursor: not-allowed; }
  .Checkbox .Tooltip {
    display: inline-block; }
    .Checkbox .Tooltip svg {
      cursor: help;
      vertical-align: super;
      margin-left: 5px; }

.CheckboxSelector {
  position: relative; }
  .CheckboxSelector .selector-label {
    display: flex;
    align-items: center;
    font-size: 12px;
    position: absolute;
    left: 10px;
    top: 7px;
    z-index: 8;
    pointer-events: none; }
    .CheckboxSelector .selector-label svg {
      margin-right: 10px; }
  .CheckboxSelector .selector-inner__control .selector-inner__multi-value {
    display: none; }
  .CheckboxSelector .selector-inner__control .selector-inner__input {
    display: block !important;
    position: relative; }
    .CheckboxSelector .selector-inner__control .selector-inner__input input {
      width: 100% !important;
      cursor: pointer;
      color: transparent; }
  .CheckboxSelector .selector-inner__option {
    display: flex;
    align-items: center;
    padding: 5px; }
    .CheckboxSelector .selector-inner__option .checkbox-option {
      display: flex;
      flex-shrink: 0; }
    .CheckboxSelector .selector-inner__option:hover input {
      border: 1px solid #44486c; }
    .CheckboxSelector .selector-inner__option label {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      font-weight: 400;
      font-size: 13px;
      color: #464854;
      text-align: left; }
    .CheckboxSelector .selector-inner__option input {
      position: relative;
      -webkit-appearance: none;
              appearance: none;
      height: 18px;
      width: 18px;
      border: 1px solid #e3e3e3;
      outline: none;
      vertical-align: bottom;
      cursor: pointer;
      margin-right: 6px;
      background-color: #fff; }
      .CheckboxSelector .selector-inner__option input.has-error {
        border-color: #ee5253; }
      .CheckboxSelector .selector-inner__option input:checked {
        border: 1px solid #44486c;
        background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MCA1MCI+PHRpdGxlPmljb25zPC90aXRsZT48cG9seWdvbiBwb2ludHM9IjEgMzIuMzUgNi4zOCAyNC43MSAxOS41MyAzMy45OCA0MS4zNiAzIDQ5IDguMzggMjEuNzkgNDcgMSAzMi4zNSIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: center center; }
      .CheckboxSelector .selector-inner__option input:disabled {
        opacity: 0.3;
        cursor: not-allowed; }

.ClipboardRow .text-input-cont {
  position: relative;
  max-width: 450px; }
  .ClipboardRow .text-input-cont .TextInput input {
    max-width: 450px; }
  .ClipboardRow .text-input-cont .TextArea textarea {
    padding-right: 60px;
    min-height: 80px; }
  .ClipboardRow .text-input-cont .clipboard-btn {
    position: absolute;
    right: 1px;
    top: 1px;
    z-index: 2;
    -webkit-appearance: none;
    background-color: #d8d8d8;
    height: 93%;
    bottom: 1px;
    border: none;
    width: 34x;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none; }
    .ClipboardRow .text-input-cont .clipboard-btn:hover {
      background-color: #e2e2e2; }

.ColorPicker {
  position: relative;
  display: inline-flex; }
  .ColorPicker .color-select {
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0px 10px 0px 0px;
    border: 1px solid #eaeaea;
    min-width: 100px; }
    .ColorPicker .color-select .color-container {
      height: 22px;
      width: 22px;
      margin-right: 10px;
      border-right: 1px solid #eaeaea; }
  .ColorPicker .Dropdown {
    padding: 0;
    left: 0;
    right: unset;
    box-shadow: none;
    border: 1px solid #eaeaea; }
    .ColorPicker .Dropdown .chrome-picker {
      border-radius: 0 !important;
      box-shadow: none !important; }
      .ColorPicker .Dropdown .chrome-picker div {
        border-radius: 0 !important; }

.ConfirmationPopup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  color: #464854;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 50px 0; }
  .ConfirmationPopup-inner {
    position: relative;
    background-color: #ffffff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    width: 30vw;
    max-width: 500px;
    min-width: 400px; }
    @media screen and (max-width: 800px) {
      .ConfirmationPopup-inner {
        width: 100%;
        max-width: unset; } }
    .ConfirmationPopup-inner .ConfirmationPopup-header {
      padding: 20px 30px 20px 20px;
      font-size: 20px;
      line-height: 1.3;
      font-weight: 600; }
    .ConfirmationPopup-inner .ConfirmationPopup-body {
      padding: 0 20px 20px;
      background-color: #ffffff;
      line-height: 1.4;
      font-size: 15px; }
    .ConfirmationPopup-inner .ConfirmationPopup-footer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      background-color: #f0f0f0;
      padding: 5px 20px; }
      .ConfirmationPopup-inner .ConfirmationPopup-footer.hidden {
        display: none; }
      .ConfirmationPopup-inner .ConfirmationPopup-footer button {
        margin-right: 10px; }
    .ConfirmationPopup-inner .ConfirmationPopup-dismiss {
      position: absolute;
      top: 10px;
      right: 0;
      background: transparent;
      border: none;
      font-size: 15px;
      cursor: pointer;
      opacity: 0.5;
      font-weight: 600;
      outline: 0;
      margin-right: 0 !important; }
      .ConfirmationPopup-inner .ConfirmationPopup-dismiss:hover {
        opacity: 1; }
  .ConfirmationPopup-warning {
    padding-top: 20px; }
    .ConfirmationPopup-warning .ConfirmationPopup-inner {
      min-width: 300px;
      max-width: 400px; }
      .ConfirmationPopup-warning .ConfirmationPopup-inner .ConfirmationPopup-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center; }
        .ConfirmationPopup-warning .ConfirmationPopup-inner .ConfirmationPopup-header svg {
          margin-bottom: 10px; }
      .ConfirmationPopup-warning .ConfirmationPopup-inner .ConfirmationPopup-body {
        text-align: center; }

.ContentLoader {
  padding: 50px 0;
  text-align: center; }
  .ContentLoader-spinner {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 3px solid transparent;
    border-radius: 50%;
    border-top-color: #707070;
    animation: spin 750ms linear infinite;
    -webkit-animation: spin 750ms linear infinite; }
  .ContentLoader-text {
    margin-top: 20px;
    font-size: 14px; }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg); } }

.CustomFieldsEditableTable {
  margin-top: 30px; }
  .CustomFieldsEditableTable .List .InputErrorMessage {
    display: none; }
  .CustomFieldsEditableTable .List .rt-noData {
    display: none; }
  .CustomFieldsEditableTable .List .TextInput input {
    max-width: unset; }

.CustomFormTab {
  display: flex;
  align-items: center;
  position: relative; }
  .CustomFormTab.error span {
    color: #ee5253; }
  .CustomFormTab.error svg {
    position: absolute;
    right: -20px; }

.CustomerLabelsSelector {
  position: relative; }
  .CustomerLabelsSelector .CustomerLabelsSelector-option,
  .CustomerLabelsSelector .CustomerLabelsSelector-value {
    display: flex;
    align-items: center; }
    .CustomerLabelsSelector .CustomerLabelsSelector-option .option-color,
    .CustomerLabelsSelector .CustomerLabelsSelector-value .option-color {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      margin-right: 5px; }
  .CustomerLabelsSelector .CustomerLabelsSelector-option {
    width: 100%;
    height: 100%;
    padding: 10px 5px; }
  .CustomerLabelsSelector .CustomerLabelsSelector-value {
    padding-left: 2px; }
  .CustomerLabelsSelector .selector-label {
    display: flex;
    align-items: center;
    font-size: 12px;
    position: absolute;
    left: 10px;
    top: 7px;
    z-index: 9;
    pointer-events: none; }
    .CustomerLabelsSelector .selector-label svg {
      margin-right: 10px; }
  .CustomerLabelsSelector .selector-inner__control .selector-inner__multi-value {
    display: none; }
  .CustomerLabelsSelector .selector-inner__control .selector-inner__input {
    display: block !important;
    position: relative; }
    .CustomerLabelsSelector .selector-inner__control .selector-inner__input input {
      width: 100% !important;
      cursor: pointer;
      color: transparent; }
  .CustomerLabelsSelector .selector-inner__option {
    display: flex;
    align-items: center;
    padding: 0 7px; }
    .CustomerLabelsSelector .selector-inner__option:hover {
      background-color: #eee !important; }
    .CustomerLabelsSelector .selector-inner__option--is-selected {
      background-color: #b2d3f3 !important; }
      .CustomerLabelsSelector .selector-inner__option--is-selected:hover {
        background-color: #b2d3f3 !important; }

.DashboardButton a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  color: #464854;
  text-decoration: none;
  padding: 12px 20px;
  margin: 15px;
  border: 1px solid #e3e3e3;
  background-color: #ffffff;
  height: 120px;
  width: 180px;
  box-sizing: border-box; }
  .DashboardButton a:hover {
    border: 1px solid #44486c; }
  .DashboardButton a svg {
    margin-bottom: 10px;
    flex-shrink: 0; }
  .DashboardButton a h3 {
    margin: 0;
    font-weight: 500; }
  .DashboardButton a p {
    margin: 5px 0 0; }
    .DashboardButton a p span:first-of-type {
      margin-right: 10px; }

.DateInput {
  position: relative;
  max-width: 300px;
  min-width: 300px;
  width: 100%; }
  .DateInput .Dropdown {
    box-shadow: none;
    background: transparent; }
  .DateInput-button {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: calc(34px - 2px);
    position: absolute;
    top: 1px;
    right: 2px;
    border: 0;
    outline: 0;
    opacity: 0.5;
    background: transparent; }
    .DateInput-button:hover {
      opacity: 1; }
  .DateInput input {
    height: 34px;
    width: 100%;
    max-width: 300px;
    min-width: 300px;
    box-sizing: border-box;
    border: 1px solid #e3e3e3;
    outline: none;
    padding: 0 30px 0 10px;
    color: #464854;
    font-size: 14px;
    font-weight: 400; }
    .DateInput input:focus {
      border: 1px solid #44486c; }
    .DateInput input:disabled {
      background: #f5f5f5;
      border-color: #ccc; }
    .DateInput input.has-error {
      border-color: #ee5253; }
  .DateInput-popup {
    position: absolute;
    right: 0;
    z-index: 10;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1); }
    .DateInput-popup .DayPicker {
      border-radius: 0; }

.DatePicker .SingleDatePickerInput {
  border: none;
  max-width: 300px;
  width: 100%; }

.DatePicker .SingleDatePicker .DateInput {
  border: 0;
  width: 100%; }
  .DatePicker .SingleDatePicker .DateInput_input {
    height: 34px;
    padding: 0 10px;
    font-size: 14px;
    border: 1px solid #e3e3e3; }
    .DatePicker .SingleDatePicker .DateInput_input:focus {
      outline: 0;
      border: 1px solid #44486c; }
    .DatePicker .SingleDatePicker .DateInput_input__focused {
      border: 1px solid #44486c; }

.DatePicker {
  display: flex;
  flex-direction: column;
  position: relative;
  white-space: nowrap; }
  .DatePicker.disabled {
    pointer-events: none; }
    .DatePicker.disabled .date-show-cont {
      background-color: #f5f5f5;
      cursor: progress; }
    .DatePicker.disabled .divider {
      background-color: #f5f5f5; }
  .DatePicker .date-show-cont {
    display: flex;
    align-items: center;
    align-self: flex-end;
    justify-content: center;
    padding: 10px;
    border: 1px solid #e3e3e3;
    min-width: 120px;
    color: #464854;
    cursor: pointer;
    outline: none;
    font-size: 14px;
    background: #fff; }
    .DatePicker .date-show-cont svg {
      margin-right: 20px; }
    .DatePicker .date-show-cont .date-show .divider {
      margin: 0 5px;
      background: #fff;
      display: inline; }
  .DatePicker .Dropdown {
    background-color: #fafafa;
    padding: 0;
    box-shadow: none;
    top: 42px;
    z-index: 9;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1); }
    .DatePicker .Dropdown::before {
      content: none; }
    .DatePicker .Dropdown .dropdown-inner {
      display: flex; }
      .DatePicker .Dropdown .dropdown-inner .date-options-cont {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        border: 1px solid #e3e3e3;
        padding: 6px;
        order: 2; }
        .DatePicker .Dropdown .dropdown-inner .date-options-cont button {
          border: none;
          cursor: pointer;
          padding: 10px 20px;
          font-size: 14px;
          font-weight: 500;
          background-color: transparent;
          text-align: right;
          outline: none;
          color: #464854;
          border-radius: 3px; }
          .DatePicker .Dropdown .dropdown-inner .date-options-cont button:hover, .DatePicker .Dropdown .dropdown-inner .date-options-cont button.active {
            background-color: #86b9ec;
            color: #fff; }
      .DatePicker .Dropdown .dropdown-inner .date-picker-cont {
        display: inline;
        border: 1px solid #e3e3e3;
        border-right: none; }
        .DatePicker .Dropdown .dropdown-inner .date-picker-cont .Button {
          display: block;
          margin-left: auto;
          margin-right: 20px;
          margin-top: 0; }
        .DatePicker .Dropdown .dropdown-inner .date-picker-cont .DateRangePickerInput {
          display: flex;
          justify-content: space-evenly;
          flex-wrap: wrap;
          border: none;
          background-color: #fafafa; }
          .DatePicker .Dropdown .dropdown-inner .date-picker-cont .DateRangePickerInput .DateInput {
            display: flex;
            width: 280px;
            margin-top: 10px;
            padding: 0 15px;
            box-sizing: border-box;
            background: transparent;
            order: 1; }
            .DatePicker .Dropdown .dropdown-inner .date-picker-cont .DateRangePickerInput .DateInput .DateInput_input {
              border: 1px solid #e3e3e3;
              padding: 6px 11px 6px;
              text-align: right;
              font-size: 14px;
              font-weight: 500;
              min-width: unset;
              color: #777; }
        .DatePicker .Dropdown .dropdown-inner .date-picker-cont .DateRangePickerInput_arrow {
          display: none; }
        .DatePicker .Dropdown .dropdown-inner .date-picker-cont .DateRangePicker_picker {
          position: initial;
          order: 3; }
          .DatePicker .Dropdown .dropdown-inner .date-picker-cont .DateRangePicker_picker .DayPicker__withBorder {
            box-shadow: none;
            border-radius: 0; }
        .DatePicker .Dropdown .dropdown-inner .date-picker-cont .DayPicker__hidden {
          visibility: visible; }
        .DatePicker .Dropdown .dropdown-inner .date-picker-cont .DayPicker_transitionContainer {
          height: 280px !important;
          background-color: #fafafa; }
          .DatePicker .Dropdown .dropdown-inner .date-picker-cont .DayPicker_transitionContainer .CalendarMonthGrid {
            background-color: #fafafa; }
            .DatePicker .Dropdown .dropdown-inner .date-picker-cont .DayPicker_transitionContainer .CalendarMonthGrid .CalendarMonth {
              background-color: #fafafa; }
              .DatePicker .Dropdown .dropdown-inner .date-picker-cont .DayPicker_transitionContainer .CalendarMonthGrid .CalendarMonth tr td {
                height: 32px !important; }
        .DatePicker .Dropdown .dropdown-inner .date-picker-cont .DayPickerKeyboardShortcuts_show {
          display: none; }
        .DatePicker .Dropdown .dropdown-inner .date-picker-cont .DateInput_fang {
          display: none; }
        .DatePicker .Dropdown .dropdown-inner .date-picker-cont .CalendarDay__selected {
          background: #2e86de;
          border: 1px solid #227cd6; }
          .DatePicker .Dropdown .dropdown-inner .date-picker-cont .CalendarDay__selected_span {
            background: #86b9ec;
            border: 1px solid #519ae4; }
        .DatePicker .Dropdown .dropdown-inner .date-picker-cont .CalendarDay__hovered_span {
          background: #c8dff6;
          border: 1px solid #519ae4; }

.DebugSwitcher {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  position: relative; }
  .DebugSwitcher .Label {
    margin: 0 20px 0 0; }
    .DebugSwitcher .Label svg {
      fill: #464854; }
  .DebugSwitcher .Switcher {
    height: 20px; }

.DescriptionCard {
  margin: 0 0 20px;
  background: #fff;
  border: 1px solid #e3e3e3;
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 14px; }
  .DescriptionCard.sm {
    width: 50%; }
  .DescriptionCard.success {
    background: rgba(16, 172, 132, 0.1); }
  .DescriptionCard.info {
    background: rgba(46, 134, 222, 0.1); }
  .DescriptionCard.error {
    background: rgba(238, 82, 83, 0.1); }
  .DescriptionCard.warning {
    background: rgba(255, 159, 67, 0.1); }
  .DescriptionCard > div {
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid #aaa; }
    .DescriptionCard > div:last-of-type {
      border: 0; }

.DescriptionTable {
  margin: 0 0 20px;
  background: #fff;
  border: 1px solid #e3e3e3;
  padding: 10px 15px;
  font-size: 13px;
  color: #464854;
  font-weight: 500;
  position: relative; }
  .DescriptionTable table {
    width: 100%;
    border-collapse: collapse; }
    .DescriptionTable table tr td {
      width: 70%;
      word-break: break-word;
      box-sizing: border-box;
      padding: 6px 2px;
      border-bottom: 1px solid rgba(227, 227, 227, 0.5); }
      .DescriptionTable table tr td:first-of-type {
        width: 30%;
        min-width: 100px; }
      .DescriptionTable table tr td:last-of-type {
        color: #525462;
        font-weight: 400; }
      .DescriptionTable table tr td .Button {
        margin: 0; }
      .DescriptionTable table tr td .tooltip-header {
        display: flex; }
        .DescriptionTable table tr td .tooltip-header span {
          margin-left: 5px; }
    .DescriptionTable table tr:last-of-type td {
      border: 0; }
    .DescriptionTable table tr.warning td {
      color: #ee5253; }
    .DescriptionTable table tr.success td {
      color: #10ac84; }
  .DescriptionTable .ContentLoader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: #ededed47; }

.DirtyFormAlert {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999999;
  color: #464854;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 0 0 50px; }
  .DirtyFormAlert-inner {
    position: relative;
    background-color: #ffffff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    width: 30vw;
    max-width: 400px;
    min-width: 300px;
    margin-top: 75px; }
    @media screen and (max-width: 500px) {
      .DirtyFormAlert-inner {
        width: 100%;
        max-width: unset; } }
    .DirtyFormAlert-inner .DirtyFormAlert-header {
      padding: 20px 30px 0px 20px;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5; }
    .DirtyFormAlert-inner .DirtyFormAlert-body {
      padding: 0 20px 20px;
      background-color: #ffffff; }
    .DirtyFormAlert-inner .DirtyFormAlert-footer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      background-color: #f0f0f0;
      padding: 5px 20px; }
      .DirtyFormAlert-inner .DirtyFormAlert-footer.hidden {
        display: none; }
      .DirtyFormAlert-inner .DirtyFormAlert-footer button {
        margin-right: 10px; }
    .DirtyFormAlert-inner .DirtyFormAlert-dismiss {
      position: absolute;
      top: 10px;
      right: 0;
      background: transparent;
      border: none;
      font-size: 15px;
      cursor: pointer;
      opacity: 0.5;
      font-weight: 600;
      outline: 0;
      margin-right: 0 !important; }
      .DirtyFormAlert-inner .DirtyFormAlert-dismiss:hover {
        opacity: 1; }

.Dropdown {
  position: absolute;
  top: 30px;
  z-index: 90;
  right: 0;
  white-space: nowrap;
  background-color: #ffffff;
  padding: 10px 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
  .Dropdown::before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: 5px solid #ffffff;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    right: 10px;
    top: -5px; }
  .Dropdown > ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .Dropdown > ul li {
      padding: 0;
      cursor: pointer; }
      .Dropdown > ul li .li-content {
        border: 0;
        padding: 5px 0;
        font-size: 14px;
        display: block;
        text-decoration: none;
        color: #464854; }
      .Dropdown > ul li:last-of-type .li-content {
        padding-bottom: 0; }

.EventStatus {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  border-radius: 2px;
  font-size: 12px;
  padding: 2px 6px;
  color: #f7f7f7;
  width: 100%;
  max-width: 100px; }
  .EventStatus.success {
    background: rgba(16, 172, 132, 0.25);
    border-color: rgba(16, 172, 132, 0.35);
    color: #05372a; }
  .EventStatus.failed {
    background: rgba(238, 82, 83, 0.25);
    border-color: rgba(238, 82, 83, 0.35);
    color: #af1112; }

.Expander .ExpanderItemButton {
  display: flex;
  align-items: center;
  width: unset;
  background-color: #f5f5f5;
  border-top: 2px solid #eaeaea;
  font-weight: 500; }
  .Expander .ExpanderItemButton:hover {
    background-color: #eee; }
  .Expander .ExpanderItemButton::before {
    margin-right: 24px; }

.Expander .ExpanderItemPanel {
  animation-duration: 100ms; }

.FeatureTypePill {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  max-width: 80px;
  padding: 3px 6px;
  font-size: 12px;
  border-width: 1px;
  border-style: solid; }
  .FeatureTypePill-activation {
    background: rgba(46, 134, 222, 0.15);
    border-color: rgba(46, 134, 222, 0.35);
    color: #134679; }
  .FeatureTypePill-consumption {
    background: rgba(16, 172, 132, 0.15);
    border-color: rgba(16, 172, 132, 0.35);
    color: #05372a; }

.FileUploader {
  max-width: 600px; }
  .FileUploader .progress-bar {
    position: relative;
    height: 5px;
    width: 100%;
    max-width: 700px;
    background-color: #fafafa;
    border-radius: 0; }
    .FileUploader .progress-bar-fill {
      background: #2e86de;
      height: 100%;
      transition: width 0.2s ease-in;
      border-radius: 0; }
  .FileUploader .dzu-dropzone {
    background: #ffffff;
    box-shadow: inset 2px 2px 6px #d9d9d9, inset -2px -2px 6px #ffffff, inset 0px 0px 2px #d9d9d9, inset 0px 0px 0px #ffffff;
    border: none;
    overflow: auto;
    max-width: 600px;
    margin: 0;
    padding: 0 20px;
    border-radius: 0; }
    .FileUploader .dzu-dropzone.dzu-dropzoneActive {
      background-color: #e9f5e9; }
    .FileUploader .dzu-dropzone .dzu-inputLabel {
      font-family: inherit;
      color: inherit;
      font-weight: normal;
      font-size: 16px; }
      .FileUploader .dzu-dropzone .dzu-inputLabel .InputContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .FileUploader .dzu-dropzone .dzu-inputLabel .InputContent .title {
          font-weight: 600;
          margin-bottom: 5px; }
        .FileUploader .dzu-dropzone .dzu-inputLabel .InputContent .description {
          font-size: 12px;
          color: #525462; }
          .FileUploader .dzu-dropzone .dzu-inputLabel .InputContent .description span {
            margin: 0 3px; }
    .FileUploader .dzu-dropzone .dzu-previewContainer {
      padding: 0; }
      .FileUploader .dzu-dropzone .dzu-previewContainer img {
        border-radius: 0; }
    .FileUploader .dzu-dropzone .dzu-submitButtonContainer {
      display: flex;
      flex-direction: column;
      margin: 5px 0 0px;
      width: 100%;
      padding: 0; }
    .FileUploader .dzu-dropzone .dzu-submitButton {
      position: relative;
      touch-action: manipulation;
      -webkit-user-select: none;
              user-select: none;
      margin: 10px 0;
      display: inline-flex;
      align-items: center;
      justify-content: space-evenly;
      border: 1px solid transparent;
      color: #ffffff;
      text-align: center;
      font-size: 16px;
      color: #fff;
      outline: 0;
      cursor: pointer;
      transition: 150ms all ease-in-out;
      background: #2e86de;
      border: 1px solid #1f72c4;
      max-width: 120px;
      border-radius: 2px;
      font-size: 13px;
      font-weight: 400;
      height: 24px;
      padding: 0 20px; }
      .FileUploader .dzu-dropzone .dzu-submitButton:focus {
        outline: 1px dashed #777; }
      .FileUploader .dzu-dropzone .dzu-submitButton:hover {
        opacity: 0.9; }
      .FileUploader .dzu-dropzone .dzu-submitButton:disabled {
        opacity: 0.3;
        cursor: not-allowed; }
        .FileUploader .dzu-dropzone .dzu-submitButton:disabled:hover {
          opacity: 0.3; }

.FilterSelect .Selector {
  max-width: 200px; }

.FormStepper-wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 30px; }
  .FormStepper-wrapper .FormStepperItem-wrapper {
    border-right: none; }
  .FormStepper-wrapper .FormStepperItem:last-of-type .FormStepperItem-wrapper {
    border-right: 1px solid #e4e4e5; }
  .FormStepper-wrapper .FormStepperItem:last-of-type .triangle-right {
    display: none; }

.FormStepperItem {
  position: relative; }
  .FormStepperItem-wrapper {
    display: flex;
    border: 1px solid #e4e4e5;
    height: 70px;
    width: 300px;
    background-color: #ffffff; }
    .FormStepperItem-wrapper .status {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 70px; }
      .FormStepperItem-wrapper .status .stepNumber {
        font-size: 30px;
        font-weight: 600; }
    .FormStepperItem-wrapper .meta {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .FormStepperItem-wrapper .meta .title {
        font-weight: 600;
        font-size: 18px; }
      .FormStepperItem-wrapper .meta .description {
        font-size: 13px; }
  .FormStepperItem .triangle-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -12px;
    width: 0;
    z-index: 2;
    height: 0;
    border-style: solid;
    border-width: 12px 0 12px 12px;
    border-color: transparent transparent transparent #e4e4e5; }
    .FormStepperItem .triangle-right::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.6px;
      width: 0;
      z-index: 2;
      height: 0;
      border-style: solid;
      border-width: 11px 0 11px 11px;
      border-color: transparent transparent transparent #ffffff; }
  .FormStepperItem.active .FormStepperItem-wrapper {
    background-color: #f3f4f5; }
    .FormStepperItem.active .FormStepperItem-wrapper .meta .title {
      color: #2e86de; }
  .FormStepperItem.active .triangle-right {
    border-color: transparent transparent transparent #e4e4e5; }
    .FormStepperItem.active .triangle-right::after {
      border-color: transparent transparent transparent #f3f4f5; }

.Forbidden {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0; }
  .Forbidden .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 340px; }
    .Forbidden .wrapper .Subtitle {
      margin-top: 10px;
      text-align: center; }

.GlobalError {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0; }
  .GlobalError .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 340px; }
    .GlobalError .wrapper .Subtitle {
      margin-top: 10px; }
    .GlobalError .wrapper p {
      text-align: center;
      color: #464854; }

.HiddenPasswordField span {
  margin-right: auto;
  padding-right: 5px;
  font-size: 16px; }

.HiddenPasswordField .clipboard-btn {
  display: inline-flex;
  align-items: center;
  z-index: 2;
  background-color: transparent;
  opacity: 0.5;
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
  outline: none; }
  .HiddenPasswordField .clipboard-btn:hover {
    background-color: #e2e2e2;
    opacity: 1; }

.HelpNavigation {
  margin-right: 50px;
  position: relative; }
  .HelpNavigation .help-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500; }
    .HelpNavigation .help-label svg {
      margin-right: 5px; }
    .HelpNavigation .help-label span {
      color: #ffffff; }
  .HelpNavigation .Dropdown {
    padding: 0; }
    .HelpNavigation .Dropdown .li-content {
      cursor: pointer;
      outline: none;
      padding: 10px 15px !important; }
      .HelpNavigation .Dropdown .li-content:hover {
        background-color: #eee; }

.ImportStatusCell {
  display: flex;
  align-items: center;
  font-size: 13px; }
  .ImportStatusCell svg {
    margin-right: 5px; }

.InputErrorMessage {
  margin-top: 2px;
  color: #e91a1c;
  font-size: 13px; }

.Label {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  margin-top: 14px; }
  .Label .Label-elem {
    font-weight: 500;
    font-size: 13px;
    color: #525462;
    text-align: left;
    margin-right: 5px; }
  .Label .Tooltip {
    position: relative; }
    .Label .Tooltip span {
      position: absolute;
      top: -12px; }
      .Label .Tooltip span svg {
        cursor: help;
        vertical-align: super; }

.LabelTag {
  font-size: 11px;
  margin: 10px 10px 0 0;
  padding: 2px 0 2px 6px;
  color: #f7f7f7;
  display: flex;
  align-items: center; }
  .LabelTag .label-delete-btn {
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    cursor: pointer;
    background: transparent;
    outline: none;
    padding: 0; }

.List .ReactTable {
  background: #fff;
  border: 1px solid #ededed;
  font-size: 14px; }
  .List .ReactTable .rt-table {
    transform: rotateX(180deg); }
  .List .ReactTable .rt-thead.-header {
    box-shadow: none;
    font-weight: 600;
    transform: rotateX(180deg);
    order: 1; }
    .List .ReactTable .rt-thead.-header .rt-th {
      display: flex;
      align-items: center;
      padding: 8px 10px;
      color: #464854;
      outline: 0;
      text-align: left;
      white-space: pre-wrap;
      word-break: break-word; }
      .List .ReactTable .rt-thead.-header .rt-th.-sort-asc {
        box-shadow: none;
        background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYxOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTI4Ni45MzUsMTk3LjI4N0wxNTkuMDI4LDY5LjM4MWMtMy42MTMtMy42MTctNy44OTUtNS40MjQtMTIuODQ3LTUuNDI0cy05LjIzMywxLjgwNy0xMi44NSw1LjQyNEw1LjQyNCwxOTcuMjg3ICAgQzEuODA3LDIwMC45MDQsMCwyMDUuMTg2LDAsMjEwLjEzNHMxLjgwNyw5LjIzMyw1LjQyNCwxMi44NDdjMy42MjEsMy42MTcsNy45MDIsNS40MjUsMTIuODUsNS40MjVoMjU1LjgxMyAgIGM0Ljk0OSwwLDkuMjMzLTEuODA4LDEyLjg0OC01LjQyNWMzLjYxMy0zLjYxMyw1LjQyNy03Ljg5OCw1LjQyNy0xMi44NDdTMjkwLjU0OCwyMDAuOTA0LDI4Ni45MzUsMTk3LjI4N3oiIGZpbGw9IiM1NTU1NTUiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
        background-repeat: no-repeat;
        background-position: calc(100% - 6px) center;
        background-size: 10px;
        background-color: rgba(254, 202, 87, 0.2); }
      .List .ReactTable .rt-thead.-header .rt-th.-sort-desc {
        box-shadow: none;
        background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTI4Ni45MzUsNjkuMzc3Yy0zLjYxNC0zLjYxNy03Ljg5OC01LjQyNC0xMi44NDgtNS40MjRIMTguMjc0Yy00Ljk1MiwwLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0ICAgQzEuODA3LDcyLjk5OCwwLDc3LjI3OSwwLDgyLjIyOGMwLDQuOTQ4LDEuODA3LDkuMjI5LDUuNDI0LDEyLjg0N2wxMjcuOTA3LDEyNy45MDdjMy42MjEsMy42MTcsNy45MDIsNS40MjgsMTIuODUsNS40MjggICBzOS4yMzMtMS44MTEsMTIuODQ3LTUuNDI4TDI4Ni45MzUsOTUuMDc0YzMuNjEzLTMuNjE3LDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N0MyOTIuMzYyLDc3LjI3OSwyOTAuNTQ4LDcyLjk5OCwyODYuOTM1LDY5LjM3N3oiIGZpbGw9IiM1NTU1NTUiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
        background-repeat: no-repeat;
        background-position: calc(100% - 6px) center;
        background-size: 10px;
        background-color: rgba(254, 202, 87, 0.2); }
      .List .ReactTable .rt-thead.-header .rt-th.text-center {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center; }
      .List .ReactTable .rt-thead.-header .rt-th.editable-column {
        position: relative;
        padding-left: 18px; }
        .List .ReactTable .rt-thead.-header .rt-th.editable-column div {
          width: 100%; }
        .List .ReactTable .rt-thead.-header .rt-th.editable-column .custom-header {
          display: flex;
          align-items: center; }
          .List .ReactTable .rt-thead.-header .rt-th.editable-column .custom-header svg {
            flex-shrink: 0;
            position: absolute;
            left: 2px;
            top: 4px; }
      .List .ReactTable .rt-thead.-header .rt-th .tooltip-header {
        display: flex; }
        .List .ReactTable .rt-thead.-header .rt-th .tooltip-header span {
          margin-left: 5px; }
  .List .ReactTable .rt-tbody {
    transform: rotateX(180deg); }
    .List .ReactTable .rt-tbody .rt-tr {
      cursor: pointer; }
      .List .ReactTable .rt-tbody .rt-tr .rt-td {
        padding: 8px 10px;
        white-space: pre-wrap;
        font-size: 13px;
        word-break: break-word; }
        .List .ReactTable .rt-tbody .rt-tr .rt-td.text-center {
          text-align: center;
          align-items: center; }
        .List .ReactTable .rt-tbody .rt-tr .rt-td.wrap {
          white-space: pre-wrap; }
        .List .ReactTable .rt-tbody .rt-tr .rt-td.select-container {
          padding: 0; }
          .List .ReactTable .rt-tbody .rt-tr .rt-td.select-container button {
            width: 100%;
            height: 100%;
            padding: 8px 10px; }
            .List .ReactTable .rt-tbody .rt-tr .rt-td.select-container button .Checkbox {
              margin: 0;
              z-index: 0;
              pointer-events: none; }
              .List .ReactTable .rt-tbody .rt-tr .rt-td.select-container button .Checkbox .InputErrorMessage {
                margin-top: 0; }
        .List .ReactTable .rt-tbody .rt-tr .rt-td.action-menu {
          overflow: visible; }
        .List .ReactTable .rt-tbody .rt-tr .rt-td.rt-expandable {
          padding: 0; }
          .List .ReactTable .rt-tbody .rt-tr .rt-td.rt-expandable .rt-expander {
            padding: 8px 10px;
            height: 100%;
            margin: 0; }
        .List .ReactTable .rt-tbody .rt-tr .rt-td.customer-label {
          padding: 0 4px 0 10px; }
          .List .ReactTable .rt-tbody .rt-tr .rt-td.customer-label .customerLabels {
            display: flex;
            align-items: center;
            height: 100%; }
            .List .ReactTable .rt-tbody .rt-tr .rt-td.customer-label .customerLabels .label-titles {
              margin-right: auto; }
            .List .ReactTable .rt-tbody .rt-tr .rt-td.customer-label .customerLabels .label-colors-wrapper {
              display: flex;
              justify-content: flex-end;
              flex-wrap: wrap;
              max-width: 30px;
              flex-shrink: 0; }
            .List .ReactTable .rt-tbody .rt-tr .rt-td.customer-label .customerLabels .label-color {
              width: 10px;
              height: 10px;
              margin: 2px; }
        .List .ReactTable .rt-tbody .rt-tr .rt-td svg {
          vertical-align: middle; }
        .List .ReactTable .rt-tbody .rt-tr .rt-td .Button {
          margin-bottom: 0; }
      .List .ReactTable .rt-tbody .rt-tr.-odd {
        background: #f5f5f5; }
    .List .ReactTable .rt-tbody .-odd + .SubComponent {
      background: #f5f5f5; }
    .List .ReactTable .rt-tbody .SubComponent .DescriptionTable {
      margin: 20px;
      margin-top: 5px;
      box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.1); }
    .List .ReactTable .rt-tbody .SubComponent-actions {
      padding: 0 20px; }
  .List .ReactTable .-pagination {
    box-shadow: none;
    border-top: 1px solid #ededed; }
  .List .ReactTable .edit-button {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 4px;
    opacity: 0.7;
    outline: 0; }
    .List .ReactTable .edit-button:hover {
      opacity: 1; }
  .List .ReactTable .table-button,
  .List .ReactTable .reset-button {
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    width: 100%;
    height: 100%;
    opacity: 0.8; }
    .List .ReactTable .table-button:hover,
    .List .ReactTable .reset-button:hover {
      opacity: 1; }
  .List .ReactTable .delete-button,
  .List .ReactTable .table-select {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0; }
  .List .ReactTable .color-cell {
    display: flex;
    align-items: center; }
    .List .ReactTable .color-cell .color-container {
      width: 16px;
      height: 16px;
      margin-right: 5px; }

.List.List-clickable .ReactTable .rt-tbody .rt-tr:hover {
  background: rgba(254, 202, 87, 0.15); }

.List .ReactTable .-loading > div {
  top: 20px; }

.MissingPermissions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0; }
  .MissingPermissions .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 340px; }
    .MissingPermissions .wrapper .Subtitle {
      margin-top: 10px; }
    .MissingPermissions .wrapper p {
      text-align: center;
      color: #464854; }
  .MissingPermissions-image svg {
    max-width: 300px;
    width: 100%;
    height: auto;
    margin-top: 30px;
    margin-bottom: 10px; }

.Modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  color: #464854;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 50px 0; }
  .Modal .modal-inner {
    position: relative;
    background-color: #ffffff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    max-width: 1000px; }
    .Modal .modal-inner-size-sm {
      width: 30vw;
      min-width: 400px;
      max-width: 450px; }
      @media screen and (max-width: 800px) {
        .Modal .modal-inner-size-sm {
          min-width: unset; } }
    .Modal .modal-inner-size-md {
      width: 40vw;
      min-width: 500px; }
      @media screen and (max-width: 800px) {
        .Modal .modal-inner-size-md {
          min-width: unset; } }
    .Modal .modal-inner-size-lg {
      width: 60vw;
      min-width: 600px; }
      @media screen and (max-width: 800px) {
        .Modal .modal-inner-size-lg {
          min-width: unset; } }
    .Modal .modal-inner-size-xl {
      width: 100vw;
      min-width: 1440px; }
      @media screen and (max-width: 800px) {
        .Modal .modal-inner-size-xl {
          min-width: unset; } }
    @media screen and (max-width: 800px) {
      .Modal .modal-inner {
        width: 100%; } }
    .Modal .modal-inner .modal-header {
      padding: 20px 10px 20px 20px;
      font-size: 24px;
      font-weight: 500; }
    .Modal .modal-inner .modal-body {
      padding: 0 20px 20px;
      background-color: #ffffff; }
    .Modal .modal-inner .modal-footer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      background-color: #f0f0f0;
      padding: 5px 20px; }
      .Modal .modal-inner .modal-footer.hidden {
        display: none; }
      .Modal .modal-inner .modal-footer button {
        margin-right: 10px; }
    .Modal .modal-inner .modal-dismiss {
      position: absolute;
      top: 10px;
      right: 0;
      background: transparent;
      border: none;
      font-size: 15px;
      cursor: pointer;
      opacity: 0.5;
      font-weight: 600;
      outline: 0; }
      .Modal .modal-inner .modal-dismiss:hover {
        opacity: 1; }

.MultiEmailInput {
  background-color: #ffffff;
  width: 100%;
  min-width: 300px;
  max-width: 400px;
  border: 1px solid #e3e3e3;
  outline: none;
  padding: 10px;
  color: #464854;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box; }
  .MultiEmailInput:focus {
    border: 1px solid #44486c; }
  .MultiEmailInput:disabled {
    background: #f5f5f5;
    border-color: #ccc; }
  .MultiEmailInput.has-error {
    border-color: #ee5253; }
  .MultiEmailInput .input {
    -webkit-appearance: none;
    appearance: none;
    height: 34px;
    width: 100%;
    max-width: 400px;
    min-width: 300px;
    border: none;
    outline: none;
    padding: 0 10px 0 0;
    color: #464854;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    font-family: Arial, sans-serif; }
  .MultiEmailInput.isDisabled {
    background: #f5f5f5;
    border-color: #ccc; }
    .MultiEmailInput.isDisabled input {
      background: #f5f5f5; }
  .MultiEmailInput .tag-item {
    background-color: #d4d5d6;
    font-size: 12px;
    padding: 2px 0px 2px 6px;
    display: inline-flex;
    align-items: center;
    margin: 0 8px 4px 0;
    border-radius: 2px;
    word-break: break-word; }
    .MultiEmailInput .tag-item button {
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent;
      width: 22px;
      height: 22px;
      border: none;
      cursor: pointer;
      margin-left: 10px;
      font-weight: bold;
      padding: 0;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center; }

.NavLinkGroup-children {
  margin-left: 40px;
  margin-top: -10px; }
  .NavLinkGroup-children a {
    display: block;
    font-size: 14px;
    text-decoration: none;
    color: #464854;
    margin-top: 5px; }
    .NavLinkGroup-children a.NavSubLink {
      font-weight: 600; }

.NotFound-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px 0; }
  .NotFound-container .Subtitle {
    margin-top: 10px; }
  .NotFound-container p {
    text-align: center;
    color: #464854; }

.Notice {
  font-family: "IBM Plex Sans", "Helvetica", "Arial", sans-serif;
  color: #464854;
  border-width: 1px;
  border-style: solid;
  margin: 10px 0; }
  .Notice .Notice-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 6px; }
  .Notice .Notice-body {
    font-size: 14px;
    color: #525462; }
  .Notice-size--lg {
    padding: 30px; }
  .Notice-size--md {
    padding: 20px; }
  .Notice-size--sm {
    padding: 10px; }
    .Notice-size--sm .Notice-title {
      font-size: 16px; }
    .Notice-size--sm .Notice-body {
      font-size: 14px; }
  .Notice-theme--success {
    background: rgba(16, 172, 132, 0.15);
    border-color: rgba(16, 172, 132, 0.35); }
    .Notice-theme--success .Notice-title {
      color: #05372a; }
    .Notice-theme--success .Notice-body {
      color: #09664e; }
  .Notice-theme--error {
    background: rgba(238, 82, 83, 0.15);
    border-color: rgba(238, 82, 83, 0.35); }
    .Notice-theme--error .Notice-title {
      color: #af1112; }
    .Notice-theme--error .Notice-body {
      color: rgba(175, 17, 18, 0.8); }
  .Notice-theme--warning {
    background: rgba(254, 202, 87, 0.15);
    border-color: rgba(254, 202, 87, 0.35); }
    .Notice-theme--warning .Notice-title {
      color: #bb8101; }
    .Notice-theme--warning .Notice-body {
      color: #d49301; }
  .Notice-theme--info {
    background: rgba(46, 134, 222, 0.15);
    border-color: rgba(46, 134, 222, 0.35); }
    .Notice-theme--info .Notice-title {
      color: #134679; }
    .Notice-theme--info .Notice-body {
      color: #1a60a5; }
  .Notice .actionable-notice {
    display: flex;
    flex-direction: column; }
    .Notice .actionable-notice .actions {
      display: flex; }
      .Notice .actionable-notice .actions .Button {
        margin: 10px 0 0; }

.Notification {
  font-family: "IBM Plex Sans", "Helvetica", "Arial", sans-serif;
  color: #464854; }
  .Notification .Notification-title {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 6px; }
  .Notification .Notification-body {
    font-size: 13px;
    color: #525462; }

.Notification-container {
  position: relative; }
  .Notification-container.Toastify__toast {
    padding: 15px 15px 15px 15px;
    border-left: 10px solid #fff; }
  .Notification-container.Toastify__toast--success {
    background: #fff;
    border-color: #10ac84; }
  .Notification-container.Toastify__toast--error {
    background: #fff;
    border-color: #ee5253; }
  .Notification-container.Toastify__toast--warning {
    background: #fff;
    border-color: #feca57; }
  .Notification-container.Toastify__toast--info {
    background: #fff;
    border-color: #2e86de; }
  .Notification-container .toast-dismiss {
    position: absolute;
    top: 10px;
    right: 0;
    background: transparent;
    border: none;
    font-size: 5px;
    cursor: pointer;
    opacity: 0.5;
    font-weight: 600;
    outline: 0; }
    .Notification-container .toast-dismiss:hover {
      opacity: 1; }

.NumberInput {
  position: relative; }
  .NumberInput input {
    height: 34px;
    width: 100%;
    max-width: 300px;
    border: 1px solid #e3e3e3;
    outline: none;
    padding: 0 10px;
    color: #464854;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box; }
    .NumberInput input::-webkit-inner-spin-button {
      opacity: 1; }
    .NumberInput input:disabled {
      background-color: #f2f2f2; }
      .NumberInput input:disabled:hover {
        border: 1px solid #e3e3e3; }
    .NumberInput input:focus, .NumberInput input:hover {
      border: 1px solid #44486c; }
    .NumberInput input.has-error {
      border-color: #ee5253; }
  .NumberInput .Spinner {
    position: absolute;
    right: 31px;
    top: 4px; }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }

.Page {
  height: calc(100vh - 50px);
  width: calc(100% - 260px);
  margin-top: 50px;
  margin-left: 260px;
  float: left;
  overflow-y: auto;
  position: relative; }
  .Page .Page-header {
    padding: 20px 30px;
    box-sizing: border-box; }
  .Page .Page-top {
    height: 20px; }
  .Page .Page-title {
    color: #464854;
    font-size: 30px;
    font-weight: 500;
    margin: 15px 0 10px;
    word-break: break-word; }
  .Page .Page-description {
    color: #525462;
    font-size: 14px; }
  .Page .Page-content {
    padding: 0 30px 50px;
    -webkit-animation-delay: 0;
    -webkit-animation-duration: 100ms;
    -webkit-animation-name: fadeInPage;
    -webkit-animation-timing-function: ease-in;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0;
    -moz-animation-duration: 100ms;
    -moz-animation-name: fadeInPage;
    -moz-animation-timing-function: ease-in;
    -moz-animation-fill-mode: forwards;
    -o-animation-delay: 0;
    -o-animation-duration: 100ms;
    -o-animation-name: fadeInPage;
    -o-animation-timing-function: ease-in;
    -o-animation-fill-mode: forwards;
    animation-delay: 0;
    animation-duration: 100ms;
    animation-name: fadeInPage;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards; }
  .Page.Sidebar-size-sm {
    width: calc(100% - 70px);
    margin-left: 70px; }

@keyframes fadeInPage {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.PageNotice {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 4;
  border-width: 1px;
  border-style: solid;
  padding: 5px 20px;
  font-family: "IBM Plex Sans", "Helvetica", "Arial", sans-serif;
  color: #464854; }
  .PageNotice .PageNotice-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 6px; }
  .PageNotice .PageNotice-body {
    font-size: 14px;
    color: #525462; }
  .PageNotice-theme--error {
    background: #f9d0d0;
    border-color: rgba(238, 82, 83, 0.35); }
    .PageNotice-theme--error .PageNotice-title {
      color: #af1112; }
    .PageNotice-theme--error .PageNotice-body {
      color: rgba(175, 17, 18, 0.8); }
  .PageNotice-theme--warning {
    background: #fbeed1;
    border-color: rgba(254, 202, 87, 0.35); }
    .PageNotice-theme--warning .PageNotice-title {
      color: #bb8101; }
    .PageNotice-theme--warning .PageNotice-body {
      color: #d49301; }
  .PageNotice-theme--success {
    background: #c9e7dc;
    border-color: rgba(16, 172, 132, 0.35); }
    .PageNotice-theme--success .PageNotice-title {
      color: #05372a; }
    .PageNotice-theme--success .PageNotice-body {
      color: #09664e; }
  .PageNotice-theme--info {
    background: #c8ddf3;
    border-color: rgba(46, 134, 222, 0.35); }
    .PageNotice-theme--info .PageNotice-title {
      color: #134679; }
    .PageNotice-theme--info .PageNotice-body {
      color: #1a60a5; }

.PasswordInput {
  position: relative;
  max-width: 300px; }
  .PasswordInput input {
    height: 34px;
    width: 100%;
    max-width: 300px;
    min-width: 300px;
    border: 1px solid #e3e3e3;
    outline: none;
    padding: 0 10px;
    color: #464854;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box; }
    .PasswordInput input:focus {
      border: 1px solid #44486c; }
    .PasswordInput input:disabled {
      background: #f5f5f5;
      border-color: #ccc; }
    .PasswordInput input.has-error {
      border-color: #ee5253; }
  .PasswordInput .spinner {
    position: absolute;
    right: 31px;
    top: 4px; }
  .PasswordInput button {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    -webkit-appearance: none;
    border: none;
    outline: none; }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }

.PermissionCheck {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; }
  .PermissionCheck .Subtitle {
    text-align: center; }
  .PermissionCheck-main {
    display: flex; }
    .PermissionCheck-main .label {
      margin-right: 10px;
      font-size: 14px; }
    .PermissionCheck-main .value {
      font-size: 14px;
      color: #ee5253; }
  .PermissionCheck-image svg {
    max-width: 300px;
    width: 100%;
    height: auto;
    margin-top: 30px;
    margin-bottom: 10px; }
  .PermissionCheck-support {
    font-size: 14px; }

.PermissionDenied {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; }
  .PermissionDenied .Subtitle {
    text-align: center; }
  .PermissionDenied-main {
    display: flex; }
    .PermissionDenied-main .label {
      margin-right: 10px;
      font-size: 14px; }
    .PermissionDenied-main .value {
      font-size: 14px;
      color: #ee5253; }
  .PermissionDenied-image svg {
    max-width: 300px;
    width: 100%;
    height: auto;
    margin-top: 30px;
    margin-bottom: 10px; }
  .PermissionDenied-support {
    font-size: 14px; }

.PermissionMissingNotificationTitle {
  display: flex;
  flex-wrap: wrap; }
  .PermissionMissingNotificationTitle .label {
    margin-right: 6px; }
  .PermissionMissingNotificationTitle .value {
    color: #ee5253; }

.PhoneNumberInput {
  position: relative; }
  .PhoneNumberInput .PhoneInputCountryIcon {
    height: auto;
    width: 30px;
    box-shadow: none; }
  .PhoneNumberInput input {
    height: 34px;
    width: 100%;
    max-width: 250px;
    min-width: 200px;
    border: 1px solid #e3e3e3;
    outline: none;
    padding: 0 10px;
    color: #464854;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    font-family: Arial, sans-serif; }
    .PhoneNumberInput input:focus {
      border: 1px solid #44486c; }
    .PhoneNumberInput input:disabled {
      background: #f5f5f5;
      border-color: #ccc; }
  .PhoneNumberInput .PhoneNumberInput-main.has-error input {
    border-color: #ee5253; }

.PlanTypeButton-input input {
  position: fixed;
  left: -99999px; }
  .PlanTypeButton-input input:checked + label {
    border-color: #2e86de; }
    .PlanTypeButton-input input:checked + label::after {
      opacity: 1; }
  .PlanTypeButton-input input:not(:checked) + label::after {
    opacity: 0; }

.PlanTypeButton-input label {
  position: relative;
  min-width: 150px;
  cursor: pointer;
  display: inline-block;
  padding: 20px 20px 20px 50px;
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  background: #fff; }
  .PlanTypeButton-input label:hover {
    border-color: #2e86de; }
  .PlanTypeButton-input label::before {
    content: '';
    position: absolute;
    left: 15px;
    top: 35%;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #e3e3e3;
    background: transparent; }
  .PlanTypeButton-input label::after {
    content: '';
    width: 12px;
    height: 12px;
    background: #2e86de;
    border-radius: 50%;
    position: absolute;
    top: 40%;
    left: 19px; }
  .PlanTypeButton-input label .plan-type-name {
    font-weight: 600;
    font-size: 18px; }
  .PlanTypeButton-input label .plan-type-desc {
    color: #525462;
    font-size: 12px;
    margin-top: 2px; }

.PlanTypeButton-disabled {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50); }
  .PlanTypeButton-disabled label {
    cursor: not-allowed; }
    .PlanTypeButton-disabled label:hover {
      border-color: #e3e3e3; }

.ProductFeaturesEditableTable {
  margin-top: 30px; }
  .ProductFeaturesEditableTable .List .InputErrorMessage {
    display: none; }
  .ProductFeaturesEditableTable .List .rt-table {
    overflow: visible; }
  .ProductFeaturesEditableTable .List .rt-tbody {
    overflow: visible; }
  .ProductFeaturesEditableTable .List .rt-tr {
    overflow: visible; }
  .ProductFeaturesEditableTable .List .rt-td {
    overflow: visible; }
    .ProductFeaturesEditableTable .List .rt-td .Selector {
      min-width: 100px; }
    .ProductFeaturesEditableTable .List .rt-td .NumberInput {
      width: 100%; }
    .ProductFeaturesEditableTable .List .rt-td .DateInput,
    .ProductFeaturesEditableTable .List .rt-td .TextArea {
      width: 100%;
      min-width: unset; }
      .ProductFeaturesEditableTable .List .rt-td .DateInput input,
      .ProductFeaturesEditableTable .List .rt-td .DateInput textarea,
      .ProductFeaturesEditableTable .List .rt-td .TextArea input,
      .ProductFeaturesEditableTable .List .rt-td .TextArea textarea {
        min-width: 100px; }
    .ProductFeaturesEditableTable .List .rt-td .expiry-date {
      display: flex;
      align-items: center; }
      .ProductFeaturesEditableTable .List .rt-td .expiry-date .Checkbox {
        margin-right: 0; }
  .ProductFeaturesEditableTable .List .rt-noData {
    display: none; }

.ProfileNavigation {
  position: relative; }
  .ProfileNavigation .profile-label {
    display: flex;
    align-items: center;
    line-height: 20px;
    font-size: 12px;
    font-weight: 600;
    z-index: 36;
    opacity: 0.8;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    text-align: center; }
    .ProfileNavigation .profile-label .sidebar-item {
      display: flex;
      align-items: center; }
      .ProfileNavigation .profile-label .sidebar-item svg {
        margin-right: 5px; }
  .ProfileNavigation .Dropdown {
    padding: 0; }
    .ProfileNavigation .Dropdown button {
      text-align: start;
      width: 100%;
      background-color: transparent; }
    .ProfileNavigation .Dropdown .li-content {
      cursor: pointer;
      outline: none;
      padding: 10px 15px !important; }
      .ProfileNavigation .Dropdown .li-content:hover {
        background-color: #eee; }

.ProgressBar {
  position: relative;
  height: 15px;
  background-color: #e3e3e3;
  margin-bottom: 40px; }
  .ProgressBar-fill {
    height: 100%;
    transition: width 0.2s ease-in; }

.RadioBtn {
  display: flex;
  margin-top: 10px;
  margin-bottom: 5px; }
  .RadioBtn label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
    color: #464854; }
    .RadioBtn label span {
      display: flex; }
      .RadioBtn label span .Tooltip {
        position: relative; }
        .RadioBtn label span .Tooltip span {
          position: absolute;
          top: -2px;
          left: 4px; }
          .RadioBtn label span .Tooltip span svg {
            cursor: help;
            vertical-align: super; }
  .RadioBtn input {
    margin-right: 10px;
    margin-left: 0;
    margin-top: 0; }
  .RadioBtn-disabled label {
    color: #707070; }

.RichTextEditorToolbar {
  display: flex;
  align-items: center; }
  .RichTextEditorToolbar-tools {
    display: flex;
    flex-wrap: wrap; }
    .RichTextEditorToolbar-tools button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 4px;
      width: 30px;
      height: 30px;
      padding: 6px;
      border: none;
      background-color: transparent;
      cursor: pointer; }
      .RichTextEditorToolbar-tools button:hover {
        background-color: #e3e3e3; }
  .RichTextEditorToolbar-button.active {
    background-color: #e3e3e3; }

.RichTextEditor {
  position: relative;
  min-width: 300px;
  border: 1px solid #e3e3e3;
  background-color: #fff; }
  .RichTextEditor .tiptap {
    min-height: 150px;
    width: 100%;
    outline: none;
    padding: 0 10px 10px 10px;
    color: #464854;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    resize: none;
    font-family: 'IBM Plex Sans', 'Helvetica', 'Arial', sans-serif; }
    .RichTextEditor .tiptap p.is-editor-empty:first-child::before {
      color: #adb5bd;
      content: attr(data-placeholder);
      float: left;
      height: 0;
      pointer-events: none; }
    .RichTextEditor .tiptap p:first-of-type {
      margin-top: 6px; }
    .RichTextEditor .tiptap img {
      max-width: 500px;
      object-fit: contain; }
    .RichTextEditor .tiptap h1,
    .RichTextEditor .tiptap h2,
    .RichTextEditor .tiptap h3,
    .RichTextEditor .tiptap h4,
    .RichTextEditor .tiptap h5,
    .RichTextEditor .tiptap h6 {
      line-height: 1.1;
      margin-top: 2.5rem;
      text-wrap: pretty; }
    .RichTextEditor .tiptap h1,
    .RichTextEditor .tiptap h2 {
      margin-top: 3.5rem;
      margin-bottom: 1.5rem; }
    .RichTextEditor .tiptap h1 {
      font-size: 1.4rem; }
    .RichTextEditor .tiptap h2 {
      font-size: 1.2rem; }
    .RichTextEditor .tiptap h3 {
      font-size: 1.1rem; }
    .RichTextEditor .tiptap h4,
    .RichTextEditor .tiptap h5,
    .RichTextEditor .tiptap h6 {
      font-size: 1rem; }

.Option {
  display: flex;
  align-items: center; }
  .Option:hover {
    background: #eee; }
  .Option--selected {
    background: #b2d3f3; }
  .Option--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: transparent; }
  .Option-LockIcon {
    margin: 0 0 0 5px; }

.Selector {
  max-width: 400px;
  height: 34px;
  font-size: 14px;
  position: relative; }
  .Selector .Spinner {
    position: absolute;
    right: 31px;
    top: 6px; }
  .Selector .selector-inner__control {
    border-color: #e3e3e3;
    cursor: pointer;
    border-radius: 0;
    min-height: 34px; }
    .Selector .selector-inner__control:hover {
      border-color: #44486c; }
    .Selector .selector-inner__control.selector-inner__control--is-focused {
      border-color: #44486c;
      box-shadow: none; }
    .Selector .selector-inner__control .selector-inner__multi-value {
      background-color: #b2d3f3; }
    .Selector .selector-inner__control .selector-inner__indicators .selector-inner__indicator-separator {
      display: none; }
    .Selector .selector-inner__control .selector-inner__indicators .selector-inner__indicator svg {
      color: #464854;
      height: 15px;
      width: 15px; }
  .Selector .selector-inner__menu {
    margin-top: 0;
    border-radius: 0;
    border: 1px solid #44486c;
    box-shadow: none;
    z-index: 9; }
    .Selector .selector-inner__menu .selector-inner__menu-list {
      max-height: 320px; }
    .Selector .selector-inner__menu .selector-inner__option {
      color: #464854;
      cursor: pointer;
      background-color: transparent; }
      .Selector .selector-inner__menu .selector-inner__option.selector-inner__option--is-focused {
        background-color: #e3e3e3; }
      .Selector .selector-inner__menu .selector-inner__option.selector-inner__option--is-disabled {
        cursor: not-allowed; }
        .Selector .selector-inner__menu .selector-inner__option.selector-inner__option--is-disabled:hover {
          background: transparent; }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }

.SelectedCustomFieldsTags {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .SelectedCustomFieldsTags .CFTag {
    cursor: default;
    margin-right: 12px;
    margin-bottom: 5px;
    padding: 2px 6px;
    border: 1px solid #f1f1f1;
    border-left: 2px solid #10ac84;
    background: #eff7f5; }
    .SelectedCustomFieldsTags .CFTag-inner {
      display: flex; }
      .SelectedCustomFieldsTags .CFTag-inner span {
        font-size: 12px; }
      .SelectedCustomFieldsTags .CFTag-inner svg {
        margin-left: 5px;
        flex-shrink: 0; }

.cf-tags-tooltip-inner {
  display: flex;
  flex-direction: column; }

.SelectedFeaturesTags {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .SelectedFeaturesTags .SFTag {
    cursor: default;
    margin-right: 12px;
    margin-bottom: 5px;
    padding: 2px 6px;
    border: 1px solid #f1f1f1;
    border-left: 2px solid #2e86de;
    background: #f0f6fb; }
    .SelectedFeaturesTags .SFTag-inner {
      display: flex; }
      .SelectedFeaturesTags .SFTag-inner span {
        font-size: 12px; }
      .SelectedFeaturesTags .SFTag-inner svg {
        margin-left: 5px;
        flex-shrink: 0; }

.sf-tags-tooltip-inner {
  display: flex;
  flex-direction: column; }

.Sidebar {
  position: fixed;
  z-index: -1;
  top: 50px;
  background: #fff;
  height: calc(100vh - 50px);
  width: 260px;
  box-sizing: border-box;
  border-right: 1px solid rgba(112, 112, 112, 0.2);
  float: left;
  overflow-y: auto; }
  .Sidebar a {
    text-decoration: none;
    font-size: 14px;
    color: #464854;
    display: block;
    margin: 10px 0; }
    .Sidebar a:hover {
      color: #111; }
    .Sidebar a.active {
      color: #2e86de;
      font-weight: 500; }
      .Sidebar a.active:hover {
        color: #2e86de; }
      .Sidebar a.active .NavLink-submenu {
        display: block; }
      .Sidebar a.active svg {
        fill: #2e86de; }
  .Sidebar .toggle-container {
    position: absolute;
    right: 0;
    top: 20px; }
    .Sidebar .toggle-container button {
      cursor: pointer;
      -webkit-appearance: none;
              appearance: none;
      padding: 0;
      border: 1px solid #eaeaea;
      border-right: none;
      outline: none;
      background: #fafafa; }
      .Sidebar .toggle-container button svg {
        width: 20px;
        height: 20px;
        stroke: #464854;
        stroke-width: 2; }
  .Sidebar-sm {
    width: 70px; }
    .Sidebar-sm .toggle-container {
      top: 0; }
    .Sidebar-sm .Company-plan {
      display: none; }
    .Sidebar-sm .CompanySidebar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 30px 0; }
      .Sidebar-sm .CompanySidebar .NavLink header {
        margin: 0; }
        .Sidebar-sm .CompanySidebar .NavLink header .NavLink-icon {
          width: unset;
          margin-right: 0; }
          .Sidebar-sm .CompanySidebar .NavLink header .NavLink-icon svg {
            height: 24px;
            width: 24px; }
        .Sidebar-sm .CompanySidebar .NavLink header .NavLink-text {
          display: none; }
      .Sidebar-sm .CompanySidebar .NavLinkGroup-children {
        display: none; }
    .Sidebar-sm .version-cont__sidebar {
      width: 58px; }
      .Sidebar-sm .version-cont__sidebar .label {
        display: none; }

.SimpleFilterTag {
  display: flex;
  align-items: center;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  margin-bottom: 5px;
  margin-right: 10px;
  border-width: 1px;
  background: rgba(254, 202, 87, 0.25);
  border-color: rgba(254, 202, 87, 0.35);
  color: #bb8101; }
  .SimpleFilterTag:disabled {
    background: rgba(254, 202, 87, 0.05); }
  .SimpleFilterTag p {
    margin: 2px 0; }
  .SimpleFilterTag svg {
    margin-left: 4px;
    fill: #885e01; }

.SlidePane-overlay {
  z-index: 99; }

.SlidePane {
  max-width: 1400px; }
  .SlidePane .slide-pane__header {
    background: #f3f4f5;
    flex-basis: 60px;
    height: 60px; }
    .SlidePane .slide-pane__header .slide-pane__close {
      margin-left: 16px; }
      .SlidePane .slide-pane__header .slide-pane__close svg {
        width: 20px; }
    .SlidePane .slide-pane__header .slide-pane__title-wrapper .slide-pane__title {
      font-size: 24px;
      font-weight: 500;
      color: #464854;
      max-width: 100%;
      padding-right: 40px; }
  .SlidePane .slide-pane__content {
    padding: 12px 24px; }

.Spinner {
  width: 50px;
  text-align: center; }
  .Spinner > div {
    width: 6px;
    height: 6px;
    margin-right: 3px;
    background-color: #707070;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both; }
  .Spinner .bounce1 {
    animation-delay: -0.32s; }
  .Spinner .bounce2 {
    animation-delay: -0.16s; }

.StatusCard {
  display: inline-flex;
  align-items: center;
  color: inherit; }
  .StatusCard > span {
    margin-right: 7px;
    border-radius: 50%;
    height: 7px;
    width: 7px; }
  .StatusCard-success {
    background: #10ac84; }
  .StatusCard-warning {
    background: #feca57; }
  .StatusCard-error {
    background: #ee5253; }

.Subtitle {
  color: #464854;
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0; }

.react-tabs__tab-list {
  border-bottom: 2px solid #e8e8e8; }

.react-tabs__tab {
  font-weight: 500;
  padding: 10px 30px;
  outline: 0;
  bottom: -1px;
  border: 0;
  border-bottom: 2px solid transparent;
  color: rgba(70, 72, 84, 0.8);
  font-size: 15px; }
  .react-tabs__tab:focus, .react-tabs__tab:hover {
    border: 0;
    border-bottom: 2px solid transparent;
    box-shadow: none;
    outline: 0;
    color: #464854; }
  .react-tabs__tab:after {
    display: none; }
  .react-tabs__tab--selected {
    background: transparent;
    color: #2e86de;
    border: 0;
    border-bottom: 2px solid #2e86de;
    outline: 0; }
    .react-tabs__tab--selected:focus, .react-tabs__tab--selected:hover {
      border: 0;
      border-bottom: 2px solid #2e86de;
      box-shadow: none;
      outline: 0;
      color: #2e86de; }
  .react-tabs__tab--disabled {
    color: rgba(70, 72, 84, 0.5);
    cursor: not-allowed; }
    .react-tabs__tab--disabled:hover {
      color: rgba(70, 72, 84, 0.5); }

.react-tabs__tab-panel--selected {
  padding: 10px; }
  .react-tabs__tab-panel--selected .Button {
    margin-top: 0; }

.TabContent-not-available {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px; }
  .TabContent-not-available-description {
    margin: 20px 0;
    font-size: 18px;
    font-weight: 500;
    text-align: center; }
  .TabContent-not-available a {
    text-decoration: none;
    background: #2e86de;
    border: 1px solid #1f72c4;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    height: 44px;
    padding: 0 40px;
    margin: 10px 0;
    text-align: center;
    color: #fff;
    outline: 0;
    cursor: pointer;
    display: flex;
    align-items: center; }
    .TabContent-not-available a:hover {
      opacity: 0.9; }

.TextArea {
  position: relative; }
  .TextArea textarea {
    width: 100%;
    min-width: 300px;
    border: 1px solid #e3e3e3;
    outline: none;
    padding: 10px;
    color: #464854;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    resize: none;
    font-family: "IBM Plex Sans", "Helvetica", "Arial", sans-serif; }
    .TextArea textarea:focus {
      border: 1px solid #44486c; }
    .TextArea textarea:disabled {
      background: #f5f5f5;
      border-color: #ccc; }
    .TextArea textarea.has-error {
      border-color: #ee5253; }

.TextInput {
  position: relative; }
  .TextInput input {
    height: 34px;
    width: 100%;
    max-width: 300px;
    min-width: 300px;
    border: 1px solid #e3e3e3;
    outline: none;
    padding: 0 10px;
    color: #464854;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    font-family: Arial, sans-serif; }
    .TextInput input:focus {
      border: 1px solid #44486c; }
    .TextInput input:disabled {
      background: #f5f5f5;
      border-color: #ccc; }
    .TextInput input.has-error {
      border-color: #ee5253; }
  .TextInput .Spinner {
    position: absolute;
    right: 0;
    top: 4px; }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }

.rc-tooltip {
  opacity: 1; }
  .rc-tooltip .rc-tooltip-content {
    max-width: 250px; }
    .rc-tooltip .rc-tooltip-content .rc-tooltip-arrow {
      border-top-color: #555; }
    .rc-tooltip .rc-tooltip-content .rc-tooltip-inner {
      background: #555;
      box-shadow: 0 0 17px rgba(0, 0, 0, 0.2);
      color: #fff;
      border: none;
      border-radius: 2px;
      min-height: 20px;
      padding: 6px 10px; }

.TopHeader {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  background: #555;
  height: 50px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 5;
  padding: 0 30px 0 0; }
  .TopHeader .TopHeader-primary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 260px; }
    .TopHeader .TopHeader-primary > .logo {
      height: 50px;
      width: 70px;
      background: #555; }
      .TopHeader .TopHeader-primary > .logo a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px; }
  .TopHeader .TopHeader-secondary {
    display: flex;
    align-items: center; }
    .TopHeader .TopHeader-secondary .docs-btn {
      margin-right: 20px; }
      .TopHeader .TopHeader-secondary .docs-btn a {
        background: #ffffff;
        border: 1px solid #ccc;
        color: #555;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 400;
        height: 20px;
        padding: 0 20px;
        margin: 10px 0;
        display: inline-flex;
        align-items: center;
        justify-content: space-evenly;
        outline: 0;
        cursor: pointer;
        transition: 150ms all ease-in-out;
        position: relative;
        text-decoration: none; }

.Widget {
  background: #fff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  max-width: 300px;
  min-width: 200px;
  padding: 10px;
  margin: 0 10px; }
  .Widget:first-of-type {
    margin-left: 0; }
  .Widget header {
    font-size: 13px;
    font-weight: 500; }
  .Widget main {
    margin-top: 20px;
    text-align: right;
    font-size: 30px;
    font-weight: 600; }

.WidgetsContainer {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0; }
  .WidgetsContainer header {
    font-size: 13px;
    font-weight: 400;
    color: #525462; }
  .WidgetsContainer main {
    text-align: right;
    font-size: 30px;
    font-weight: 600; }

.CheckboxSelector {
  position: relative;
  max-width: 400px;
  font-size: 14px;
  height: auto;
  min-height: 34px;
  max-height: none;
  /* Make main select container take up its own space */ }
  .CheckboxSelector .selector-label {
    display: flex;
    align-items: center;
    font-size: 12px;
    position: absolute;
    left: 10px;
    top: 7px;
    z-index: 8;
    pointer-events: none; }
    .CheckboxSelector .selector-label svg {
      margin-right: 10px; }
  .CheckboxSelector .selector-inner__control .selector-inner__multi-value {
    display: none; }
  .CheckboxSelector .selector-inner__control .selector-inner__input {
    display: block !important;
    position: relative; }
    .CheckboxSelector .selector-inner__control .selector-inner__input input {
      width: 100% !important;
      cursor: pointer;
      color: transparent; }
  .CheckboxSelector .selector-inner__option {
    display: flex;
    align-items: center;
    padding: 5px; }
    .CheckboxSelector .selector-inner__option .checkbox-option {
      display: flex;
      flex-shrink: 0; }
    .CheckboxSelector .selector-inner__option:hover input {
      border: 1px solid #44486c; }
    .CheckboxSelector .selector-inner__option label {
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      font-weight: 400;
      font-size: 13px;
      color: #464854;
      text-align: left; }
    .CheckboxSelector .selector-inner__option input {
      position: relative;
      -webkit-appearance: none;
              appearance: none;
      height: 18px;
      width: 18px;
      border: 1px solid #e3e3e3;
      outline: none;
      vertical-align: bottom;
      cursor: pointer;
      margin-right: 6px;
      background-color: #fff; }
      .CheckboxSelector .selector-inner__option input.has-error {
        border-color: #ee5253; }
      .CheckboxSelector .selector-inner__option input:checked {
        border: 1px solid #44486c;
        background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MCA1MCI+PHRpdGxlPmljb25zPC90aXRsZT48cG9seWdvbiBwb2ludHM9IjEgMzIuMzUgNi4zOCAyNC43MSAxOS41MyAzMy45OCA0MS4zNiAzIDQ5IDguMzggMjEuNzkgNDcgMSAzMi4zNSIgZmlsbD0iIzMzMyIvPjwvc3ZnPg==);
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: center center; }
      .CheckboxSelector .selector-inner__option input:disabled {
        opacity: 0.3;
        cursor: not-allowed; }
  .CheckboxSelector .selector-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%; }
  .CheckboxSelector .selector-container {
    width: 100%;
    margin-bottom: 8px; }
  .CheckboxSelector .selected-pills {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 8px;
    gap: 8px;
    margin-top: 8px;
    position: static;
    width: 100%;
    overflow-wrap: break-word;
    word-break: break-word; }
  .CheckboxSelector .selected-pill {
    display: inline-flex;
    align-items: center;
    background-color: #f3f4f6;
    border-radius: 16px;
    padding: 4px 8px;
    font-size: 14px;
    max-width: 100%; }
    .CheckboxSelector .selected-pill .pill-label {
      margin-right: 6px;
      overflow: hidden;
      text-overflow: ellipsis; }
    .CheckboxSelector .selected-pill .pill-remove {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      padding: 2px;
      cursor: pointer;
      color: #6b7280;
      border-radius: 50%;
      transition: all 0.2s ease; }
      .CheckboxSelector .selected-pill .pill-remove:hover {
        background-color: #e5e7eb;
        color: #4b5563; }
      .CheckboxSelector .selected-pill .pill-remove:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5); }

.BaseChart {
  padding: 10px 0; }
  .BaseChart-heading {
    padding: 0 10px 5px; }
    .BaseChart-heading .title {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 13px; }
    .BaseChart-heading .subtitle {
      font-size: 12px;
      color: #525462; }
  .BaseChart.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5e9ea; }
    .BaseChart.loading .ContentLoader {
      padding: 0; }

.ChartTooltip {
  background: white;
  padding: 5px;
  font-size: 12px;
  border-radius: 2px;
  box-shadow: 0 0 17px rgba(0, 0, 0, 0.2); }
  .ChartTooltip-date {
    margin-bottom: 4px;
    font-size: 14px;
    font-weight: 500; }
  .ChartTooltip-row {
    margin-bottom: 4px; }

.ConsumptionChart {
  margin-bottom: 40px; }
  .ConsumptionChart .recharts-reference-line text {
    font-size: 11px;
    color: #525462; }
  .ConsumptionChart-actions {
    margin-bottom: 10px; }
    .ConsumptionChart-actions button {
      margin-right: 20px; }

.NoGraphData {
  border: 1px solid #e5e9ea;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: relative; }
  .NoGraphData-label {
    position: absolute;
    top: 10px;
    left: 10px; }
    .NoGraphData-label .title {
      font-weight: 500;
      font-size: 13px; }
    .NoGraphData-label .subtitle {
      font-size: 12px;
      color: #525462; }
  .NoGraphData-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px; }
  .NoGraphData .text {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px; }
  .NoGraphData .description {
    font-size: 12px; }

.rotate-secret-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%; }

.rotate-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem; }

.time-input-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 1rem; }

.time-input {
  width: 100px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px; }

.time-input-label {
  display: flex;
  flex-direction: column; }

.time-input-span {
  font-size: 0.875rem;
  margin-bottom: 0.25rem; }

.rotate-input-wrapper {
  margin-bottom: 1.5rem; }

.rotate-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500; }

.init-checkbox-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  margin-bottom: 1rem; }

.oauth-actions {
  width: 100%; }
  .oauth-actions .credential-cell {
    cursor: default;
    display: flex !important;
    align-items: center !important;
    height: 100% !important; }
  .oauth-actions .credential-actions {
    max-width: 120px;
    cursor: default;
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem; }
  .oauth-actions .icon-button {
    background-color: transparent;
    cursor: pointer;
    padding: 8px;
    border-radius: 9999px;
    border-color: transparent;
    transition: colors 0.2s ease-in-out; }
    .oauth-actions .icon-button svg {
      width: 20px;
      height: 20px; }
    .oauth-actions .icon-button:disabled {
      opacity: 0.5;
      cursor: not-allowed; }
    .oauth-actions .icon-button--refresh {
      color: #059669; }
      .oauth-actions .icon-button--refresh:hover:not(:disabled) {
        color: #047857; }
    .oauth-actions .icon-button--rotate {
      color: #4b5563; }
      .oauth-actions .icon-button--rotate:hover:not(:disabled) {
        color: #374151; }
    .oauth-actions .icon-button--delete {
      color: #dc2626; }
      .oauth-actions .icon-button--delete:hover:not(:disabled) {
        color: #b91c1c; }

.oauth-client-secret-actions {
  display: flex;
  justify-content: justify-start;
  grid-gap: 1rem;
  gap: 1rem; }

.row {
  margin-bottom: 0; }

.credential-cell .credential-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px; }

.secret-expiration-info {
  margin-top: 4px; }
  .secret-expiration-info .expiration-row {
    display: flex;
    align-items: flex-start;
    grid-gap: 8px;
    gap: 8px;
    padding: 8px;
    border-radius: 4px;
    background-color: #f5f5f5; }
    .secret-expiration-info .expiration-row.status-normal {
      background-color: #f0f7ff;
      color: #0066cc; }
    .secret-expiration-info .expiration-row.status-warning {
      background-color: #fff7e6;
      color: #d46b08; }
    .secret-expiration-info .expiration-row.status-expired {
      background-color: #fff1f0;
      color: #cf1322; }
  .secret-expiration-info .expiration-icon {
    width: 16px;
    height: 16px;
    margin-top: 2px; }
  .secret-expiration-info .expiration-details {
    flex: 1 1; }
  .secret-expiration-info .expiration-main {
    font-weight: 500;
    margin-bottom: 2px; }
  .secret-expiration-info .expiration-secondary {
    font-size: 12px;
    opacity: 0.85; }
  .secret-expiration-info .rotation-info {
    margin-top: 4px;
    font-size: 12px;
    font-style: italic; }

.NewCompanyForm .form-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (max-width: 1200px) {
    .NewCompanyForm .form-inner {
      flex-direction: column;
      align-items: center; } }
  .NewCompanyForm .form-inner .Subtitle {
    margin: 10px 0; }
  .NewCompanyForm .form-inner .TextInput,
  .NewCompanyForm .form-inner .NumberInput {
    max-width: 300px; }
    @media screen and (max-width: 1200px) {
      .NewCompanyForm .form-inner .TextInput,
      .NewCompanyForm .form-inner .NumberInput {
        max-width: unset; } }
    .NewCompanyForm .form-inner .TextInput input,
    .NewCompanyForm .form-inner .NumberInput input {
      max-width: unset;
      min-width: unset;
      box-sizing: border-box; }
  .NewCompanyForm .form-inner .hidden {
    visibility: hidden; }

.NewCompanyForm .dev-checkbox {
  margin-top: 5px; }

.NewCompanyForm .linked-acc {
  margin-top: 10px; }
  .NewCompanyForm .linked-acc .Label {
    margin-top: 5px; }

.GenerateReportForm .form-horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column; }
  @media (min-width: 768px) {
    .GenerateReportForm .form-horizontal {
      flex-direction: row; } }

.GenerateReportForm .row .DatePicker .date-show-cont {
  align-self: unset;
  justify-content: flex-start; }

.GenerateReportForm .row .TextInput input {
  max-width: unset; }

.CompanyList .Page {
  width: 100%;
  margin-left: 0; }
  .CompanyList .Page .list-header {
    margin-bottom: 10px; }

.CompanySelector {
  width: calc(260px - 50px); }
  .CompanySelector .company-selector {
    margin: 0;
    position: relative;
    background-color: #707070;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    cursor: pointer; }
    .CompanySelector .company-selector .company-selector-inner__control {
      display: flex;
      height: 50px;
      border: none;
      border-radius: 0;
      background-color: #555;
      width: 100%; }
      .CompanySelector .company-selector .company-selector-inner__control .company-selector-inner__single-value {
        color: #ffffff;
        font-size: 16px;
        font-weight: 600; }
      .CompanySelector .company-selector .company-selector-inner__control .company-selector-inner__input {
        color: #ffffff;
        font-size: 16px; }
        .CompanySelector .company-selector .company-selector-inner__control .company-selector-inner__input input {
          font-weight: 600; }
      .CompanySelector .company-selector .company-selector-inner__control .company-selector-inner__indicator-separator {
        display: none; }
    .CompanySelector .company-selector.company-selector-inner--is-disabled .company-selector-inner__dropdown-indicator {
      opacity: 0; }
    .CompanySelector .company-selector .company-selector-inner__menu {
      background-color: #84888b;
      border-radius: 0;
      margin: 0; }

.SecurityContainer-twofa {
  margin-top: 40px; }
  .SecurityContainer-twofa .image-container svg {
    max-width: 300px;
    width: 100%;
    height: auto;
    margin-top: 30px;
    margin-bottom: 10px; }
  .SecurityContainer-twofa ul {
    padding-inline-start: 15px;
    font-size: 14px;
    color: #525462; }
    .SecurityContainer-twofa ul li {
      margin-bottom: 6px; }
      .SecurityContainer-twofa ul li:last-of-type {
        margin-bottom: 0; }

.ProfileContainer .Sidebar-sm .CompanySidebar {
  margin-top: 0; }

.ProfileContainer .ProfileContainer-link:first-of-type {
  margin-top: 40px; }

.ConsentForm {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .ConsentForm .consent-form-wrapper {
    max-width: 60vw;
    margin-top: 30px;
    margin-bottom: 30px; }
    @media screen and (max-width: 800px) {
      .ConsentForm .consent-form-wrapper {
        max-width: 100%; } }
    .ConsentForm .consent-form-wrapper .consent-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .ConsentForm .consent-form-wrapper .consent-form .row {
        display: flex;
        align-items: flex-start;
        margin: 20px; }
        .ConsentForm .consent-form-wrapper .consent-form .row p {
          margin: 0; }
        .ConsentForm .consent-form-wrapper .consent-form .row a {
          margin: 0 5px; }
        .ConsentForm .consent-form-wrapper .consent-form .row.logo-cont {
          margin: 0; }
        .ConsentForm .consent-form-wrapper .consent-form .row.title-cont {
          margin-top: 5px; }
          .ConsentForm .consent-form-wrapper .consent-form .row.title-cont h1 {
            margin: 0;
            font-weight: 500;
            font-size: 30px;
            text-align: center; }
        .ConsentForm .consent-form-wrapper .consent-form .row.subtitle-cont p {
          font-size: 21px;
          font-weight: 500;
          color: #525462; }

.CustomerLabelsForm .new-label-btn .Button,
.CustomerLabelsForm .back-label-btn .Button {
  padding: 0;
  color: #2e86de; }
  .CustomerLabelsForm .new-label-btn .Button span,
  .CustomerLabelsForm .back-label-btn .Button span {
    margin-right: 3px; }

.CustomersImport .template-link {
  margin: 10px 0; }
  .CustomersImport .template-link a {
    font-size: 14px; }

.CustomersImport .docs-link {
  display: flex; }
  .CustomersImport .docs-link p {
    margin: 0;
    font-size: 14px;
    color: #525462; }
  .CustomersImport .docs-link a {
    color: #525462;
    font-size: 14px;
    margin-left: 3px; }

.CustomersImport-list {
  margin-top: 20px; }
  .CustomersImport-list .heading {
    margin-bottom: 10px;
    font-weight: 500; }

.CustomerForm .form-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (max-width: 1200px) {
    .CustomerForm .form-inner {
      flex-direction: column;
      align-items: center; } }
  .CustomerForm .form-inner .Subtitle {
    margin: 10px 0; }
  .CustomerForm .form-inner .TextInput input,
  .CustomerForm .form-inner .NumberInput input,
  .CustomerForm .form-inner .PhoneNumberInput input {
    max-width: unset;
    min-width: unset;
    box-sizing: border-box; }
  .CustomerForm .form-inner .hidden {
    visibility: hidden; }
  .CustomerForm .form-inner .left {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .CustomerForm .form-inner .left {
        margin-right: 0;
        min-height: unset;
        width: 100%; } }
    .CustomerForm .form-inner .left .Checkbox {
      margin-top: 0 !important; }
    .CustomerForm .form-inner .left .Selector {
      max-width: unset; }
    .CustomerForm .form-inner .left .trial-checkbox {
      margin-top: 20px; }
  .CustomerForm .form-inner .divider {
    display: none;
    margin: 30px 30px 10px;
    background: #e3e3e3;
    width: 1px; }
    @media screen and (max-width: 1200px) {
      .CustomerForm .form-inner .divider {
        display: none; } }
    .CustomerForm .form-inner .divider.hidden {
      display: block;
      visibility: hidden; }
    .CustomerForm .form-inner .divider.active {
      display: block; }
      @media screen and (max-width: 1200px) {
        .CustomerForm .form-inner .divider.active {
          display: none; } }
  .CustomerForm .form-inner .right {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .CustomerForm .form-inner .right {
        margin-left: 0;
        min-height: unset;
        width: 100%; } }
    .CustomerForm .form-inner .right.hidden {
      visibility: hidden; }
    .CustomerForm .form-inner .right .Selector {
      max-width: unset; }
    .CustomerForm .form-inner .right .DateInput {
      max-width: unset;
      min-width: unset;
      width: 100%; }
      .CustomerForm .form-inner .right .DateInput input {
        max-width: unset;
        min-width: unset; }
    .CustomerForm .form-inner .right .flex-row {
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 1200px) {
        .CustomerForm .form-inner .right .flex-row {
          align-items: flex-start; } }
      .CustomerForm .form-inner .right .flex-row .NumberInput {
        flex-grow: 1;
        margin-right: 10px; }
        @media screen and (max-width: 1200px) {
          .CustomerForm .form-inner .right .flex-row .NumberInput {
            max-width: unset;
            width: 50%;
            margin-right: 0; } }
        .CustomerForm .form-inner .right .flex-row .NumberInput input {
          min-width: unset !important;
          margin-right: 10px; }
          @media screen and (max-width: 1200px) {
            .CustomerForm .form-inner .right .flex-row .NumberInput input {
              width: 95% !important;
              margin-right: 0; } }
      .CustomerForm .form-inner .right .flex-row .Selector {
        flex-grow: 1; }
        @media screen and (max-width: 1200px) {
          .CustomerForm .form-inner .right .flex-row .Selector {
            max-width: unset;
            width: 50%; } }
        .CustomerForm .form-inner .right .flex-row .Selector .selector-inner__control {
          min-height: calc(32px + 2px); }

.form-horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column; }
  @media (min-width: 768px) {
    .form-horizontal {
      flex-direction: row; } }

.CustomersList .list-header {
  margin-bottom: 0; }

.CustomersList-filters {
  margin-bottom: 10px; }
  .CustomersList-filters .AdvancedSearch-btn {
    -webkit-appearance: none;
    appearance: none;
    font-size: 14px;
    color: #2e86de;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    outline: none;
    margin-top: 10px;
    padding: 0;
    display: flex;
    align-items: center; }
    .CustomersList-filters .AdvancedSearch-btn svg {
      margin-right: 5px; }
  .CustomersList-filters .AdvancedSearch-wrapper {
    overflow: visible;
    transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1);
    -webkit-animation-delay: 0;
    -webkit-animation-duration: 400ms;
    -webkit-animation-name: visibleOverflow;
    -webkit-animation-timing-function: ease-in;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0;
    -moz-animation-duration: 400ms;
    -moz-animation-name: visibleOverflow;
    -moz-animation-timing-function: ease-in;
    -moz-animation-fill-mode: forwards;
    -o-animation-delay: 0;
    -o-animation-duration: 400ms;
    -o-animation-name: visibleOverflow;
    -o-animation-timing-function: ease-in;
    -o-animation-fill-mode: forwards;
    animation-delay: 0;
    animation-duration: 400ms;
    animation-name: visibleOverflow;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    margin-top: 5px; }
    .CustomersList-filters .AdvancedSearch-wrapper.hide {
      overflow: hidden;
      padding-bottom: 0px;
      -webkit-animation-delay: 0;
      -webkit-animation-duration: 10ms;
      -webkit-animation-name: hiddenOverflow;
      -webkit-animation-timing-function: ease-in;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-delay: 0;
      -moz-animation-duration: 10ms;
      -moz-animation-name: hiddenOverflow;
      -moz-animation-timing-function: ease-in;
      -moz-animation-fill-mode: forwards;
      -o-animation-delay: 0;
      -o-animation-duration: 10ms;
      -o-animation-name: hiddenOverflow;
      -o-animation-timing-function: ease-in;
      -o-animation-fill-mode: forwards;
      animation-delay: 0;
      animation-duration: 10ms;
      animation-name: hiddenOverflow;
      animation-timing-function: ease-in;
      animation-fill-mode: forwards;
      margin-top: 0; }
  .CustomersList-filters .AdvancedSearch {
    display: block;
    background-color: #ffffff;
    border: 1px solid #e3e3e3;
    margin-top: 3px;
    max-height: 500px;
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1); }
    .CustomersList-filters .AdvancedSearch.hide {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transform: translateY(-230px); }
    .CustomersList-filters .AdvancedSearch-fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .CustomersList-filters .AdvancedSearch-fields .filter-section {
        width: 48%;
        padding: 0 0 0 20px; }
        .CustomersList-filters .AdvancedSearch-fields .filter-section .CheckboxSelector {
          max-width: 200px; }
    .CustomersList-filters .AdvancedSearch-actions {
      margin-top: 20px;
      background-color: #f0f0f0;
      width: 100%;
      padding: 5px 0 5px 20px; }
      .CustomersList-filters .AdvancedSearch-actions .Button {
        margin-right: 40px;
        margin-top: 10px; }
  .CustomersList-filters .Filters-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px; }

.CustomersList-labels .list-header {
  margin-bottom: 10px; }

.CustomersList-labels .List .rt-tr {
  cursor: initial !important; }

.CustomerAccountForm .form-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (max-width: 1200px) {
    .CustomerAccountForm .form-inner {
      flex-direction: column;
      align-items: center; } }
  .CustomerAccountForm .form-inner .Subtitle {
    margin: 10px 0; }
  .CustomerAccountForm .form-inner .TextInput input,
  .CustomerAccountForm .form-inner .NumberInput input,
  .CustomerAccountForm .form-inner .PhoneNumberInput input {
    max-width: unset;
    min-width: unset;
    box-sizing: border-box; }
  .CustomerAccountForm .form-inner .hidden {
    visibility: hidden; }
  .CustomerAccountForm .form-inner .left {
    flex: 1 1;
    flex-shrink: 0; }
    @media screen and (max-width: 1200px) {
      .CustomerAccountForm .form-inner .left {
        margin-right: 0;
        min-height: unset;
        width: 100%; } }
    .CustomerAccountForm .form-inner .left .Checkbox {
      margin-top: 0 !important; }
    .CustomerAccountForm .form-inner .left .Selector {
      max-width: unset; }
    .CustomerAccountForm .form-inner .left .trial-checkbox {
      margin-top: 20px; }
  .CustomerAccountForm .form-inner .divider {
    display: none;
    margin: 30px 30px 10px;
    background: #e3e3e3;
    width: 1px; }
    @media screen and (max-width: 1200px) {
      .CustomerAccountForm .form-inner .divider {
        display: none; } }
    .CustomerAccountForm .form-inner .divider.hidden {
      display: block;
      visibility: hidden; }
    .CustomerAccountForm .form-inner .divider.active {
      display: block; }
      @media screen and (max-width: 1200px) {
        .CustomerAccountForm .form-inner .divider.active {
          display: none; } }
  .CustomerAccountForm .form-inner .right {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .CustomerAccountForm .form-inner .right {
        margin-left: 0;
        min-height: unset;
        width: 100%; } }
    .CustomerAccountForm .form-inner .right.hidden {
      visibility: hidden; }
    .CustomerAccountForm .form-inner .right .Selector {
      max-width: unset; }
    .CustomerAccountForm .form-inner .right .TextArea textarea {
      min-width: unset; }
    .CustomerAccountForm .form-inner .right .DateInput {
      max-width: unset;
      min-width: unset;
      width: 100%; }
      .CustomerAccountForm .form-inner .right .DateInput input {
        max-width: unset;
        min-width: unset; }
    .CustomerAccountForm .form-inner .right .flex-row {
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 1200px) {
        .CustomerAccountForm .form-inner .right .flex-row {
          align-items: flex-start; } }
      .CustomerAccountForm .form-inner .right .flex-row .NumberInput {
        flex-grow: 1;
        margin-right: 10px; }
        @media screen and (max-width: 1200px) {
          .CustomerAccountForm .form-inner .right .flex-row .NumberInput {
            max-width: unset;
            width: 50%;
            margin-right: 0; } }
        .CustomerAccountForm .form-inner .right .flex-row .NumberInput input {
          min-width: unset !important;
          margin-right: 10px; }
          @media screen and (max-width: 1200px) {
            .CustomerAccountForm .form-inner .right .flex-row .NumberInput input {
              width: 95% !important;
              margin-right: 0; } }
      .CustomerAccountForm .form-inner .right .flex-row .Selector {
        flex-grow: 1; }
        @media screen and (max-width: 1200px) {
          .CustomerAccountForm .form-inner .right .flex-row .Selector {
            max-width: unset;
            width: 50%; } }
        .CustomerAccountForm .form-inner .right .flex-row .Selector .selector-inner__control {
          min-height: calc(32px + 2px); }

.AddSSOProviderForm .docs-link {
  display: flex;
  margin: 10px 0; }
  .AddSSOProviderForm .docs-link p {
    margin: 0;
    font-size: 14px;
    color: #525462; }
  .AddSSOProviderForm .docs-link a {
    color: #525462;
    font-size: 14px;
    margin-left: 3px; }

.AddSSOProviderForm .google-form,
.AddSSOProviderForm .saml-form,
.AddSSOProviderForm .redirect-row {
  display: flex;
  flex-direction: column; }
  .AddSSOProviderForm .google-form .TextInput input,
  .AddSSOProviderForm .saml-form .TextInput input,
  .AddSSOProviderForm .redirect-row .TextInput input {
    max-width: 400px; }
  .AddSSOProviderForm .google-form .FileUploader,
  .AddSSOProviderForm .saml-form .FileUploader,
  .AddSSOProviderForm .redirect-row .FileUploader {
    max-width: 400px; }
    .AddSSOProviderForm .google-form .FileUploader .dzu-dropzone,
    .AddSSOProviderForm .saml-form .FileUploader .dzu-dropzone,
    .AddSSOProviderForm .redirect-row .FileUploader .dzu-dropzone {
      min-height: 90px;
      justify-content: center; }
      .AddSSOProviderForm .google-form .FileUploader .dzu-dropzone .dzu-previewContainer,
      .AddSSOProviderForm .saml-form .FileUploader .dzu-dropzone .dzu-previewContainer,
      .AddSSOProviderForm .redirect-row .FileUploader .dzu-dropzone .dzu-previewContainer {
        border-bottom: none; }
    .AddSSOProviderForm .google-form .FileUploader .dzu-submitButtonContainer,
    .AddSSOProviderForm .saml-form .FileUploader .dzu-submitButtonContainer,
    .AddSSOProviderForm .redirect-row .FileUploader .dzu-submitButtonContainer {
      display: none; }

.CustomerAccountRedirectUriForm .redirect-row {
  display: flex;
  flex-direction: column; }
  .CustomerAccountRedirectUriForm .redirect-row .TextInput input {
    max-width: 400px; }

.CustomerAccountSSO .row h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  color: #464854; }

.CustomerAccountSSO .redirect-uri-cell {
  display: flex;
  align-items: center; }
  .CustomerAccountSSO .redirect-uri-cell .Button {
    margin-left: 20px; }

.CustomerAccountSSO .account-verification-switcher {
  display: flex;
  align-items: center; }
  .CustomerAccountSSO .account-verification-switcher .Label {
    margin-top: 0;
    margin-left: 10px; }

.AddCustomerToAccountForm .form-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 6px; }
  @media screen and (max-width: 1200px) {
    .AddCustomerToAccountForm .form-inner {
      flex-direction: column;
      align-items: center; } }
  .AddCustomerToAccountForm .form-inner .Subtitle {
    margin: 10px 0; }
  .AddCustomerToAccountForm .form-inner .TextInput input,
  .AddCustomerToAccountForm .form-inner .NumberInput input,
  .AddCustomerToAccountForm .form-inner .PhoneNumberInput input {
    max-width: unset;
    min-width: unset;
    box-sizing: border-box; }
  .AddCustomerToAccountForm .form-inner .hidden {
    visibility: hidden; }
  .AddCustomerToAccountForm .form-inner .left {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .AddCustomerToAccountForm .form-inner .left {
        margin-right: 0;
        min-height: unset;
        width: 100%; } }
    .AddCustomerToAccountForm .form-inner .left .Checkbox {
      margin-top: 0 !important; }
    .AddCustomerToAccountForm .form-inner .left .Selector {
      max-width: unset; }
    .AddCustomerToAccountForm .form-inner .left .trial-checkbox {
      margin-top: 20px; }
  .AddCustomerToAccountForm .form-inner .divider {
    display: none;
    margin: 30px 30px 10px;
    background: #e3e3e3;
    width: 1px; }
    @media screen and (max-width: 1200px) {
      .AddCustomerToAccountForm .form-inner .divider {
        display: none; } }
    .AddCustomerToAccountForm .form-inner .divider.hidden {
      display: block;
      visibility: hidden; }
    .AddCustomerToAccountForm .form-inner .divider.active {
      display: block; }
      @media screen and (max-width: 1200px) {
        .AddCustomerToAccountForm .form-inner .divider.active {
          display: none; } }
  .AddCustomerToAccountForm .form-inner .right {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .AddCustomerToAccountForm .form-inner .right {
        margin-left: 0;
        min-height: unset;
        width: 100%; } }
    .AddCustomerToAccountForm .form-inner .right.hidden {
      visibility: hidden; }
    .AddCustomerToAccountForm .form-inner .right .Selector {
      max-width: unset; }
    .AddCustomerToAccountForm .form-inner .right .DateInput {
      max-width: unset;
      min-width: unset;
      width: 100%; }
      .AddCustomerToAccountForm .form-inner .right .DateInput input {
        max-width: unset;
        min-width: unset; }
    .AddCustomerToAccountForm .form-inner .right .flex-row {
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 1200px) {
        .AddCustomerToAccountForm .form-inner .right .flex-row {
          align-items: flex-start; } }
      .AddCustomerToAccountForm .form-inner .right .flex-row .NumberInput {
        flex-grow: 1;
        margin-right: 10px; }
        @media screen and (max-width: 1200px) {
          .AddCustomerToAccountForm .form-inner .right .flex-row .NumberInput {
            max-width: unset;
            width: 50%;
            margin-right: 0; } }
        .AddCustomerToAccountForm .form-inner .right .flex-row .NumberInput input {
          min-width: unset !important;
          margin-right: 10px; }
          @media screen and (max-width: 1200px) {
            .AddCustomerToAccountForm .form-inner .right .flex-row .NumberInput input {
              width: 95% !important;
              margin-right: 0; } }
      .AddCustomerToAccountForm .form-inner .right .flex-row .Selector {
        flex-grow: 1; }
        @media screen and (max-width: 1200px) {
          .AddCustomerToAccountForm .form-inner .right .flex-row .Selector {
            max-width: unset;
            width: 50%; } }
        .AddCustomerToAccountForm .form-inner .right .flex-row .Selector .selector-inner__control {
          min-height: calc(32px + 2px); }

.AddCustomerToAccountForm .radio-btns {
  display: flex; }
  .AddCustomerToAccountForm .radio-btns .RadioBtn {
    margin-right: 40px; }

.AddCustomerToAccountForm .existing-customer-select {
  margin-top: 20px; }

.PlatformAuditLog .Filters-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 10px; }

.PlatformAuditLog .log-actor {
  margin-left: 10px; }


.SelectCustomerAccount .form-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 6px; }
  @media screen and (max-width: 1200px) {
    .SelectCustomerAccount .form-inner {
      flex-direction: column;
      align-items: center; } }
  .SelectCustomerAccount .form-inner .Subtitle {
    margin: 10px 0; }
  .SelectCustomerAccount .form-inner .TextInput input,
  .SelectCustomerAccount .form-inner .NumberInput input,
  .SelectCustomerAccount .form-inner .PhoneNumberInput input {
    max-width: unset;
    min-width: unset;
    box-sizing: border-box; }
  .SelectCustomerAccount .form-inner .hidden {
    visibility: hidden; }
  .SelectCustomerAccount .form-inner .left {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .SelectCustomerAccount .form-inner .left {
        margin-right: 0;
        min-height: unset;
        width: 100%; } }
    .SelectCustomerAccount .form-inner .left .Checkbox {
      margin-top: 0 !important; }
    .SelectCustomerAccount .form-inner .left .Selector {
      max-width: unset; }
    .SelectCustomerAccount .form-inner .left .trial-checkbox {
      margin-top: 20px; }
  .SelectCustomerAccount .form-inner .divider {
    display: none;
    margin: 30px 30px 10px;
    background: #e3e3e3;
    width: 1px; }
    @media screen and (max-width: 1200px) {
      .SelectCustomerAccount .form-inner .divider {
        display: none; } }
    .SelectCustomerAccount .form-inner .divider.hidden {
      display: block;
      visibility: hidden; }
    .SelectCustomerAccount .form-inner .divider.active {
      display: block; }
      @media screen and (max-width: 1200px) {
        .SelectCustomerAccount .form-inner .divider.active {
          display: none; } }
  .SelectCustomerAccount .form-inner .right {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .SelectCustomerAccount .form-inner .right {
        margin-left: 0;
        min-height: unset;
        width: 100%; } }
    .SelectCustomerAccount .form-inner .right.hidden {
      visibility: hidden; }
    .SelectCustomerAccount .form-inner .right .Selector {
      max-width: unset; }
    .SelectCustomerAccount .form-inner .right .TextArea textarea {
      min-width: unset; }
    .SelectCustomerAccount .form-inner .right .DateInput {
      max-width: unset;
      min-width: unset;
      width: 100%; }
      .SelectCustomerAccount .form-inner .right .DateInput input {
        max-width: unset;
        min-width: unset; }
    .SelectCustomerAccount .form-inner .right .flex-row {
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 1200px) {
        .SelectCustomerAccount .form-inner .right .flex-row {
          align-items: flex-start; } }
      .SelectCustomerAccount .form-inner .right .flex-row .NumberInput {
        flex-grow: 1;
        margin-right: 10px; }
        @media screen and (max-width: 1200px) {
          .SelectCustomerAccount .form-inner .right .flex-row .NumberInput {
            max-width: unset;
            width: 50%;
            margin-right: 0; } }
        .SelectCustomerAccount .form-inner .right .flex-row .NumberInput input {
          min-width: unset !important;
          margin-right: 10px; }
          @media screen and (max-width: 1200px) {
            .SelectCustomerAccount .form-inner .right .flex-row .NumberInput input {
              width: 95% !important;
              margin-right: 0; } }
      .SelectCustomerAccount .form-inner .right .flex-row .Selector {
        flex-grow: 1; }
        @media screen and (max-width: 1200px) {
          .SelectCustomerAccount .form-inner .right .flex-row .Selector {
            max-width: unset;
            width: 50%; } }
        .SelectCustomerAccount .form-inner .right .flex-row .Selector .selector-inner__control {
          min-height: calc(32px + 2px); }

.SelectCustomerAccount .radio-btns {
  display: flex; }
  .SelectCustomerAccount .radio-btns .RadioBtn {
    margin-right: 40px; }

.SelectCustomerAccount .existing-account-select {
  margin-top: 20px; }

.CustomerContainer .lists-cont {
  background: #fff;
  border: 1px solid #e3e3e3; }

.CustomerContainer .Logs {
  margin-top: 20px; }

.customer-title-container .labels {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .customer-title-container .labels .Button {
    margin-bottom: 0; }

.customer-title-container .customer-title {
  display: flex;
  align-items: center; }
  .customer-title-container .customer-title .clipboard-btn {
    display: inline-flex;
    align-items: center;
    z-index: 2;
    background-color: transparent;
    opacity: 0.5;
    -webkit-appearance: none;
    border: none;
    width: 34x;
    cursor: pointer;
    outline: none;
    margin-left: 10px; }
    .customer-title-container .customer-title .clipboard-btn:hover {
      background-color: #e2e2e2;
      opacity: 1; }

.customer-title-container .customer-company-desc {
  color: #525462;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 10px; }

.EditLicenseFormTitle {
  display: flex;
  align-items: center; }
  .EditLicenseFormTitle .confirm-btn {
    margin-left: auto; }

.EditLicenseForm .confirm-btn .Button {
  margin-bottom: 20px;
  margin-top: 0; }

.EditLicenseForm-main {
  display: flex;
  flex-wrap: wrap; }

.EditLicenseForm .sections-wrapper {
  width: 40%; }
  .EditLicenseForm .sections-wrapper:first-of-type {
    margin-right: 10%; }

.EditLicenseForm section {
  margin-bottom: 30px; }
  .EditLicenseForm section .section-header {
    font-weight: 600;
    margin-bottom: 14px;
    color: #464854;
    font-size: 18px; }
    .EditLicenseForm section .section-header.first {
      margin-top: 0; }
    .EditLicenseForm section .section-header.license-usage {
      margin-bottom: 4px; }
  .EditLicenseForm section .section-row .Notice {
    max-width: 380px; }
  .EditLicenseForm section .section-row .InputErrorMessage {
    max-width: 400px; }
  .EditLicenseForm section .section-row .DateInput {
    max-width: 400px;
    min-width: unset;
    width: 100%; }
    .EditLicenseForm section .section-row .DateInput input {
      max-width: unset;
      min-width: unset; }
  .EditLicenseForm section .section-row .TextInput input,
  .EditLicenseForm section .section-row .NumberInput input {
    max-width: 400px; }
  .EditLicenseForm section .section-row .Checkbox {
    margin-top: 10px; }
  .EditLicenseForm section .section-row.initial-checkbox .Checkbox {
    margin-top: 0; }
  .EditLicenseForm section .section-row.max-simult-users {
    margin-bottom: 14px; }
    .EditLicenseForm section .section-row.max-simult-users .Label {
      margin-top: 5px; }
  .EditLicenseForm section .section-row.time-limited-options {
    margin-bottom: 10px; }
  .EditLicenseForm section .section-row.overage-options .radio-btns-overages {
    margin-top: -5px;
    margin-bottom: 10px; }
  .EditLicenseForm section .section-row.consumption-period-select {
    margin-bottom: 10px; }
    .EditLicenseForm section .section-row.consumption-period-select .Label {
      margin-top: 4px; }
  .EditLicenseForm section .section-row.max-activations .Checkbox {
    margin-top: 0; }
  .EditLicenseForm section .section-row.max-activations .Label {
    margin-top: 7px; }
  .EditLicenseForm section .section-row.row-max-license-users-checkbox .Checkbox {
    margin-top: 4px;
    margin-bottom: 10px; }
  .EditLicenseForm section .section-row .flex-row {
    display: flex;
    flex-wrap: wrap;
    max-width: 400px; }
    @media screen and (max-width: 1200px) {
      .EditLicenseForm section .section-row .flex-row {
        align-items: flex-start; } }
    .EditLicenseForm section .section-row .flex-row .NumberInput {
      flex-grow: 1;
      margin-right: 10px; }
      @media screen and (max-width: 1200px) {
        .EditLicenseForm section .section-row .flex-row .NumberInput {
          max-width: unset;
          width: 50%;
          margin-right: 0; } }
      .EditLicenseForm section .section-row .flex-row .NumberInput input {
        min-width: unset !important;
        margin-right: 10px; }
        @media screen and (max-width: 1200px) {
          .EditLicenseForm section .section-row .flex-row .NumberInput input {
            width: 95% !important;
            margin-right: 0; } }
    .EditLicenseForm section .section-row .flex-row .Selector {
      flex-grow: 1; }
      @media screen and (max-width: 1200px) {
        .EditLicenseForm section .section-row .flex-row .Selector {
          max-width: unset;
          width: 50%; } }
      .EditLicenseForm section .section-row .flex-row .Selector .selector-inner__control {
        min-height: calc(32px + 2px); }

.form-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (max-width: 1200px) {
    .form-inner {
      flex-direction: column;
      align-items: center; } }
  .form-inner .Subtitle {
    margin: 10px 0; }
  .form-inner .TextInput input,
  .form-inner .NumberInput input {
    max-width: unset;
    min-width: unset;
    box-sizing: border-box; }
  .form-inner .hidden {
    visibility: hidden; }
  .form-inner .left {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .form-inner .left {
        margin-right: 0;
        min-height: unset;
        width: 100%; } }
    .form-inner .left .Checkbox {
      margin-top: 0 !important; }
    .form-inner .left .Selector {
      max-width: unset; }
    .form-inner .left .trial-checkbox {
      margin-top: 20px; }
    .form-inner .left .trialConfig .radio-btns {
      margin-bottom: 10px; }
      .form-inner .left .trialConfig .radio-btns .RadioBtn:nth-of-type(1) {
        margin-top: 5px; }
    .form-inner .left .DateInput {
      max-width: unset;
      min-width: unset;
      width: 100%; }
      .form-inner .left .DateInput input {
        max-width: unset;
        min-width: unset; }
  .form-inner .divider {
    display: none;
    margin: 30px 30px 10px;
    background: #e3e3e3;
    width: 1px; }
    @media screen and (max-width: 1200px) {
      .form-inner .divider {
        display: none; } }
    .form-inner .divider.hidden {
      display: block;
      visibility: hidden; }
    .form-inner .divider.active {
      display: block; }
      @media screen and (max-width: 1200px) {
        .form-inner .divider.active {
          display: none; } }
  .form-inner .right {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .form-inner .right {
        margin-left: 0;
        min-height: unset;
        width: 100%; } }
    .form-inner .right .floating-config {
      margin: 10px 0; }
      .form-inner .right .floating-config .Label {
        margin-top: 0; }
    .form-inner .right .radio-btns {
      margin-top: 20px; }
      .form-inner .right .radio-btns.radio-btns-overages {
        margin-top: -5px; }
      .form-inner .right .radio-btns .RadioBtn:nth-of-type(1) {
        margin-top: 10px; }
      .form-inner .right .radio-btns .RadioBtn label {
        font-weight: 500;
        font-size: 13px;
        color: #525462; }
    .form-inner .right.hidden {
      visibility: hidden; }
    .form-inner .right .Selector {
      max-width: unset; }
    .form-inner .right .DateInput {
      max-width: unset;
      min-width: unset;
      width: 100%; }
      .form-inner .right .DateInput input {
        max-width: unset;
        min-width: unset; }
    .form-inner .right .flex-row {
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 1200px) {
        .form-inner .right .flex-row {
          align-items: flex-start; } }
      .form-inner .right .flex-row .NumberInput {
        flex-grow: 1;
        margin-right: 10px; }
        @media screen and (max-width: 1200px) {
          .form-inner .right .flex-row .NumberInput {
            max-width: unset;
            width: 50%;
            margin-right: 0; } }
        .form-inner .right .flex-row .NumberInput input {
          min-width: unset !important;
          margin-right: 10px; }
          @media screen and (max-width: 1200px) {
            .form-inner .right .flex-row .NumberInput input {
              width: 95% !important;
              margin-right: 0; } }
      .form-inner .right .flex-row .Selector {
        flex-grow: 1; }
        @media screen and (max-width: 1200px) {
          .form-inner .right .flex-row .Selector {
            max-width: unset;
            width: 50%; } }
        .form-inner .right .flex-row .Selector .selector-inner__control {
          min-height: calc(32px + 2px); }

.ProductFeaturesEditorForm .form-row .Checkbox {
  margin-top: 14px; }

.ProductFeaturesEditorForm .form-row .Label {
  margin-top: 5px; }

.ProductFeaturesEditorForm .form-row .Selector {
  max-width: 300px; }

.ProductFeaturesEditor {
  margin-top: 10px; }
  .ProductFeaturesEditor ol {
    padding-inline-start: 16px;
    margin: 0; }
  .ProductFeaturesEditor .feature-row {
    margin: 0 0 20px;
    background: #fff;
    border: 1px solid #e3e3e3;
    border-left: 4px solid #707070;
    padding: 10px 15px;
    font-size: 13px;
    color: #464854; }
  .ProductFeaturesEditor .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px; }
    .ProductFeaturesEditor .header .product-name {
      font-size: 14px;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center; }
      .ProductFeaturesEditor .header .product-name .name {
        margin-right: 6px; }
      .ProductFeaturesEditor .header .product-name .code {
        font-size: 13px;
        font-weight: 500;
        margin-right: 20px; }
      .ProductFeaturesEditor .header .product-name .floating-pill {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 80px;
        padding: 3px 6px;
        font-size: 12px;
        margin-left: 20px;
        border-width: 1px;
        border-style: solid;
        background: rgba(255, 159, 67, 0.15);
        border-color: rgba(255, 159, 67, 0.35);
        color: #c35f00; }
    .ProductFeaturesEditor .header .actions .Button {
      margin-bottom: 0;
      margin-left: 20px; }
  .ProductFeaturesEditor .details {
    display: flex; }
    .ProductFeaturesEditor .details .label {
      width: 30%;
      min-width: 100px;
      word-break: break-word;
      box-sizing: border-box;
      padding: 6px 2px;
      color: #464854;
      font-weight: 500; }
    .ProductFeaturesEditor .details .value {
      width: 70%;
      word-break: break-word;
      box-sizing: border-box;
      padding: 6px 2px;
      color: #525462;
      font-weight: 400; }

.BatchLicenseAssign-filter {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 30px; }
  .BatchLicenseAssign-filter .filter-field {
    width: 48%; }

.BatchLicenseAssign-main .unassigned-count {
  font-weight: 500; }
  .BatchLicenseAssign-main .unassigned-count span {
    color: #ee5253; }

.BatchLicenseAssign-main .MultiEmailInput {
  max-width: 100%; }

.BatchLicenseAssign-main .warning-notice {
  font-size: 14px;
  margin: 20px 0; }

.EditProductFeatureForm .form-row .Checkbox {
  margin-top: 14px; }

.EditProductFeatureForm .form-row .Label {
  margin-top: 5px; }

.EditProductFeatureForm .form-row .Selector {
  max-width: 300px; }

.CustomBreadcrumbs {
  list-style: none;
  margin: 0;
  padding: 0;
  opacity: 0.6;
  font-size: 13px;
  font-weight: 500; }
  .CustomBreadcrumbs a {
    text-decoration: none; }
    .CustomBreadcrumbs a:hover {
      text-decoration: underline; }

.BorrowLicenseForm .row .DateInput {
  max-width: unset;
  width: 100%; }
  .BorrowLicenseForm .row .DateInput input {
    max-width: unset; }

.DeviceActionMenu {
  position: relative; }
  .DeviceActionMenu .header {
    margin-top: 30px; }
    .DeviceActionMenu .header .text {
      font-weight: 500;
      font-size: 14px; }
  .DeviceActionMenu ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin: 10px 0 30px 0; }
    .DeviceActionMenu ul li {
      padding: 10px 0 !important;
      border-bottom: 1px solid #eaeaea; }
      .DeviceActionMenu ul li:hover {
        background-color: #eee; }
      .DeviceActionMenu ul li button {
        text-align: start;
        width: 100%;
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer; }
        .DeviceActionMenu ul li button .icon-wrapper {
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center; }
        .DeviceActionMenu ul li button svg {
          margin-right: 10px;
          margin-top: 2px; }

.DevicesList .SubComponent-actions {
  margin-top: 10px; }

.DevicesList .SubComponent-users {
  margin: 0 20px 20px;
  background: #fff;
  padding: 10px;
  padding-top: 14px; }
  .DevicesList .SubComponent-users .heading {
    font-weight: 600;
    font-size: 14px; }
  .DevicesList .SubComponent-users ul {
    margin-block-start: 5px;
    padding-inline-start: 20px; }

.AnalyticsDatePicker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; }

.AnalyticsProduct {
  margin-top: 20px; }
  .AnalyticsProduct .individual-actions-charts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .AnalyticsProduct .individual-actions-charts .individual-chart {
      width: 48%;
      margin-top: 30px; }
    @media screen and (max-width: 768px) {
      .AnalyticsProduct .individual-actions-charts {
        flex-direction: column; }
        .AnalyticsProduct .individual-actions-charts .individual-chart {
          width: 100%; } }

.AnalyticsReports .AnalyticsReports-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .AnalyticsReports .AnalyticsReports-selector .section-row {
    min-width: 300px;
    margin-right: 40px;
    margin-bottom: 20px; }
    .AnalyticsReports .AnalyticsReports-selector .section-row:last-of-type {
      margin-right: 0; }
  .AnalyticsReports .AnalyticsReports-selector .Label {
    margin-top: 0; }

.AnalyticsReports .row {
  margin-bottom: 30px; }

.AnalyticsTotal .individual-actions-charts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .AnalyticsTotal .individual-actions-charts .individual-chart {
    width: 48%;
    margin-top: 30px; }
  @media screen and (max-width: 768px) {
    .AnalyticsTotal .individual-actions-charts {
      flex-direction: column; }
      .AnalyticsTotal .individual-actions-charts .individual-chart {
        width: 100%; } }

.MonthlyRateLimitBar-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500; }

.MonthlyRateLimitBar .ProgressBar {
  margin-bottom: 20px; }

.LicenseConsumptionEventsList .List {
  margin-top: 20px; }

.LicenseConsumptionChart {
  margin-bottom: 40px; }
  .LicenseConsumptionChart .recharts-reference-line text {
    font-size: 11px;
    color: #525462; }
  .LicenseConsumptionChart-actions {
    margin-bottom: 10px; }
    .LicenseConsumptionChart-actions button {
      margin-right: 20px; }

.LicenseAnalytics .individual-actions-charts {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .LicenseAnalytics .individual-actions-charts .individual-chart {
    width: 48%;
    margin-top: 30px; }
  @media screen and (max-width: 768px) {
    .LicenseAnalytics .individual-actions-charts {
      flex-direction: column; }
      .LicenseAnalytics .individual-actions-charts .individual-chart {
        width: 100%; } }

.LicenseDetailsTable .license-policy-content {
  display: flex;
  align-items: center; }
  .LicenseDetailsTable .license-policy-content .policy-name {
    margin-right: 20px; }

.LicenseDetailsTable .total-consumptions-cell {
  display: flex;
  align-items: center; }
  .LicenseDetailsTable .total-consumptions-cell .value {
    margin-right: 20px; }

.LicenseDetailsTable .Logs {
  margin-top: 20px; }

.ExistingUserForm-message {
  text-align: left;
  font-weight: 500;
  margin-bottom: 5px; }

.ExistingUserForm .DescriptionTable {
  margin-bottom: 10px;
  text-align: left; }

.ExistingUserForm .license-manager-input {
  display: flex; }

.ExistingUserForm .section-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
  text-align: left; }
  .ExistingUserForm .section-row .NumberInput input {
    min-width: 300px; }

.AssignLicenseUser .form-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (max-width: 1200px) {
    .AssignLicenseUser .form-inner {
      flex-direction: column;
      align-items: center; } }
  .AssignLicenseUser .form-inner .Subtitle {
    margin: 10px 0; }
  .AssignLicenseUser .form-inner .TextInput input,
  .AssignLicenseUser .form-inner .NumberInput input {
    max-width: unset;
    min-width: unset;
    box-sizing: border-box; }
  .AssignLicenseUser .form-inner .hidden {
    visibility: hidden; }
  .AssignLicenseUser .form-inner .left {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .AssignLicenseUser .form-inner .left {
        margin-right: 0;
        min-height: unset;
        width: 100%; } }
    .AssignLicenseUser .form-inner .left .Checkbox {
      margin-top: 0 !important; }
  .AssignLicenseUser .form-inner .divider {
    display: none;
    margin: 30px 30px 10px;
    background: #e3e3e3;
    width: 1px; }
    @media screen and (max-width: 1200px) {
      .AssignLicenseUser .form-inner .divider {
        display: none; } }
    .AssignLicenseUser .form-inner .divider.hidden {
      display: block;
      visibility: hidden; }
    .AssignLicenseUser .form-inner .divider.active {
      display: block; }
      @media screen and (max-width: 1200px) {
        .AssignLicenseUser .form-inner .divider.active {
          display: none; } }
  .AssignLicenseUser .form-inner .right {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .AssignLicenseUser .form-inner .right {
        margin-left: 0;
        min-height: unset;
        width: 100%; } }
    .AssignLicenseUser .form-inner .right.hidden {
      visibility: hidden; }
    .AssignLicenseUser .form-inner .right .license-manager-input {
      margin-top: 12px; }
    .AssignLicenseUser .form-inner .right .password-container .Label {
      margin-top: 5px; }

.EditLicenseUser .form-inner {
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 1200px) {
    .EditLicenseUser .form-inner {
      flex-direction: column;
      align-items: flex-start; } }
  .EditLicenseUser .form-inner .Subtitle {
    margin: 10px 0; }
  .EditLicenseUser .form-inner .form-row {
    width: 100%; }
  .EditLicenseUser .form-inner .TextInput input,
  .EditLicenseUser .form-inner .NumberInput input,
  .EditLicenseUser .form-inner .PhoneNumberInput input {
    max-width: unset;
    min-width: unset;
    box-sizing: border-box; }
  .EditLicenseUser .form-inner .hidden {
    visibility: hidden; }

.ChangeLicenseUserPassword .form-inner {
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 1200px) {
    .ChangeLicenseUserPassword .form-inner {
      flex-direction: column;
      align-items: flex-start; } }
  .ChangeLicenseUserPassword .form-inner .Subtitle {
    margin: 10px 0; }
  .ChangeLicenseUserPassword .form-inner .form-row {
    width: 100%; }
  .ChangeLicenseUserPassword .form-inner .TextInput input,
  .ChangeLicenseUserPassword .form-inner .NumberInput input {
    max-width: unset;
    min-width: unset;
    box-sizing: border-box; }
  .ChangeLicenseUserPassword .form-inner .hidden {
    visibility: hidden; }

.LicenseUserMaxActivations .form-inner {
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 1200px) {
    .LicenseUserMaxActivations .form-inner {
      flex-direction: column;
      align-items: flex-start; } }
  .LicenseUserMaxActivations .form-inner .Subtitle {
    margin: 10px 0; }
  .LicenseUserMaxActivations .form-inner .form-row {
    width: 100%; }
  .LicenseUserMaxActivations .form-inner .TextInput input,
  .LicenseUserMaxActivations .form-inner .NumberInput input {
    max-width: unset;
    min-width: unset;
    box-sizing: border-box; }
  .LicenseUserMaxActivations .form-inner .hidden {
    visibility: hidden; }

.LicenseUsersActionMenu {
  position: relative; }
  .LicenseUsersActionMenu .header {
    margin-top: 30px; }
    .LicenseUsersActionMenu .header .text {
      font-weight: 500;
      font-size: 14px; }
  .LicenseUsersActionMenu ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin: 10px 0 30px 0; }
    .LicenseUsersActionMenu ul li {
      padding: 10px 0 !important;
      border-bottom: 1px solid #eaeaea; }
      .LicenseUsersActionMenu ul li:hover {
        background-color: #eee; }
      .LicenseUsersActionMenu ul li button {
        text-align: start;
        width: 100%;
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer; }
        .LicenseUsersActionMenu ul li button .icon-wrapper {
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center; }
        .LicenseUsersActionMenu ul li button svg {
          margin-right: 10px;
          margin-top: 2px; }

.LicenseUsers .license-user-tab-actions .left .Button {
  margin-right: 20px; }

.FeatureConsumptionEventsList .List {
  margin-top: 20px; }

.FeatureConsumptionChart {
  margin-bottom: 40px; }
  .FeatureConsumptionChart .recharts-reference-line text {
    font-size: 11px;
    color: #525462; }
  .FeatureConsumptionChart-actions {
    margin-bottom: 10px; }
    .FeatureConsumptionChart-actions button {
      margin-right: 20px; }

.ProductFeatures-activity {
  margin-top: 50px; }

.ProductFeatures .floating-slots-btn {
  display: flex;
  align-items: flex-end;
  justify-content: center; }
  .ProductFeatures .floating-slots-btn span {
    margin-right: 10px; }

.ProductFeatures .total-consumptions-cell {
  display: flex;
  align-items: center; }
  .ProductFeatures .total-consumptions-cell .value {
    margin-right: 10px; }
  .ProductFeatures .total-consumptions-cell .Button {
    padding: 0; }

.UsageReport .list-header .Button {
  height: 42px; }

.UsageReport .List .description-cell {
  display: flex;
  align-items: center; }
  .UsageReport .List .description-cell svg {
    flex-shrink: 0; }
  .UsageReport .List .description-cell span {
    margin-left: 4px;
    word-break: break-word; }

.UsageReport .List .SubComponent .subheading {
  margin: 10px 0 0 20px;
  font-weight: 500;
  font-size: 14px; }

.UsageReport .List .SubComponent .empty-data-warning {
  margin: 6px 0 15px 20px;
  font-size: 12px; }

.UsageReport .List .SubComponent .data-container {
  padding: 0 20px 20px; }
  .UsageReport .List .SubComponent .data-container .subheading {
    margin-left: 0;
    margin-bottom: 10px; }



.BundleLicenseContainer .docs-link {
  display: flex;
  margin: 10px 0; }
  .BundleLicenseContainer .docs-link p {
    margin: 0;
    font-size: 14px; }
  .BundleLicenseContainer .docs-link a {
    font-size: 14px;
    margin-left: 3px; }

.BundleLicenseContainer .LicenseUsers {
  margin-bottom: 20px; }

.LicenseContainer .Notice .docs-link {
  display: flex;
  align-items: baseline; }
  .LicenseContainer .Notice .docs-link p {
    margin: 0 4px 0 0; }

.LicenseContainer .license-title-container {
  display: flex;
  align-items: center; }
  .LicenseContainer .license-title-container .clipboard-btn {
    display: inline-flex;
    align-items: center;
    z-index: 2;
    background-color: transparent;
    opacity: 0.5;
    -webkit-appearance: none;
    border: none;
    width: 34x;
    cursor: pointer;
    outline: none;
    margin-left: 10px; }
    .LicenseContainer .license-title-container .clipboard-btn:hover {
      background-color: #e2e2e2;
      opacity: 1; }

.LicenseContainer .license-user-tab .license-user-tab-actions {
  display: flex;
  justify-content: space-between; }
  .LicenseContainer .license-user-tab .license-user-tab-actions .max-license-users-count {
    font-weight: 500;
    font-size: 14px;
    background: #ffffff;
    padding: 5px 18px;
    border: 1px solid #eaeaea;
    display: flex;
    align-items: center; }

.LicenseContainer .devices-list .List .ReactTable .rt-tbody .rt-tr {
  cursor: default; }

.LicenseContainer .blacklisted-devices-list {
  margin-top: 40px; }
  .LicenseContainer .blacklisted-devices-list .Subtitle {
    font-size: 18px; }

.LicenseContainer-tabs .Tab-header {
  font-weight: 500;
  font-size: 15px;
  max-width: 600px;
  margin-bottom: 20px; }

.LicenseContainer .Device-variables-header {
  display: flex; }
  .LicenseContainer .Device-variables-header svg {
    margin-left: 5px; }

.form-horizontal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column; }
  @media (min-width: 768px) {
    .form-horizontal {
      flex-direction: row; } }

.ExportOrderForm-filters .title {
  font-weight: 600;
  margin: 20px 0 6px;
  font-size: 14px; }

.ExportOrderForm-filters .DescriptionTable {
  margin-bottom: 0; }

.ExportOrderForm-filters .Button {
  margin-top: 6px;
  padding: 0; }
  .ExportOrderForm-filters .Button svg {
    margin-right: 3px; }

.OrderList .list-header {
  margin-bottom: 0; }

.OrderList-filters {
  margin-bottom: 10px; }
  .OrderList-filters .AdvancedSearch-btn {
    -webkit-appearance: none;
    appearance: none;
    font-size: 14px;
    color: #2e86de;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    outline: none;
    margin-top: 10px;
    padding: 0;
    display: flex;
    align-items: center; }
    .OrderList-filters .AdvancedSearch-btn svg {
      margin-right: 5px; }
  .OrderList-filters .AdvancedSearch-wrapper {
    overflow: visible;
    transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1);
    -webkit-animation-delay: 0;
    -webkit-animation-duration: 400ms;
    -webkit-animation-name: visibleOverflow;
    -webkit-animation-timing-function: ease-in;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0;
    -moz-animation-duration: 400ms;
    -moz-animation-name: visibleOverflow;
    -moz-animation-timing-function: ease-in;
    -moz-animation-fill-mode: forwards;
    -o-animation-delay: 0;
    -o-animation-duration: 400ms;
    -o-animation-name: visibleOverflow;
    -o-animation-timing-function: ease-in;
    -o-animation-fill-mode: forwards;
    animation-delay: 0;
    animation-duration: 400ms;
    animation-name: visibleOverflow;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    margin-top: 5px; }
    .OrderList-filters .AdvancedSearch-wrapper.hide {
      overflow: hidden;
      padding-bottom: 0px;
      -webkit-animation-delay: 0;
      -webkit-animation-duration: 10ms;
      -webkit-animation-name: hiddenOverflow;
      -webkit-animation-timing-function: ease-in;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-delay: 0;
      -moz-animation-duration: 10ms;
      -moz-animation-name: hiddenOverflow;
      -moz-animation-timing-function: ease-in;
      -moz-animation-fill-mode: forwards;
      -o-animation-delay: 0;
      -o-animation-duration: 10ms;
      -o-animation-name: hiddenOverflow;
      -o-animation-timing-function: ease-in;
      -o-animation-fill-mode: forwards;
      animation-delay: 0;
      animation-duration: 10ms;
      animation-name: hiddenOverflow;
      animation-timing-function: ease-in;
      animation-fill-mode: forwards;
      margin-top: 0; }
  .OrderList-filters .AdvancedSearch {
    display: block;
    background-color: #ffffff;
    border: 1px solid #e3e3e3;
    margin-top: 3px;
    max-height: 500px;
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1); }
    .OrderList-filters .AdvancedSearch.hide {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transform: translateY(-230px); }
    .OrderList-filters .AdvancedSearch-fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .OrderList-filters .AdvancedSearch-fields .filter-section {
        width: 48%;
        padding: 0 0 0 20px; }
        .OrderList-filters .AdvancedSearch-fields .filter-section .CheckboxSelector {
          max-width: 200px; }
    .OrderList-filters .AdvancedSearch-actions {
      margin-top: 20px;
      background-color: #f0f0f0;
      width: 100%;
      padding: 5px 0 5px 20px; }
      .OrderList-filters .AdvancedSearch-actions .Button {
        margin-right: 40px; }
  .OrderList-filters .Filters-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px; }

.ChangeCustomerOrderForm-form .customer-details .existing-customer-selector .Selector {
  max-width: 350px; }

.ChangeCustomerOrderForm-form .customer-details .existing-customer-selector .existing-customer {
  display: flex;
  flex-wrap: wrap; }
  .ChangeCustomerOrderForm-form .customer-details .existing-customer-selector .existing-customer .search-selector {
    min-width: 150px; }
  .ChangeCustomerOrderForm-form .customer-details .existing-customer-selector .existing-customer .customer-selector .Selector {
    min-width: 350px; }

.ChangeCustomerOrderForm-form .customer-details .buttons-container {
  margin-bottom: 20px; }

.ChangeCustomerOrderForm-form .customer-details .form-row {
  display: flex;
  flex-wrap: wrap; }
  .ChangeCustomerOrderForm-form .customer-details .form-row .form-column {
    margin-right: 40px; }
    .ChangeCustomerOrderForm-form .customer-details .form-row .form-column .TextInput input,
    .ChangeCustomerOrderForm-form .customer-details .form-row .form-column .NumberInput input,
    .ChangeCustomerOrderForm-form .customer-details .form-row .form-column .PhoneNumberInput input {
      max-width: unset;
      box-sizing: border-box; }
    .ChangeCustomerOrderForm-form .customer-details .form-row .form-column .PhoneNumberInput {
      min-width: 300px; }

.EditOrderForm .checkbox-cont {
  margin-top: 14px; }

.OrderManagerForm .existing-manager {
  margin: 10px 0 20px; }
  .OrderManagerForm .existing-manager .search {
    display: flex;
    align-items: center; }

.OrderContainer .order-title-container {
  display: flex;
  align-items: center; }
  .OrderContainer .order-title-container .clipboard-btn {
    display: inline-flex;
    align-items: center;
    z-index: 2;
    background-color: transparent;
    opacity: 0.5;
    -webkit-appearance: none;
    border: none;
    width: 34x;
    cursor: pointer;
    outline: none;
    margin-left: 10px; }
    .OrderContainer .order-title-container .clipboard-btn:hover {
      background-color: #e2e2e2;
      opacity: 1; }


.LicenseManagerReport {
  margin-top: 14px; }

.LicenseManagerProductForm .input-cont .NumberInput input {
  max-width: 400px; }

.LicenseManagerProductForm-selected-policies {
  margin: 10px;
  padding-inline-start: 0; }
  .LicenseManagerProductForm-selected-policies li {
    font-size: 13px;
    color: #525462;
    margin-bottom: 4px; }

.LicenseManagerProduct {
  margin: 0 0 20px;
  background: #fff;
  border: 1px solid #e3e3e3;
  border-left: 4px solid #707070;
  padding: 10px 15px;
  font-size: 13px;
  color: #464854; }
  .LicenseManagerProduct .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px; }
    .LicenseManagerProduct .header .product-name {
      font-size: 13px;
      font-weight: 600; }
    .LicenseManagerProduct .header .actions .Button {
      margin-bottom: 0;
      margin-left: 20px; }
  .LicenseManagerProduct .details {
    display: flex; }
    .LicenseManagerProduct .details .label {
      width: 30%;
      min-width: 100px;
      word-break: break-word;
      box-sizing: border-box;
      padding: 6px 2px;
      color: #464854;
      font-weight: 500; }
    .LicenseManagerProduct .details .value {
      width: 70%;
      word-break: break-word;
      box-sizing: border-box;
      padding: 6px 2px;
      color: #525462;
      font-weight: 400; }

.LicenseManagerPage .Tabs .react-tabs__tab-list {
  margin-bottom: 0; }

.LicenseManagerPage .Tabs .react-tabs__tab-panel {
  padding-top: 0; }

.LicenseManagerPage-permissions .permission-actions {
  display: flex;
  align-items: flex-end; }
  .LicenseManagerPage-permissions .permission-actions .Button {
    margin-bottom: 4px; }

.LicenseManagerPage-permissions .DescriptionTable {
  margin-top: 3px; }

.LicenseManagerPage-products-list .Label .Label-elem {
  font-size: 15px;
  color: #464854;
  font-weight: 600; }

.LicenseManagerPage-products-list .List {
  margin-bottom: 20px; }

.LicenseManagerPage-products-list ol {
  padding-inline-start: 0;
  margin: 0; }

.LicenseManagerPage-products {
  display: flex;
  align-items: center; }

.LicenseManagerPage .checkbox-selector {
  min-width: 300px;
  max-width: 300px;
  margin-right: 10px;
  margin-bottom: -4px; }

.ProductReactivation-popup .row {
  margin-bottom: 5px; }
  .ProductReactivation-popup .row .label {
    margin-right: 6px;
    font-size: 14px;
    color: #525462; }
  .ProductReactivation-popup .row .value {
    font-weight: 500; }

.EditProductForm .second-tab {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (max-width: 1200px) {
    .EditProductForm .second-tab {
      flex-direction: column;
      align-items: center; } }
  .EditProductForm .second-tab .form-row {
    max-width: 300px; }
    .EditProductForm .second-tab .form-row.expanded .Label {
      margin-top: 4px; }
  .EditProductForm .second-tab .flex-row {
    display: flex;
    flex-wrap: wrap; }
    @media screen and (max-width: 1200px) {
      .EditProductForm .second-tab .flex-row {
        align-items: flex-start; } }
    .EditProductForm .second-tab .flex-row .NumberInput {
      flex-grow: 1;
      margin-right: 10px; }
      @media screen and (max-width: 1200px) {
        .EditProductForm .second-tab .flex-row .NumberInput {
          max-width: unset;
          width: 50%;
          margin-right: 0; } }
      .EditProductForm .second-tab .flex-row .NumberInput input {
        min-width: unset !important;
        margin-right: 10px; }
        @media screen and (max-width: 1200px) {
          .EditProductForm .second-tab .flex-row .NumberInput input {
            width: 95% !important;
            margin-right: 0; } }
    .EditProductForm .second-tab .flex-row .Selector {
      flex-grow: 1; }
      @media screen and (max-width: 1200px) {
        .EditProductForm .second-tab .flex-row .Selector {
          max-width: unset;
          width: 50%; } }
      .EditProductForm .second-tab .flex-row .Selector .selector-inner__control {
        min-height: calc(32px + 2px); }
  .EditProductForm .second-tab .left {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .EditProductForm .second-tab .left {
        margin-right: 0;
        min-height: unset;
        width: 100%; } }
    .EditProductForm .second-tab .left .NumberInput input {
      box-sizing: border-box;
      max-width: unset; }
    .EditProductForm .second-tab .left .Checkbox {
      margin-top: 10px; }
    .EditProductForm .second-tab .left .Selector {
      max-width: unset; }

.LicensePolicyForm .air-gapped-switcher {
  display: flex;
  align-items: center;
  margin-bottom: 10px; }
  .LicensePolicyForm .air-gapped-switcher .Label {
    margin: 0 0 0 10px;
    padding-bottom: 5px; }

.LicensePolicyForm .form-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (max-width: 1200px) {
    .LicensePolicyForm .form-inner {
      flex-direction: column;
      align-items: center; } }
  .LicensePolicyForm .form-inner .Subtitle {
    margin: 10px 0; }
  .LicensePolicyForm .form-inner .TextInput input,
  .LicensePolicyForm .form-inner .NumberInput input,
  .LicensePolicyForm .form-inner .PhoneNumberInput input {
    max-width: unset;
    min-width: unset;
    box-sizing: border-box; }
  .LicensePolicyForm .form-inner .hidden {
    visibility: hidden; }
  .LicensePolicyForm .form-inner .Checkbox {
    margin-bottom: 4px;
    margin-top: 14px; }
  .LicensePolicyForm .form-inner .form-row.trial-checkbox {
    margin-top: 14px; }
  .LicensePolicyForm .form-inner .form-row.trial .Label {
    margin-top: 0; }
  .LicensePolicyForm .form-inner .form-row.prevent-vm .Checkbox {
    margin-bottom: 14px; }
  .LicensePolicyForm .form-inner .form-row.overage-options .Checkbox {
    margin-top: 14px !important; }
  .LicensePolicyForm .form-inner .form-row.overage-options .Label {
    margin-top: 6px; }
  .LicensePolicyForm .form-inner .form-row.floating .Checkbox, .LicensePolicyForm .form-inner .form-row.max-activations .Checkbox {
    margin-top: 0; }
  .LicensePolicyForm .form-inner .form-row.floating .Label, .LicensePolicyForm .form-inner .form-row.max-activations .Label {
    margin-top: 7px; }
  .LicensePolicyForm .form-inner .flex-row {
    display: flex;
    flex-wrap: wrap; }
    @media screen and (max-width: 1200px) {
      .LicensePolicyForm .form-inner .flex-row {
        align-items: flex-start; } }
    .LicensePolicyForm .form-inner .flex-row .NumberInput {
      flex-grow: 1;
      margin-right: 10px; }
      @media screen and (max-width: 1200px) {
        .LicensePolicyForm .form-inner .flex-row .NumberInput {
          max-width: unset;
          width: 50%;
          margin-right: 0; } }
      .LicensePolicyForm .form-inner .flex-row .NumberInput input {
        min-width: unset !important;
        margin-right: 10px; }
        @media screen and (max-width: 1200px) {
          .LicensePolicyForm .form-inner .flex-row .NumberInput input {
            width: 95% !important;
            margin-right: 0; } }
    .LicensePolicyForm .form-inner .flex-row .Selector {
      flex-grow: 1; }
      @media screen and (max-width: 1200px) {
        .LicensePolicyForm .form-inner .flex-row .Selector {
          max-width: unset;
          width: 50%; } }
      .LicensePolicyForm .form-inner .flex-row .Selector .selector-inner__control {
        min-height: calc(32px + 2px); }
  .LicensePolicyForm .form-inner .left {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .LicensePolicyForm .form-inner .left {
        margin-right: 0;
        min-height: unset;
        width: 100%; } }
    .LicensePolicyForm .form-inner .left .Checkbox {
      margin-top: 0 !important; }
    .LicensePolicyForm .form-inner .left .Selector {
      max-width: unset; }
  .LicensePolicyForm .form-inner .divider {
    display: none;
    margin: 30px 30px 10px;
    background: #e3e3e3;
    width: 1px; }
    @media screen and (max-width: 1200px) {
      .LicensePolicyForm .form-inner .divider {
        display: none; } }
    .LicensePolicyForm .form-inner .divider.hidden {
      display: block;
      visibility: hidden; }
    .LicensePolicyForm .form-inner .divider.active {
      display: block; }
      @media screen and (max-width: 1200px) {
        .LicensePolicyForm .form-inner .divider.active {
          display: none; } }
  .LicensePolicyForm .form-inner .right {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .LicensePolicyForm .form-inner .right {
        margin-left: 0;
        min-height: unset;
        width: 100%; } }
    .LicensePolicyForm .form-inner .right.hidden {
      visibility: hidden; }
    .LicensePolicyForm .form-inner .right .Selector {
      max-width: unset; }
    .LicensePolicyForm .form-inner .right .DateInput {
      max-width: unset;
      min-width: unset;
      width: 100%; }
      .LicensePolicyForm .form-inner .right .DateInput input {
        max-width: unset;
        min-width: unset; }
    .LicensePolicyForm .form-inner .right .flex-row {
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 1200px) {
        .LicensePolicyForm .form-inner .right .flex-row {
          align-items: flex-start; } }
      .LicensePolicyForm .form-inner .right .flex-row .NumberInput {
        flex-grow: 1;
        margin-right: 10px; }
        @media screen and (max-width: 1200px) {
          .LicensePolicyForm .form-inner .right .flex-row .NumberInput {
            max-width: unset;
            width: 50%;
            margin-right: 0; } }
        .LicensePolicyForm .form-inner .right .flex-row .NumberInput input {
          min-width: unset !important;
          margin-right: 10px; }
          @media screen and (max-width: 1200px) {
            .LicensePolicyForm .form-inner .right .flex-row .NumberInput input {
              width: 95% !important;
              margin-right: 0; } }
      .LicensePolicyForm .form-inner .right .flex-row .Selector {
        flex-grow: 1; }
        @media screen and (max-width: 1200px) {
          .LicensePolicyForm .form-inner .right .flex-row .Selector {
            max-width: unset;
            width: 50%; } }
        .LicensePolicyForm .form-inner .right .flex-row .Selector .selector-inner__control {
          min-height: calc(32px + 2px); }

.TrialOptions .Label {
  margin-top: 5px; }

.TrialOptions .flex-row {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px; }

.TrialOptions .NumberInput {
  max-width: 140px;
  margin-right: 10px; }
  .TrialOptions .NumberInput input {
    max-width: 140px;
    min-width: 140px; }

.TrialOptions .Selector {
  flex-grow: 1; }
  .TrialOptions .Selector .selector-inner__control {
    min-height: calc(32px + 2px); }

.InstallationFileOptions {
  background: #fff;
  border: 1px solid #e3e3e3;
  padding: 5px 20px 30px;
  max-width: 760px; }

.ProductBundleForm .form-row {
  max-width: 300px; }
  .ProductBundleForm .form-row .Selector {
    max-width: 300px; }
  .ProductBundleForm .form-row .products-list ul {
    margin: 10px 0 0 0;
    list-style-type: square;
    padding-inline-start: 14px; }
    .ProductBundleForm .form-row .products-list ul li {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 6px; }
      .ProductBundleForm .form-row .products-list ul li span.code {
        margin-left: 6px;
        font-weight: normal;
        font-size: 12px; }

.ProductFeatureForm .form-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (max-width: 1200px) {
    .ProductFeatureForm .form-inner {
      flex-direction: column;
      align-items: center; } }
  .ProductFeatureForm .form-inner .Subtitle {
    margin: 10px 0; }
  .ProductFeatureForm .form-inner .TextInput input,
  .ProductFeatureForm .form-inner .NumberInput input {
    max-width: unset;
    min-width: unset;
    box-sizing: border-box; }
  .ProductFeatureForm .form-inner .hidden {
    visibility: hidden; }
  .ProductFeatureForm .form-inner .left {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .ProductFeatureForm .form-inner .left {
        margin-right: 0;
        min-height: unset;
        width: 100%; } }
    .ProductFeatureForm .form-inner .left .Checkbox {
      margin-top: 0 !important; }
    .ProductFeatureForm .form-inner .left .Selector {
      max-width: unset; }
    .ProductFeatureForm .form-inner .left .trial-checkbox {
      margin-top: 20px; }
  .ProductFeatureForm .form-inner .divider {
    display: none;
    margin: 30px 30px 10px;
    background: #e3e3e3;
    width: 1px; }
    @media screen and (max-width: 1200px) {
      .ProductFeatureForm .form-inner .divider {
        display: none; } }
    .ProductFeatureForm .form-inner .divider.hidden {
      display: block;
      visibility: hidden; }
    .ProductFeatureForm .form-inner .divider.active {
      display: block; }
      @media screen and (max-width: 1200px) {
        .ProductFeatureForm .form-inner .divider.active {
          display: none; } }
  .ProductFeatureForm .form-inner .right {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .ProductFeatureForm .form-inner .right {
        margin-left: 0;
        min-height: unset;
        width: 100%; } }
    .ProductFeatureForm .form-inner .right.hidden {
      visibility: hidden; }
    .ProductFeatureForm .form-inner .right .Checkbox {
      margin-top: 14px; }
    .ProductFeatureForm .form-inner .right .Selector {
      max-width: unset; }
    .ProductFeatureForm .form-inner .right .DateInput {
      max-width: unset;
      min-width: unset;
      width: 100%; }
      .ProductFeatureForm .form-inner .right .DateInput input {
        max-width: unset;
        min-width: unset; }
    .ProductFeatureForm .form-inner .right .flex-row {
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 1200px) {
        .ProductFeatureForm .form-inner .right .flex-row {
          align-items: flex-start; } }
      .ProductFeatureForm .form-inner .right .flex-row .NumberInput {
        flex-grow: 1;
        margin-right: 10px; }
        @media screen and (max-width: 1200px) {
          .ProductFeatureForm .form-inner .right .flex-row .NumberInput {
            max-width: unset;
            width: 50%;
            margin-right: 0; } }
        .ProductFeatureForm .form-inner .right .flex-row .NumberInput input {
          min-width: unset !important;
          margin-right: 10px; }
          @media screen and (max-width: 1200px) {
            .ProductFeatureForm .form-inner .right .flex-row .NumberInput input {
              width: 95% !important;
              margin-right: 0; } }
      .ProductFeatureForm .form-inner .right .flex-row .Selector {
        flex-grow: 1; }
        @media screen and (max-width: 1200px) {
          .ProductFeatureForm .form-inner .right .flex-row .Selector {
            max-width: unset;
            width: 50%; } }
        .ProductFeatureForm .form-inner .right .flex-row .Selector .selector-inner__control {
          min-height: calc(32px + 2px); }
    .ProductFeatureForm .form-inner .right .form-row.row-expanded .Label {
      margin-top: 5px; }
    .ProductFeatureForm .form-inner .right .form-row.initial-checkbox .Checkbox {
      margin-top: 0; }

.product-version-form .Selector {
  max-width: 300px; }

.product-version-form .TextInput input {
  min-width: unset; }

.product-version-form .DateInput {
  width: 100%; }
  @media screen and (max-width: 850px) {
    .product-version-form .DateInput {
      min-width: unset; }
      .product-version-form .DateInput input {
        min-width: unset; } }

.product-version-form .form-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (max-width: 1200px) {
    .product-version-form .form-inner {
      flex-direction: column;
      align-items: center; } }
  .product-version-form .form-inner .TextInput input,
  .product-version-form .form-inner .NumberInput input {
    max-width: unset;
    min-width: unset;
    box-sizing: border-box; }
  .product-version-form .form-inner .hidden {
    visibility: hidden; }
  .product-version-form .form-inner .left {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .product-version-form .form-inner .left {
        margin-right: 0;
        min-height: unset;
        width: 100%; } }
    .product-version-form .form-inner .left .Checkbox {
      margin-top: 10px !important; }
    .product-version-form .form-inner .left .Selector {
      max-width: unset; }
    .product-version-form .form-inner .left .trial-checkbox {
      margin-top: 20px; }
  .product-version-form .form-inner .divider {
    display: none;
    margin: 30px 30px 10px;
    background: #e3e3e3;
    width: 1px; }
    @media screen and (max-width: 1200px) {
      .product-version-form .form-inner .divider {
        display: none; } }
    .product-version-form .form-inner .divider.hidden {
      display: block;
      visibility: hidden; }
    .product-version-form .form-inner .divider.active {
      display: block; }
      @media screen and (max-width: 1200px) {
        .product-version-form .form-inner .divider.active {
          display: none; } }
  .product-version-form .form-inner .right {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .product-version-form .form-inner .right {
        margin-left: 0;
        min-height: unset;
        width: 100%; } }
    .product-version-form .form-inner .right.hidden {
      visibility: hidden; }
    .product-version-form .form-inner .right .Selector {
      max-width: unset; }
    .product-version-form .form-inner .right .DateInput {
      max-width: unset;
      min-width: unset;
      width: 100%; }
      .product-version-form .form-inner .right .DateInput input {
        max-width: unset;
        min-width: unset; }

.ProductList-filters {
  margin-bottom: 10px; }
  .ProductList-filters .AdvancedSearch-btn {
    -webkit-appearance: none;
    appearance: none;
    font-size: 14px;
    color: #2e86de;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    outline: none;
    margin-bottom: 5px;
    display: flex;
    align-items: center; }
    .ProductList-filters .AdvancedSearch-btn svg {
      margin-right: 5px; }
  .ProductList-filters .AdvancedSearch-wrapper {
    overflow: visible;
    transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1);
    -webkit-animation-delay: 0;
    -webkit-animation-duration: 400ms;
    -webkit-animation-name: visibleOverflow;
    -webkit-animation-timing-function: ease-in;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0;
    -moz-animation-duration: 400ms;
    -moz-animation-name: visibleOverflow;
    -moz-animation-timing-function: ease-in;
    -moz-animation-fill-mode: forwards;
    -o-animation-delay: 0;
    -o-animation-duration: 400ms;
    -o-animation-name: visibleOverflow;
    -o-animation-timing-function: ease-in;
    -o-animation-fill-mode: forwards;
    animation-delay: 0;
    animation-duration: 400ms;
    animation-name: visibleOverflow;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards; }
    .ProductList-filters .AdvancedSearch-wrapper.hide {
      overflow: hidden;
      padding-bottom: 0px;
      -webkit-animation-delay: 0;
      -webkit-animation-duration: 10ms;
      -webkit-animation-name: hiddenOverflow;
      -webkit-animation-timing-function: ease-in;
      -webkit-animation-fill-mode: forwards;
      -moz-animation-delay: 0;
      -moz-animation-duration: 10ms;
      -moz-animation-name: hiddenOverflow;
      -moz-animation-timing-function: ease-in;
      -moz-animation-fill-mode: forwards;
      -o-animation-delay: 0;
      -o-animation-duration: 10ms;
      -o-animation-name: hiddenOverflow;
      -o-animation-timing-function: ease-in;
      -o-animation-fill-mode: forwards;
      animation-delay: 0;
      animation-duration: 10ms;
      animation-name: hiddenOverflow;
      animation-timing-function: ease-in;
      animation-fill-mode: forwards; }
  .ProductList-filters .AdvancedSearch {
    display: block;
    background-color: #ffffff;
    border: 1px solid #e3e3e3;
    margin-top: 3px;
    max-height: 500px;
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1); }
    .ProductList-filters .AdvancedSearch.hide {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      transform: translateY(-230px); }
    .ProductList-filters .AdvancedSearch-fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .ProductList-filters .AdvancedSearch-fields .filter-section {
        width: 48%;
        padding: 0 0 0 20px; }
      .ProductList-filters .AdvancedSearch-fields .more-filters {
        width: 100%;
        margin-top: 20px; }
        .ProductList-filters .AdvancedSearch-fields .more-filters .heading {
          display: flex;
          align-items: center;
          -webkit-appearance: none;
          appearance: none;
          padding: 0 0 0 20px;
          font-weight: 500;
          font-size: 13px;
          color: #2e86de;
          cursor: pointer;
          background-color: transparent;
          border: none;
          outline: none; }
          .ProductList-filters .AdvancedSearch-fields .more-filters .heading svg {
            fill: #2e86de;
            height: 15px;
            width: 15px;
            margin-left: 5px;
            transform: rotate(0);
            transition: transform 0.3s cubic-bezier(0.25, 1, 0.5, 1); }
            .ProductList-filters .AdvancedSearch-fields .more-filters .heading svg.open {
              transform: rotate(-180deg); }
        .ProductList-filters .AdvancedSearch-fields .more-filters-wrapper {
          overflow: visible;
          -webkit-animation-delay: 0;
          -webkit-animation-duration: 400ms;
          -webkit-animation-name: visibleOverflow;
          -webkit-animation-timing-function: ease-in;
          -webkit-animation-fill-mode: forwards;
          -moz-animation-delay: 0;
          -moz-animation-duration: 400ms;
          -moz-animation-name: visibleOverflow;
          -moz-animation-timing-function: ease-in;
          -moz-animation-fill-mode: forwards;
          -o-animation-delay: 0;
          -o-animation-duration: 400ms;
          -o-animation-name: visibleOverflow;
          -o-animation-timing-function: ease-in;
          -o-animation-fill-mode: forwards;
          animation-delay: 0;
          animation-duration: 400ms;
          animation-name: visibleOverflow;
          animation-timing-function: ease-in;
          animation-fill-mode: forwards; }
          .ProductList-filters .AdvancedSearch-fields .more-filters-wrapper.hide {
            overflow: hidden;
            -webkit-animation-delay: 0;
            -webkit-animation-duration: 10ms;
            -webkit-animation-name: hiddenOverflow;
            -webkit-animation-timing-function: ease-in;
            -webkit-animation-fill-mode: forwards;
            -moz-animation-delay: 0;
            -moz-animation-duration: 10ms;
            -moz-animation-name: hiddenOverflow;
            -moz-animation-timing-function: ease-in;
            -moz-animation-fill-mode: forwards;
            -o-animation-delay: 0;
            -o-animation-duration: 10ms;
            -o-animation-name: hiddenOverflow;
            -o-animation-timing-function: ease-in;
            -o-animation-fill-mode: forwards;
            animation-delay: 0;
            animation-duration: 10ms;
            animation-name: hiddenOverflow;
            animation-timing-function: ease-in;
            animation-fill-mode: forwards; }
          .ProductList-filters .AdvancedSearch-fields .more-filters-wrapper .more-filters-fields {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            max-height: 500px;
            opacity: 1;
            transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1); }
            .ProductList-filters .AdvancedSearch-fields .more-filters-wrapper .more-filters-fields.hide {
              max-height: 0;
              opacity: 0;
              transform: translateY(-80px); }
    .ProductList-filters .AdvancedSearch-actions {
      margin-top: 20px;
      background-color: #f0f0f0;
      width: 100%;
      padding: 5px 0 5px 20px; }
      .ProductList-filters .AdvancedSearch-actions .Button {
        margin-right: 40px;
        margin-top: 10px; }
  .ProductList-filters .Filters-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px; }

@keyframes visibleOverflow {
  from {
    overflow: hidden; }
  to {
    overflow: visible; } }

@keyframes hiddenOverflow {
  from {
    overflow: visible; }
  to {
    overflow: hidden; } }

.ProductDetails .Logs {
  margin-top: 20px; }

.bundle-title-container {
  display: flex;
  align-items: center; }
  .bundle-title-container .clipboard-btn {
    display: inline-flex;
    align-items: center;
    z-index: 2;
    background-color: transparent;
    opacity: 0.5;
    -webkit-appearance: none;
    border: none;
    width: 34x;
    cursor: pointer;
    outline: none;
    margin-left: 10px; }
    .bundle-title-container .clipboard-btn:hover {
      background-color: #e2e2e2;
      opacity: 1; }

.ProductContainer-tabs .Tab-header {
  font-weight: 500;
  font-size: 15px;
  max-width: 600px;
  margin-bottom: 20px; }

.ProductContainer-tabs .features-tab .Notice {
  margin-top: 0;
  max-width: unset; }

.product-title-container {
  display: flex;
  align-items: center; }
  .product-title-container .clipboard-btn {
    display: inline-flex;
    align-items: center;
    z-index: 2;
    background-color: transparent;
    opacity: 0.5;
    -webkit-appearance: none;
    border: none;
    width: 34x;
    cursor: pointer;
    outline: none;
    margin-left: 10px; }
    .product-title-container .clipboard-btn:hover {
      background-color: #e2e2e2;
      opacity: 1; }

.ProductInformations-form .form-row {
  display: flex; }
  .ProductInformations-form .form-row.file-checkbox {
    margin-top: 10px; }
  .ProductInformations-form .form-row .form-column {
    margin-right: 40px; }
    .ProductInformations-form .form-row .form-column.right-input .TextInput,
    .ProductInformations-form .form-row .form-column.right-input .NumberInput {
      max-width: 260px; }
      .ProductInformations-form .form-row .form-column.right-input .TextInput input,
      .ProductInformations-form .form-row .form-column.right-input .NumberInput input {
        max-width: 260px;
        min-width: 260px; }
    .ProductInformations-form .form-row .form-column .Selector {
      min-width: 300px; }

.ProductInformations-actions {
  margin-top: 30px; }

.VerifyProduct-details .product-information {
  background: #ffffff;
  max-width: 738px;
  border: 1px solid #e3e3e3;
  border-bottom: none;
  padding: 20px 0 20px 20px; }
  .VerifyProduct-details .product-information .product-name {
    font-size: 18px;
    font-weight: 600; }
  .VerifyProduct-details .product-information .product-code {
    color: #525462; }

.VerifyProduct-details .product-details {
  max-width: 760px; }
  .VerifyProduct-details .product-details .installation-files-row {
    vertical-align: top; }
    .VerifyProduct-details .product-details .installation-files-row .InstallationFileData div {
      margin: 2px; }
      .VerifyProduct-details .product-details .installation-files-row .InstallationFileData div span {
        font-size: 12px;
        padding-right: 5px; }

.VerifyProduct-actions {
  margin-top: 30px; }
  .VerifyProduct-actions .Button {
    margin-right: 40px; }


.PermissionTemplateFormTitle {
  display: flex;
  align-items: center; }
  .PermissionTemplateFormTitle .confirm-btn {
    margin-left: auto; }

.PermissionTemplateForm .row.checkbox-cont {
  margin-top: 10px;
  margin-bottom: 20px;
  padding-bottom: 1px;
  border-bottom: 1px solid #ededed; }
  .PermissionTemplateForm .row.checkbox-cont .Checkbox {
    width: 15%; }

.UserLabelsForm .new-label-btn .Button,
.UserLabelsForm .back-label-btn .Button {
  padding: 0;
  color: #2e86de; }
  .UserLabelsForm .new-label-btn .Button span,
  .UserLabelsForm .back-label-btn .Button span {
    margin-right: 3px; }

.UserPermissionsFormTitle {
  display: flex;
  align-items: center; }
  .UserPermissionsFormTitle .confirm-btn {
    margin-left: auto; }

.UserPermissionsForm .row.checkbox-cont {
  margin-top: 10px;
  margin-bottom: 20px;
  padding-bottom: 1px;
  border-bottom: 1px solid #ededed; }
  .UserPermissionsForm .row.checkbox-cont .Checkbox {
    width: 15%; }

.user-title-container .labels {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .user-title-container .labels .Button {
    margin-bottom: 0; }

.user-title-container .user-title {
  display: flex;
  align-items: center; }
  .user-title-container .user-title .clipboard-btn {
    display: inline-flex;
    align-items: center;
    z-index: 2;
    background-color: transparent;
    opacity: 0.5;
    -webkit-appearance: none;
    border: none;
    width: 34x;
    cursor: pointer;
    outline: none;
    margin-left: 10px; }
    .user-title-container .user-title .clipboard-btn:hover {
      background-color: #e2e2e2;
      opacity: 1; }

.UserContainer-permissions {
  background: #fff;
  border: 1px solid #e3e3e3;
  padding: 10px 15px; }
  .UserContainer-permissions .Button {
    margin: 0 20px 0 0; }
  .UserContainer-permissions .permissions-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 50%;
    padding-inline-start: 0; }
    .UserContainer-permissions .permissions-list .permission-item {
      list-style-type: square;
      font-size: 14px;
      font-weight: 400;
      width: 40%;
      margin-bottom: 5px;
      margin-left: 20px; }

.AddSSOProviderForm .docs-link {
  display: flex;
  margin: 10px 0; }
  .AddSSOProviderForm .docs-link p {
    margin: 0;
    font-size: 14px;
    color: #525462; }
  .AddSSOProviderForm .docs-link a {
    color: #525462;
    font-size: 14px;
    margin-left: 3px; }

.AddSSOProviderForm .google-form,
.AddSSOProviderForm .saml-form {
  display: flex;
  flex-direction: column; }
  .AddSSOProviderForm .google-form .TextInput input,
  .AddSSOProviderForm .saml-form .TextInput input {
    max-width: 400px; }
  .AddSSOProviderForm .google-form .FileUploader,
  .AddSSOProviderForm .saml-form .FileUploader {
    max-width: 400px; }
    .AddSSOProviderForm .google-form .FileUploader .dzu-dropzone,
    .AddSSOProviderForm .saml-form .FileUploader .dzu-dropzone {
      min-height: 90px;
      justify-content: center; }
      .AddSSOProviderForm .google-form .FileUploader .dzu-dropzone .dzu-previewContainer,
      .AddSSOProviderForm .saml-form .FileUploader .dzu-dropzone .dzu-previewContainer {
        border-bottom: none; }
    .AddSSOProviderForm .google-form .FileUploader .dzu-submitButtonContainer,
    .AddSSOProviderForm .saml-form .FileUploader .dzu-submitButtonContainer {
      display: none; }

.AirGap .row.air-gapped .Button {
  margin: 0; }

.AirGap .row.air-gapped .air-gap-loading .ContentLoader {
  padding: 10px;
  text-align: left; }
  .AirGap .row.air-gapped .air-gap-loading .ContentLoader-spinner {
    width: 20px;
    height: 20px; }

.AirGap .row.air-gapped .air-gap-no-key {
  margin-top: 10px;
  font-weight: 600;
  font-size: 14px;
  color: #525462; }

.AirGap .row h3 {
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  color: #464854; }

.AirGap .row .descriptive-header {
  display: flex; }
  .AirGap .row .descriptive-header svg {
    margin-left: 5px; }

.EditLicenseAPIKeyForm .license-api-key {
  margin-bottom: 20px; }

.EditLicenseAPIKeyForm .readonly-checkbox {
  margin-top: 12px; }

.LicenseAPI .license-keys-list .key-products-row {
  display: flex;
  flex-wrap: wrap; }
  .LicenseAPI .license-keys-list .key-products-row a {
    margin-right: 5px; }
    .LicenseAPI .license-keys-list .key-products-row a:after {
      content: ','; }
    .LicenseAPI .license-keys-list .key-products-row a:last-of-type::after {
      content: none; }

.LicenseAPI .license-keys-list .key-container {
  display: flex;
  align-items: center; }
  .LicenseAPI .license-keys-list .key-container span {
    margin-right: auto; }
  .LicenseAPI .license-keys-list .key-container .clipboard-btn {
    display: inline-flex;
    align-items: center;
    z-index: 2;
    background-color: transparent;
    opacity: 0.5;
    -webkit-appearance: none;
    border: none;
    width: 34x;
    cursor: pointer;
    outline: none;
    margin-left: 10px; }
    .LicenseAPI .license-keys-list .key-container .clipboard-btn:hover {
      background-color: #e2e2e2;
      opacity: 1; }

.LicenseAPI .license-keys-list .key-products-row {
  display: flex;
  flex-wrap: wrap; }
  .LicenseAPI .license-keys-list .key-products-row a {
    margin-right: 5px; }
    .LicenseAPI .license-keys-list .key-products-row a:after {
      content: ','; }
    .LicenseAPI .license-keys-list .key-products-row a:last-of-type::after {
      content: none; }

.LicenseAPI .license-keys-list .key-container {
  display: flex;
  align-items: center; }
  .LicenseAPI .license-keys-list .key-container span {
    margin-right: auto; }
  .LicenseAPI .license-keys-list .key-container .clipboard-btn {
    display: inline-flex;
    align-items: center;
    z-index: 2;
    background-color: transparent;
    opacity: 0.5;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    width: 34x;
    cursor: pointer;
    outline: none;
    margin-left: 10px; }
    .LicenseAPI .license-keys-list .key-container .clipboard-btn:hover {
      background-color: #e2e2e2;
      opacity: 1; }

.title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem; }

.inline-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: baseline; }

.oauth-rotation-info {
  font-size: 12px;
  opacity: 0.85; }

.EditMgmtKeyForm .mgmt-key {
  margin-bottom: 20px;
  font-size: 13px;
  font-weight: 500; }

.EditMgmtKeyForm .readonly-checkbox {
  margin-top: 12px; }

.ManagementAPI .management-keys-list .key-container {
  display: flex;
  align-items: center; }
  .ManagementAPI .management-keys-list .key-container span {
    margin-right: auto; }
  .ManagementAPI .management-keys-list .key-container .clipboard-btn {
    display: inline-flex;
    align-items: center;
    z-index: 2;
    background-color: transparent;
    opacity: 0.5;
    -webkit-appearance: none;
    border: none;
    width: 34x;
    cursor: pointer;
    outline: none;
    margin-left: 10px; }
    .ManagementAPI .management-keys-list .key-container .clipboard-btn:hover {
      background-color: #e2e2e2;
      opacity: 1; }

.ApiKeys .row {
  margin-bottom: 30px; }
  .ApiKeys .row.air-gapped .Button {
    margin: 0; }
  .ApiKeys .row.air-gapped .air-gap-loading .ContentLoader {
    padding: 10px;
    text-align: left; }
    .ApiKeys .row.air-gapped .air-gap-loading .ContentLoader-spinner {
      width: 20px;
      height: 20px; }
  .ApiKeys .row.air-gapped .air-gap-no-key {
    margin-top: 10px;
    font-weight: 600;
    font-size: 14px;
    color: #525462; }
  .ApiKeys .row h3 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
    color: #464854; }
  .ApiKeys .row .descriptive-header {
    display: flex; }
    .ApiKeys .row .descriptive-header svg {
      margin-left: 5px; }

.ApiKeys .management-keys-list .key-container {
  display: flex;
  align-items: center; }
  .ApiKeys .management-keys-list .key-container span {
    margin-right: auto; }
  .ApiKeys .management-keys-list .key-container .clipboard-btn {
    display: inline-flex;
    align-items: center;
    z-index: 2;
    background-color: transparent;
    opacity: 0.5;
    -webkit-appearance: none;
    border: none;
    width: 34x;
    cursor: pointer;
    outline: none;
    margin-left: 10px; }
    .ApiKeys .management-keys-list .key-container .clipboard-btn:hover {
      background-color: #e2e2e2;
      opacity: 1; }

.SalesforceInit .TextInput {
  margin-bottom: 16px; }
  .SalesforceInit .TextInput input {
    max-width: 400px; }

.HubSpotInit .TextInput {
  margin-bottom: 16px; }
  .HubSpotInit .TextInput input {
    max-width: 400px; }

.FastSpringForm .TextArea {
  margin-bottom: 16px; }
  .FastSpringForm .TextArea textarea {
    max-width: 400px; }

.FastSpringForm .Selector {
  margin-bottom: 16px; }

.FastSpringForm .Notice {
  max-width: 380px; }

.StripeForm .TextArea {
  margin-bottom: 16px; }
  .StripeForm .TextArea textarea {
    max-width: 400px; }

.StripeForm .Selector {
  margin-bottom: 16px; }

.StripeForm .Notice {
  max-width: 380px; }

.Integrations .row {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin-bottom: 30px; }
  .Integrations .row h3 {
    margin-top: 0px;
    margin-bottom: 1px;
    font-weight: 600;
    font-size: 18px;
    color: #464854; }
  .Integrations .row .Button {
    max-width: 140px; }

.Integrations .docs-link {
  display: flex;
  margin-top: 4px; }
  .Integrations .docs-link p {
    margin: 0;
    font-size: 14px;
    color: #525462; }
  .Integrations .docs-link a {
    color: #525462;
    font-size: 14px;
    margin-left: 3px; }

.Integrations .ClipboardRow {
  margin-bottom: 0; }

.Integrations .actions {
  display: flex; }
  .Integrations .actions .Button {
    margin-right: 20px; }

.AddSSOProviderForm .docs-link {
  display: flex;
  margin: 10px 0; }
  .AddSSOProviderForm .docs-link p {
    margin: 0;
    font-size: 14px;
    color: #525462; }
  .AddSSOProviderForm .docs-link a {
    color: #525462;
    font-size: 14px;
    margin-left: 3px; }

.AddSSOProviderForm .google-form,
.AddSSOProviderForm .saml-form {
  display: flex;
  flex-direction: column; }
  .AddSSOProviderForm .google-form .TextInput input,
  .AddSSOProviderForm .saml-form .TextInput input {
    max-width: 400px; }
  .AddSSOProviderForm .google-form .FileUploader,
  .AddSSOProviderForm .saml-form .FileUploader {
    max-width: 400px; }
    .AddSSOProviderForm .google-form .FileUploader .dzu-dropzone,
    .AddSSOProviderForm .saml-form .FileUploader .dzu-dropzone {
      min-height: 90px;
      justify-content: center; }
      .AddSSOProviderForm .google-form .FileUploader .dzu-dropzone .dzu-previewContainer,
      .AddSSOProviderForm .saml-form .FileUploader .dzu-dropzone .dzu-previewContainer {
        border-bottom: none; }
    .AddSSOProviderForm .google-form .FileUploader .dzu-submitButtonContainer,
    .AddSSOProviderForm .saml-form .FileUploader .dzu-submitButtonContainer {
      display: none; }

.AddSSOProviderForm .create-user-config {
  margin-top: 20px; }

.SsoSettings .row h3 {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  color: #464854; }

.SsoSettings .row.btn-row .Button {
  margin-top: 10px; }

.SsoSettings .create-user-switcher {
  display: flex;
  align-items: center; }
  .SsoSettings .create-user-switcher .Label {
    margin-top: 0;
    margin-left: 10px; }

.SettingsContainer .Security {
  margin-top: 30px; }
  .SettingsContainer .Security .Button {
    margin-top: 10px !important; }
  .SettingsContainer .Security .description {
    font-size: 14px;
    color: #525462; }

.SettingsContainer .row.checkboxes {
  margin-top: 14px; }

.SettingsContainer .row.management-key .Button {
  margin: 0; }

.SettingsContainer .row .text-input-cont {
  position: relative;
  max-width: 450px; }
  .SettingsContainer .row .text-input-cont .TextInput input {
    max-width: 450px; }

.SettingsContainer .form-cont .TextInput {
  max-width: 300px; }

.SettingsContainer .form-cont .Button {
  margin-top: 20px; }

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }

.oauth-message {
  font-size: 13px;
  color: #464854;
  font-weight: 400;
  text-align: left; }

.TaxDetailsForm .tax-type-fields .row {
  margin-bottom: 10px; }
  .TaxDetailsForm .tax-type-fields .row .TextInput input {
    max-width: 400px; }

.BillingDetails .Button {
  margin-bottom: 5px; }

.InvoicesList .invoice-status {
  flex-direction: row; }
  .InvoicesList .invoice-status svg {
    margin-right: 5px; }

.PaymentMethod .payment-method-card {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 30px 20px 10px;
  border: 1px solid #ededed;
  border-radius: 4px;
  min-width: 200px; }
  .PaymentMethod .payment-method-card .card-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    border-right: 1px solid rgba(112, 112, 112, 0.15);
    margin-right: 60px;
    flex: 1 1;
    margin-bottom: 20px;
    max-width: 250px; }
    .PaymentMethod .payment-method-card .card-details .card-last4 {
      display: flex;
      align-items: center;
      margin-top: 10px;
      font-size: 13px; }
      .PaymentMethod .payment-method-card .card-details .card-last4 span {
        display: flex;
        align-items: center;
        margin-right: 5px; }
    .PaymentMethod .payment-method-card .card-details .card-expiry {
      display: flex;
      align-items: center;
      margin-top: 10px;
      font-size: 10px;
      color: #525462; }
      .PaymentMethod .payment-method-card .card-details .card-expiry .card-expiration-date {
        margin-left: 3px; }
  .PaymentMethod .payment-method-card .user-details {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    margin-bottom: 20px; }
    .PaymentMethod .payment-method-card .user-details .user-details-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px; }
      .PaymentMethod .payment-method-card .user-details .user-details-row:last-of-type {
        margin-bottom: 0; }
      .PaymentMethod .payment-method-card .user-details .user-details-row .user-details-column {
        min-width: 25%; }
        .PaymentMethod .payment-method-card .user-details .user-details-row .user-details-column .Label {
          margin: 0 0 2px; }
        .PaymentMethod .payment-method-card .user-details .user-details-row .user-details-column div {
          font-size: 13px; }
  .PaymentMethod .payment-method-card .payment-action {
    align-self: center;
    display: flex;
    align-items: flex-start;
    flex: 2 1;
    min-width: 200px;
    margin-bottom: 20px;
    flex-direction: column; }
    .PaymentMethod .payment-method-card .payment-action .Button {
      min-width: 205px; }

.PaymentMethodForm .form-row {
  display: flex;
  align-items: baseline;
  justify-content: space-between; }
  .PaymentMethodForm .form-row > div {
    flex: 1 1; }
    .PaymentMethodForm .form-row > div.form-row-column {
      width: 48%;
      flex: none; }
      .PaymentMethodForm .form-row > div.form-row-column.cardholder .Label {
        margin-top: 9px; }
      .PaymentMethodForm .form-row > div.form-row-column .TextInput {
        min-width: unset;
        width: 100%; }
        .PaymentMethodForm .form-row > div.form-row-column .TextInput input {
          min-width: unset;
          max-width: unset;
          width: 100%; }
  @media screen and (max-width: 700px) {
    .PaymentMethodForm .form-row {
      flex-direction: column;
      align-items: center; }
      .PaymentMethodForm .form-row .form-row-column {
        width: 100%; }
        .PaymentMethodForm .form-row .form-row-column input {
          max-width: unset; } }

.PaymentMethodForm .StripeInput {
  height: 34px;
  width: 100%;
  outline: none;
  color: #464854;
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box; }
  .PaymentMethodForm .StripeInput .StripeElement {
    padding: 0 10px;
    border: 1px solid #e3e3e3; }
    .PaymentMethodForm .StripeInput .StripeElement--focus {
      border: 1px solid #44486c; }

.PaymentMethodForm .extra-information {
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500; }
  .PaymentMethodForm .extra-information .stripe-info {
    display: flex;
    align-items: center;
    margin-top: 5px; }
    .PaymentMethodForm .extra-information .stripe-info img {
      height: 25px; }

.BillingContainer-notice {
  margin: 10px 0; }

.BillingContainer-info {
  margin-top: 30px; }
  .BillingContainer-info h2 {
    margin: 0 0 5px;
    font-size: 18px; }

.BillingContainer .DescriptionTable-label {
  display: flex;
  align-items: center; }
  .BillingContainer .DescriptionTable-label .icon-cont {
    margin-left: 5px; }

.FreePlanTypeBox .PlanTypeBox-price {
  margin-bottom: 40px; }

.BusinessStarterPlanTypeBox .PlanTypeBox-trial {
  margin-top: 10px;
  margin-bottom: 14px;
  font-weight: bold;
  color: #13b0fc; }

.BusinessStarterPlanTypeBox .PlanTypeBox-price {
  margin-bottom: 40px; }

.BusinessPlusPlanTypeBox .PlanTypeBox-trial {
  margin-top: 10px;
  margin-bottom: 14px;
  font-weight: bold;
  color: #13b0fc; }

.BusinessPlusPlanTypeBox .PlanTypeBox-price {
  margin-bottom: 40px; }

.EnterprisePlanTypeBox .Button {
  margin-top: 2px; }

.EnterprisePlanTypeBox .PlanTypeBox-price {
  height: 138px !important; }

.EnterprisePlanTypeBox .PlanTypeBox-btn .Button {
  padding: 0 36px; }

@charset "UTF-8";
.PlanTypeContainer-heading .Subtitle {
  margin-top: 10px; }

.PlanTypeContainer-notice {
  margin-top: 15px; }

.PlanTypeContainer-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column; }
  .PlanTypeContainer-form .form-row {
    display: flex; }
    .PlanTypeContainer-form .form-row .PlanTypeButton {
      margin-right: 20px; }

.PlanTypeContainer .PlanTypeBox {
  background-image: linear-gradient(-45deg, #eef9fe, #f6f8fb);
  flex: 0 1 31.5%;
  align-self: flex-start;
  margin-right: 20px;
  padding: 42px 32px;
  margin-bottom: 60px;
  border-radius: 4px;
  background-color: #f0f9fe;
  max-width: 250px;
  text-align: center;
  box-shadow: 0px 0px 8px 2px rgba(173, 173, 173, 0.1); }
  .PlanTypeContainer .PlanTypeBox.current {
    border: 1px solid #44486c; }
  .PlanTypeContainer .PlanTypeBox-title {
    font-size: 32px;
    margin-top: 0;
    margin-bottom: 20px;
    color: #13b0fc;
    line-height: 1.25;
    font-weight: 700;
    letter-spacing: -0.5px; }
  .PlanTypeContainer .PlanTypeBox-description {
    line-height: 1.45; }
  .PlanTypeContainer .PlanTypeBox-price {
    font-size: 46px;
    font-weight: 700;
    margin-top: 40px; }
    .PlanTypeContainer .PlanTypeBox-price.free:before, .PlanTypeContainer .PlanTypeBox-price.standard:before {
      top: -20px;
      margin: 0;
      margin-right: 3px;
      font-size: 15px;
      content: '$';
      position: relative;
      font-weight: 400; }
    .PlanTypeContainer .PlanTypeBox-price.free:after, .PlanTypeContainer .PlanTypeBox-price.standard:after {
      content: '/mo';
      margin: 0;
      font-size: 15px;
      font-weight: 400; }
    .PlanTypeContainer .PlanTypeBox-price.enterprise {
      display: flex;
      align-items: center;
      line-height: 1.5;
      font-weight: 400;
      color: #9ba6b5;
      font-size: 15px;
      max-width: 150px;
      margin: 0 auto;
      height: 147px; }
  .PlanTypeContainer .PlanTypeBox-btn .upgrade-link {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: #10ac84;
    border: 1px solid #0d8767;
    text-decoration: none;
    color: white;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
    height: 44px;
    padding: 0 40px;
    margin: 0 2px;
    transition: 150ms all ease-in-out; }
  .PlanTypeContainer .PlanTypeBox-features {
    text-align: left;
    margin: 32px 6% 0;
    list-style: none;
    padding: 0; }
    .PlanTypeContainer .PlanTypeBox-features li {
      line-height: 2.5;
      font-size: 15px; }
      .PlanTypeContainer .PlanTypeBox-features li.checkmark {
        margin-left: 24px; }
        .PlanTypeContainer .PlanTypeBox-features li.checkmark::before {
          content: '\2713';
          color: #13b0fc;
          background-size: contain;
          background-position: 50%;
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-left: -24px;
          margin-right: 8px; }
      .PlanTypeContainer .PlanTypeBox-features li span {
        color: #13b0fc;
        font-weight: 700;
        margin-right: 5px; }

.Summary {
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  align-self: flex-start;
  border-radius: 4px;
  box-shadow: 0px 0px 8px 2px rgba(173, 173, 173, 0.1); }
  .Summary-heading {
    padding: 20px;
    border-radius: 4px 4px 0 0;
    font-weight: 600;
    font-size: 18px;
    background-color: #707070;
    color: #ffffff; }
  .Summary-content {
    padding: 40px 20px 10px;
    word-wrap: break-word; }
    .Summary-content .row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: 10px;
      margin-bottom: 20px;
      border-bottom: 1px solid #e3e3e3; }
      .Summary-content .row .label {
        font-size: 14px;
        font-weight: 500; }
      .Summary-content .row .value .currency {
        margin-left: 5px; }
      .Summary-content .row.total {
        font-weight: bold;
        margin-top: 30px;
        border-bottom: none; }
  .Summary .submit-btn {
    display: flex;
    justify-content: center;
    padding: 0 40px 10px; }
    .Summary .submit-btn .Button {
      width: 100%; }

.PlanUpgradeContainer-wrapper {
  display: flex;
  max-width: 1120px;
  position: relative; }
  .PlanUpgradeContainer-wrapper .form-container {
    flex: 1 1;
    max-width: 400px;
    margin-right: 40px; }
    .PlanUpgradeContainer-wrapper .form-container .form-row.first .Label {
      margin-top: 0; }
    .PlanUpgradeContainer-wrapper .form-container .form-row.submit-btn {
      margin-bottom: 20px; }
    .PlanUpgradeContainer-wrapper .form-container .form-row .payment-method-card {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 30px 20px 10px;
      border: 1px solid #ededed;
      border-radius: 4px;
      min-width: 200px; }
      .PlanUpgradeContainer-wrapper .form-container .form-row .payment-method-card .card-details {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        flex: 1 1;
        margin-bottom: 20px; }
        .PlanUpgradeContainer-wrapper .form-container .form-row .payment-method-card .card-details .card-last4 {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          font-size: 13px; }
          .PlanUpgradeContainer-wrapper .form-container .form-row .payment-method-card .card-details .card-last4 span {
            display: flex;
            align-items: center;
            margin-right: 5px; }
        .PlanUpgradeContainer-wrapper .form-container .form-row .payment-method-card .card-details .card-expiry {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10px;
          font-size: 10px;
          color: #525462; }
          .PlanUpgradeContainer-wrapper .form-container .form-row .payment-method-card .card-details .card-expiry .card-expiration-date {
            margin-left: 3px; }

.AirgapPortalData-domain {
  margin-bottom: 20px; }
  .AirgapPortalData-domain .Label {
    margin-top: 0; }
  .AirgapPortalData-domain .domain {
    font-size: 14px; }

.AirgapPortalData .config-section {
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 0; }

.AirgapPortalData .style-config .section-header {
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  background: #dadada; }

.AirgapPortalData .style-config .DescriptionTable {
  margin-bottom: 0; }

.AirgapPortalData-actions {
  margin-top: 30px; }
  .AirgapPortalData-actions .Button {
    margin-right: 30px; }

.LogoUpload .logo-upload-container .logo-upload-wrapper {
  display: flex; }
  .LogoUpload .logo-upload-container .logo-upload-wrapper .existing-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eaeaea;
    padding: 10px; }
    .LogoUpload .logo-upload-container .logo-upload-wrapper .existing-logo img {
      width: 100px;
      height: auto;
      max-height: 100px;
      object-fit: contain; }
  .LogoUpload .logo-upload-container .logo-upload-wrapper .logo-uploader {
    flex-grow: 1; }
    .LogoUpload .logo-upload-container .logo-upload-wrapper .logo-uploader .FileUploader .dzu-dropzone {
      min-height: 124px; }

.OfflinePortalData-domain {
  margin-bottom: 20px; }
  .OfflinePortalData-domain .Label {
    margin-top: 0; }
  .OfflinePortalData-domain .domain {
    font-size: 14px; }

.OfflinePortalData .config-section {
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 0; }

.OfflinePortalData .style-config .section-header {
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  background: #dadada; }

.OfflinePortalData .style-config .DescriptionTable {
  margin-bottom: 0; }

.OfflinePortalData-actions {
  margin-top: 30px; }
  .OfflinePortalData-actions .Button {
    margin-right: 30px; }

.UserPortalData-domain {
  margin-bottom: 20px; }
  .UserPortalData-domain .Label {
    margin-top: 0; }
  .UserPortalData-domain .domain {
    font-size: 14px; }

.UserPortalData .config-section {
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 0; }

.UserPortalData .style-config .section-header {
  font-size: 14px;
  font-weight: 600;
  padding: 10px;
  background: #dadada; }

.UserPortalData .style-config .DescriptionTable {
  margin-bottom: 0; }

.UserPortalData-actions {
  margin-top: 30px; }
  .UserPortalData-actions .Button {
    margin-right: 30px; }

.PortalConfiguration .Tabs {
  margin-top: 20px; }


.EmailPreview {
  background-color: #ffffff;
  box-shadow: 0px 0px 8px 2px rgba(173, 173, 173, 0.1);
  max-width: 570px;
  min-width: 570px; }
  @media only screen and (max-width: 768px) {
    .EmailPreview {
      min-width: unset; } }
  .EmailPreview-subject {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 12px; }
    .EmailPreview-subject .label {
      text-transform: uppercase;
      font-weight: 500;
      margin-right: 10px; }
  .EmailPreview-main {
    padding: 6px 12px 24px; }
    .EmailPreview-main .Logo {
      margin-bottom: 24px; }
      .EmailPreview-main .Logo img {
        width: 60px;
        height: auto; }
    .EmailPreview-main .Title {
      margin-bottom: 12px;
      font-size: 22px;
      font-weight: 600; }
    .EmailPreview-main .Text {
      margin-bottom: 20px;
      line-height: 20px; }
      .EmailPreview-main .Text:first-child {
        margin-top: 0; }
      .EmailPreview-main .Text img {
        max-width: 500px;
        object-fit: contain; }
      .EmailPreview-main .Text h1,
      .EmailPreview-main .Text h2,
      .EmailPreview-main .Text h3,
      .EmailPreview-main .Text h4,
      .EmailPreview-main .Text h5,
      .EmailPreview-main .Text h6 {
        line-height: 1.1;
        margin-top: 2.5rem;
        text-wrap: pretty; }
      .EmailPreview-main .Text h1,
      .EmailPreview-main .Text h2 {
        margin-top: 3.5rem;
        margin-bottom: 1.5rem; }
      .EmailPreview-main .Text h1 {
        font-size: 1.4rem; }
      .EmailPreview-main .Text h2 {
        font-size: 1.2rem; }
      .EmailPreview-main .Text h3 {
        font-size: 1.1rem; }
      .EmailPreview-main .Text h4,
      .EmailPreview-main .Text h5,
      .EmailPreview-main .Text h6 {
        font-size: 1rem; }
    .EmailPreview-main .Variables {
      padding: 20px 0 14px;
      background-color: #f5f5f5; }
    .EmailPreview-main .UserPortalCredentials,
    .EmailPreview-main .LicensesList {
      border: 1px solid #f5f5f5;
      border-bottom: none;
      border-top: 5px solid #47c6ef; }
      .EmailPreview-main .UserPortalCredentials .section-title,
      .EmailPreview-main .LicensesList .section-title {
        font-size: 12px;
        font-weight: bold;
        line-height: 20px;
        padding: 10px 20px 0px;
        text-transform: uppercase; }
    .EmailPreview-main .variable-item {
      padding: 0 20px 0px;
      margin-bottom: 6px; }
      .EmailPreview-main .variable-item .value {
        font-size: 14px;
        line-height: 14px;
        font-weight: 500; }
      .EmailPreview-main .variable-item .label {
        font-size: 12px;
        line-height: 20px;
        color: #525462; }
    .EmailPreview-main .UserPortalCredentials {
      padding-bottom: 24px; }
      .EmailPreview-main .UserPortalCredentials .section-title {
        margin-bottom: 16px; }
    .EmailPreview-main .LicensesList .section-title {
      margin-bottom: 16px; }
    .EmailPreview-main .LicensesList .license {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 2px solid #f5f5f5;
      padding: 0 20px 0px; }
    .EmailPreview-main .LicensesList .license-item-wrapper {
      width: 50%; }
      .EmailPreview-main .LicensesList .license-item-wrapper .license-item {
        max-width: 170px;
        margin-right: 40px;
        margin-bottom: 10px; }
        .EmailPreview-main .LicensesList .license-item-wrapper .license-item .value {
          font-size: 14px;
          line-height: 14px;
          font-weight: 500; }
        .EmailPreview-main .LicensesList .license-item-wrapper .license-item .label {
          font-size: 12px;
          line-height: 20px;
          color: #525462; }
    .EmailPreview-main .license-custom-fields {
      width: 100%;
      margin-bottom: 10px; }
      .EmailPreview-main .license-custom-fields .license-custom-fields-title {
        font-size: 12px;
        line-height: 14px;
        text-align: left;
        color: #464854; }
      .EmailPreview-main .license-custom-fields .custom-field-item {
        margin-top: 2px; }
      .EmailPreview-main .license-custom-fields .custom-field-name {
        font-size: 12px;
        word-break: break-all; }
      .EmailPreview-main .license-custom-fields .custom-field-divider {
        font-size: 12px;
        margin-right: 5px; }
      .EmailPreview-main .license-custom-fields .custom-field-value {
        font-size: 12px;
        word-break: break-all; }

.EmailConfiguration-sender {
  margin-bottom: 20px; }
  .EmailConfiguration-sender .Label {
    margin-top: 0; }
  .EmailConfiguration-sender .email {
    font-size: 14px; }

.EmailConfiguration-title {
  margin-bottom: 20px;
  margin-top: 20px; }

.EmailConfiguration .Notice {
  margin-top: 0; }

.EmailConfiguration .test-email {
  margin-bottom: 0px; }
  .EmailConfiguration .test-email .Label {
    margin-top: 0; }
  .EmailConfiguration .test-email .test-email-input-wrapper {
    display: flex;
    align-items: center; }
    .EmailConfiguration .test-email .test-email-input-wrapper .Button {
      margin: 0; }
    .EmailConfiguration .test-email .test-email-input-wrapper .TextInput {
      width: 100%; }
      .EmailConfiguration .test-email .test-email-input-wrapper .TextInput input {
        max-width: 100%; }

.EmailConfiguration .Wrapper {
  display: flex;
  flex-wrap: wrap; }

.EmailConfiguration .Configurator {
  margin-bottom: 20px;
  min-width: 540px;
  max-width: 540px;
  padding-right: 40px; }
  .EmailConfiguration .Configurator .event-selector .Label {
    margin-top: 0; }
  .EmailConfiguration .Configurator .TextInput input {
    max-width: 100%; }
  .EmailConfiguration .Configurator .TextArea textarea {
    max-width: 100%;
    min-height: 150px; }
  .EmailConfiguration .Configurator .Selector {
    max-width: 100%; }
  .EmailConfiguration .Configurator .form-row.checkbox {
    margin: 14px 0 20px; }
  .EmailConfiguration .Configurator .buttons-container {
    margin-top: 30px; }
    .EmailConfiguration .Configurator .buttons-container .Button {
      margin-right: 40px; }

.flex-row-toggle {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  grid-gap: 16px;
  gap: 16px; }

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 20px; }

.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s; }

.slider:before {
  position: absolute;
  content: '';
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s; }

input:checked + .slider {
  background-color: #2196f3; }

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3; }

input:checked + .slider:before {
  transform: translateX(22px); }

/* Rounded sliders */
.slider.round {
  border-radius: 20px; }

.slider.round:before {
  border-radius: 50%; }


.ResendForm-eventName {
  font-weight: 500; }

.ResendForm-errorNotice {
  margin-top: 20px;
  color: #525462;
  font-size: 14px; }

.ResendForm-main {
  margin-top: 20px; }
  .ResendForm-main .Label {
    margin-top: 8px; }

.NotificationPolicyForm .form-row .Selector {
  max-width: 100%; }

.NotificationPolicyForm .form-row .TextInput input {
  max-width: 100%; }

.NotificationPolicyForm .form-row .Checkbox {
  margin: 10px 0 0; }

.NotificationPolicyForm .form-row.product-select {
  margin-bottom: 10px; }
  .NotificationPolicyForm .form-row.product-select .Label {
    margin-top: 4px; }

.EndpointForm-input-row .TextInput input {
  max-width: 100%; }

.WebhooksList .list-header .Button {
  margin-bottom: 0; }

.RolloverKeyForm-key {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 15px; }

.RolloverKeyForm-desc {
  margin-bottom: 20px;
  font-size: 14px; }

.WebhookPage .section-heading {
  margin: 20px 0 10px;
  font-weight: 500; }

.WebhookPage .subheading {
  font-weight: 500;
  font-size: 15px; }

.WebhookPage .events-list {
  display: flex;
  flex-direction: column;
  padding-inline-start: 0; }
  .WebhookPage .events-list .event-item {
    list-style-type: square;
    font-size: 14px;
    font-weight: 400;
    width: 40%;
    margin-bottom: 5px;
    margin-left: 20px; }

.WebhookPage .SubComponent-webhook-history {
  padding: 0 20px;
  margin-bottom: 30px; }
  .WebhookPage .SubComponent-webhook-history-heading {
    margin-bottom: 10px;
    font-weight: 500; }

.ActivityAnalytics {
  padding-bottom: 50px; }
  .ActivityAnalytics-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 10px; }
  .ActivityAnalytics .mrl .mrl-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500; }
  .ActivityAnalytics .mrl .ProgressBar {
    margin-bottom: 20px; }

.Dashboard .dashboard-navigation {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .Dashboard .dashboard-navigation p.multi {
    display: flex;
    justify-content: space-between;
    width: 100%; }

.Dashboard-activity {
  margin-bottom: 120px;
  max-width: 1100px; }
  .Dashboard-activity .Subtitle {
    margin-top: 30px;
    margin-bottom: 0; }
  .Dashboard-activity .chart-container {
    margin-top: 20px; }
    .Dashboard-activity .chart-container .recharts-legend-wrapper {
      margin-left: 40px !important;
      bottom: -10px !important; }
      .Dashboard-activity .chart-container .recharts-legend-wrapper li {
        margin-right: 20px !important; }

.LicensesImport .template-link {
  margin: 10px 0; }
  .LicensesImport .template-link a {
    font-size: 14px; }

.LicensesImport .docs-link {
  display: flex; }
  .LicensesImport .docs-link p {
    margin: 0;
    font-size: 14px;
    color: #525462; }
  .LicensesImport .docs-link a {
    color: #525462;
    font-size: 14px;
    margin-left: 3px; }

.LicensesImport-list {
  margin-top: 20px; }
  .LicensesImport-list .heading {
    margin-bottom: 10px;
    font-weight: 500; }

.LicensesFeaturesImport .template-link {
  margin: 10px 0; }
  .LicensesFeaturesImport .template-link a {
    font-size: 14px; }

.LicensesFeaturesImport .docs-link {
  display: flex; }
  .LicensesFeaturesImport .docs-link p {
    margin: 0;
    font-size: 14px;
    color: #525462; }
  .LicensesFeaturesImport .docs-link a {
    color: #525462;
    font-size: 14px;
    margin-left: 3px; }

.LicensesFeaturesImport-list {
  margin-top: 20px; }
  .LicensesFeaturesImport-list .heading {
    margin-bottom: 10px;
    font-weight: 500; }

.BulkActionsDropdown {
  position: relative;
  display: inline-block; }
  .BulkActionsDropdown-trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 3px;
    border: 1px solid #e2e8f0;
    background-color: #fff;
    color: #4a5568;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 150px; }
    .BulkActionsDropdown-trigger:hover:not(.is-disabled) {
      background-color: #f7fafc;
      border-color: #cbd5e0; }
    .BulkActionsDropdown-trigger.is-disabled {
      background-color: #f7fafc;
      color: #a0aec0;
      cursor: not-allowed;
      border-color: #edf2f7; }
  .BulkActionsDropdown-chevron {
    width: 16px;
    height: 16px;
    margin-left: 8px; }
  .BulkActionsDropdown-menu {
    position: absolute;
    left: 0;
    margin-top: 8px;
    min-width: 200px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border: 1px solid #e2e8f0;
    z-index: 10; }
  .BulkActionsDropdown-menuContent {
    padding: 4px 0; }
  .BulkActionsDropdown-menuItem {
    display: block;
    width: 100%;
    text-align: left;
    padding: 8px 16px;
    font-size: 14px;
    color: #4a5568;
    background: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease; }
    .BulkActionsDropdown-menuItem:hover {
      background-color: #f7fafc;
      color: #2d3748; }
    .BulkActionsDropdown-menuItem:not(:last-child) {
      border-bottom: 1px solid #f0f4f8; }

.LicenseList .list-header {
  margin-bottom: 0; }

.LicenseList-filters .AdvancedSearch-btn {
  -webkit-appearance: none;
  appearance: none;
  font-size: 14px;
  color: #2e86de;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  outline: none;
  margin-top: 10px;
  padding: 0;
  display: flex;
  align-items: center; }
  .LicenseList-filters .AdvancedSearch-btn svg {
    margin-right: 5px; }

.LicenseList-filters .AdvancedSearch-wrapper {
  overflow: visible;
  transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1);
  -webkit-animation-delay: 0;
  -webkit-animation-duration: 400ms;
  -webkit-animation-name: visibleOverflow;
  -webkit-animation-timing-function: ease-in;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-delay: 0;
  -moz-animation-duration: 400ms;
  -moz-animation-name: visibleOverflow;
  -moz-animation-timing-function: ease-in;
  -moz-animation-fill-mode: forwards;
  -o-animation-delay: 0;
  -o-animation-duration: 400ms;
  -o-animation-name: visibleOverflow;
  -o-animation-timing-function: ease-in;
  -o-animation-fill-mode: forwards;
  animation-delay: 0;
  animation-duration: 400ms;
  animation-name: visibleOverflow;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  margin-top: 5px; }
  .LicenseList-filters .AdvancedSearch-wrapper.hide {
    overflow: hidden;
    padding-bottom: 0px;
    -webkit-animation-delay: 0;
    -webkit-animation-duration: 10ms;
    -webkit-animation-name: hiddenOverflow;
    -webkit-animation-timing-function: ease-in;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0;
    -moz-animation-duration: 10ms;
    -moz-animation-name: hiddenOverflow;
    -moz-animation-timing-function: ease-in;
    -moz-animation-fill-mode: forwards;
    -o-animation-delay: 0;
    -o-animation-duration: 10ms;
    -o-animation-name: hiddenOverflow;
    -o-animation-timing-function: ease-in;
    -o-animation-fill-mode: forwards;
    animation-delay: 0;
    animation-duration: 10ms;
    animation-name: hiddenOverflow;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards;
    margin-top: 0; }

.LicenseList-filters .AdvancedSearch {
  display: block;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  margin-top: 3px;
  max-height: 500px;
  opacity: 1;
  transition: all 0.4s cubic-bezier(0.25, 1, 0.5, 1); }
  .LicenseList-filters .AdvancedSearch.hide {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transform: translateY(-230px); }
  .LicenseList-filters .AdvancedSearch-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .LicenseList-filters .AdvancedSearch-fields .filter-section {
      width: 48%;
      padding: 0 0 0 20px; }
      .LicenseList-filters .AdvancedSearch-fields .filter-section .CheckboxSelector {
        max-width: 200px; }
      .LicenseList-filters .AdvancedSearch-fields .filter-section .DateInput {
        max-width: 200px;
        min-width: 200px; }
        .LicenseList-filters .AdvancedSearch-fields .filter-section .DateInput input {
          max-width: 200px;
          min-width: 200px; }
  .LicenseList-filters .AdvancedSearch-actions {
    margin-top: 20px;
    background-color: #f0f0f0;
    width: 100%;
    padding: 5px 0 5px 20px; }
    .LicenseList-filters .AdvancedSearch-actions .Button {
      margin-right: 40px;
      margin-top: 10px;
      margin-bottom: 10px; }

.LicenseList-filters .Filters-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 10px; }

.HardwareKeys .list-header .main-action {
  display: flex;
  flex-direction: column; }
  .HardwareKeys .list-header .main-action .read-instruction {
    margin-top: 6px;
    font-size: 13px; }

.ExistingOrderDetails .order-data {
  display: flex; }
  .ExistingOrderDetails .order-data div {
    margin-right: 20px; }

.CustomerStep-form .form-row {
  display: flex;
  flex-wrap: wrap; }
  .CustomerStep-form .form-row .form-column {
    margin-right: 40px; }

.CustomerStep-form .order-details .is-customer-manager {
  margin-top: 10px;
  margin-bottom: 10px;
  flex-direction: column; }

.CustomerStep-form .customer-details h4 {
  margin-bottom: 0; }

.CustomerStep-form .customer-details .radio-btns {
  display: flex; }
  .CustomerStep-form .customer-details .radio-btns .RadioBtn {
    margin-right: 40px; }

.CustomerStep-form .customer-details .customer-details-input-fields .existing-customer-selector {
  margin-top: 20px; }
  .CustomerStep-form .customer-details .customer-details-input-fields .existing-customer-selector .Selector {
    max-width: 350px; }
  .CustomerStep-form .customer-details .customer-details-input-fields .existing-customer-selector .existing-customer {
    display: flex; }
    .CustomerStep-form .customer-details .customer-details-input-fields .existing-customer-selector .existing-customer .search-selector {
      min-width: 150px; }
    .CustomerStep-form .customer-details .customer-details-input-fields .existing-customer-selector .existing-customer .customer-selector .Selector {
      min-width: 350px; }

.CustomerStep-form .customer-details .customer-details-input-fields .new-customer-form .form-row {
  display: flex; }
  .CustomerStep-form .customer-details .customer-details-input-fields .new-customer-form .form-row .form-column {
    margin: 0 20px 0 0; }
    .CustomerStep-form .customer-details .customer-details-input-fields .new-customer-form .form-row .form-column input {
      max-width: 200px;
      min-width: 200px; }
    .CustomerStep-form .customer-details .customer-details-input-fields .new-customer-form .form-row .form-column.wide input {
      max-width: 310px;
      min-width: 310px; }
    .CustomerStep-form .customer-details .customer-details-input-fields .new-customer-form .form-row .form-column.radio-btns input {
      min-width: unset; }
    .CustomerStep-form .customer-details .customer-details-input-fields .new-customer-form .form-row .form-column .PhoneNumberInput input {
      max-width: 152px;
      min-width: 15px; }

.CustomerStep-actions {
  margin-top: 30px; }

.CustomFieldsTab {
  width: 100%; }

.ProductFeaturesTab {
  width: 100%; }

.SelectProductFormTitle {
  display: flex;
  align-items: center; }
  .SelectProductFormTitle .confirm-btn {
    margin-left: auto; }

.SelectProductForm .DescriptionTable {
  width: 100%;
  margin-top: -20px; }

.SelectProductForm .react-tabs__tab {
  font-size: 14px;
  padding: 6px 30px; }

.SelectProductForm-main {
  display: flex;
  flex-wrap: wrap; }
  .SelectProductForm-main .sections-wrapper {
    width: 40%; }
    .SelectProductForm-main .sections-wrapper:first-of-type {
      margin-right: 10%; }
  .SelectProductForm-main section {
    margin-bottom: 30px; }
    .SelectProductForm-main section .section-header {
      font-weight: 600;
      margin-bottom: 20px;
      color: #464854;
      font-size: 18px; }
      .SelectProductForm-main section .section-header.first {
        margin-top: 0; }
    .SelectProductForm-main section .section-row .DateInput {
      max-width: 400px;
      min-width: unset;
      width: 100%; }
      .SelectProductForm-main section .section-row .DateInput input {
        max-width: unset;
        min-width: unset; }
    .SelectProductForm-main section .section-row .InputErrorMessage {
      max-width: 400px; }
    .SelectProductForm-main section .section-row .TextInput input,
    .SelectProductForm-main section .section-row .NumberInput input {
      max-width: 400px; }
    .SelectProductForm-main section .section-row .Checkbox {
      margin-top: 10px; }
    .SelectProductForm-main section .section-row.initial-checkbox .Checkbox {
      margin-top: 0; }
    .SelectProductForm-main section .section-row.row-expanded {
      margin-bottom: 10px; }
      .SelectProductForm-main section .section-row.row-expanded .Label {
        margin-top: 6px;
        margin-bottom: 10px; }
      .SelectProductForm-main section .section-row.row-expanded .radio-btns.radio-btns-overages {
        margin-top: -5px; }
      .SelectProductForm-main section .section-row.row-expanded .radio-btns .RadioBtn {
        margin-top: 14px;
        margin-bottom: 6px; }
        .SelectProductForm-main section .section-row.row-expanded .radio-btns .RadioBtn:first-of-type {
          margin-top: 12px; }
        .SelectProductForm-main section .section-row.row-expanded .radio-btns .RadioBtn label {
          font-weight: 500;
          font-size: 13px;
          color: #525462; }
      .SelectProductForm-main section .section-row.row-expanded.time-limited-row, .SelectProductForm-main section .section-row.row-expanded.subscription-expanded {
        margin-bottom: 10px; }
      .SelectProductForm-main section .section-row.row-expanded.expanded-consumption-row .RadioBtn {
        margin-top: 6px;
        margin-bottom: 6px; }
      .SelectProductForm-main section .section-row.row-expanded .DateInput {
        max-width: 400px;
        min-width: unset;
        width: 100%; }
        .SelectProductForm-main section .section-row.row-expanded .DateInput input {
          max-width: unset;
          min-width: unset; }
      .SelectProductForm-main section .section-row.row-expanded .Notice {
        max-width: 380px; }
    .SelectProductForm-main section .section-row.row-max-license-users-checkbox .Checkbox {
      margin-top: 4px;
      margin-bottom: 10px; }
    .SelectProductForm-main section .section-row .flex-row {
      display: flex;
      flex-wrap: wrap;
      max-width: 400px; }
      @media screen and (max-width: 1200px) {
        .SelectProductForm-main section .section-row .flex-row {
          align-items: flex-start; } }
      .SelectProductForm-main section .section-row .flex-row .NumberInput {
        flex-grow: 1;
        margin-right: 10px; }
        @media screen and (max-width: 1200px) {
          .SelectProductForm-main section .section-row .flex-row .NumberInput {
            max-width: unset;
            width: 50%;
            margin-right: 0; } }
        .SelectProductForm-main section .section-row .flex-row .NumberInput input {
          min-width: unset !important;
          margin-right: 10px; }
          @media screen and (max-width: 1200px) {
            .SelectProductForm-main section .section-row .flex-row .NumberInput input {
              width: 95% !important;
              margin-right: 0; } }
      .SelectProductForm-main section .section-row .flex-row .Selector {
        flex-grow: 1; }
        @media screen and (max-width: 1200px) {
          .SelectProductForm-main section .section-row .flex-row .Selector {
            max-width: unset;
            width: 50%; } }
        .SelectProductForm-main section .section-row .flex-row .Selector .selector-inner__control {
          min-height: calc(32px + 2px); }

.PolicySelector .section-row .DescriptionTable {
  width: unset; }

.SelectBundleFormTitle {
  display: flex;
  align-items: center; }
  .SelectBundleFormTitle .confirm-btn {
    margin-left: auto; }

.SelectBundleForm .bundle-prod-warning {
  display: flex;
  align-items: center; }
  .SelectBundleForm .bundle-prod-warning span {
    margin-right: 4px; }

.SelectBundleForm .DescriptionTable {
  width: 100%;
  margin-top: -20px; }

.SelectBundleForm .section-row {
  margin-bottom: 20px; }
  .SelectBundleForm .section-row .bundle-products-loading {
    margin-top: 60px; }
  .SelectBundleForm .section-row .bundle-products-list .bundle-products-heading {
    font-weight: 600;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 24px;
    padding-bottom: 10px; }

.ProductsStep .bundle-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px; }
  .ProductsStep .bundle-row .Label {
    margin: 0; }
  .ProductsStep .bundle-row .Switcher {
    margin-right: 10px;
    height: 16px; }

.ProductsStep-add-product .Button {
  margin-bottom: 0; }

.ProductsStep-add-product .InputErrorMessage {
  margin-bottom: 6px; }

.ProductsStep-actions {
  margin-top: 30px; }
  .ProductsStep-actions .Button {
    margin-right: 40px; }

.VerifyOrderStep .section-header {
  font-weight: 600;
  margin-bottom: 10px;
  color: #464854;
  font-size: 16px; }
  .VerifyOrderStep .section-header.order-id span, .VerifyOrderStep .section-header.reference span {
    margin-left: 5px; }
  .VerifyOrderStep .section-header.reference {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px; }

.VerifyOrderStep-customer {
  margin-bottom: 40px; }
  .VerifyOrderStep-customer .no-customer-added {
    font-size: 13px;
    font-weight: 500;
    color: #525462;
    opacity: 0.8; }

.VerifyOrderStep-actions .Button {
  margin-right: 40px; }


.RescheduleUpdateForm .scheduled-date-container .Label {
  margin-bottom: 10px; }

.ScheduledUpdateContainer {
  padding: 10px 10px 5px;
  border: 1px solid #e3e3e3;
  border-top: 5px solid #ff9f43;
  margin: 15px 0 10px;
  font-size: 14px;
  background: #ffffff; }
  .ScheduledUpdateContainer-heading {
    font-weight: bold; }
  .ScheduledUpdateContainer-body .DescriptionTable {
    margin: 0;
    border: none; }
  .ScheduledUpdateContainer-actions .Button {
    margin-right: 20px; }

.SubscriptionForm-form .Selector {
  max-width: 400px; }

.SubscriptionForm-form .DateInput {
  max-width: 400px;
  width: 100%; }
  .SubscriptionForm-form .DateInput input {
    max-width: 400px; }

.SubscriptionForm-form .InputErrorMessage {
  margin-bottom: 12px; }

.SubscriptionForm-form .tax-rate-wrapper .Checkbox {
  margin-top: 10px; }

.SubscriptionForm-form .Checkbox .InputErrorMessage {
  margin-bottom: 5px; }

.SubscriptionForm-form .total {
  font-size: 14px;
  color: #525462;
  padding: 12px 0; }

.SubscriptionForm-form .billing-trial-end {
  margin-bottom: 16px; }

.ScheduledUpdateForm-form .scheduled-date-container {
  padding: 10px;
  border-bottom: 1px solid #707070; }
  .ScheduledUpdateForm-form .scheduled-date-container .Label {
    margin-bottom: 10px; }

.ScheduledUpdateForm-form .Selector {
  max-width: 400px; }

.ScheduledUpdateForm-form .DateInput {
  max-width: 400px;
  width: 100%; }
  .ScheduledUpdateForm-form .DateInput input {
    max-width: 400px; }

.ScheduledUpdateForm-form .InputErrorMessage {
  margin-bottom: 12px; }

.ScheduledUpdateForm-form .tax-rate-wrapper .Checkbox {
  margin-top: 10px; }

.ScheduledUpdateForm-form .Checkbox .InputErrorMessage {
  margin-bottom: 5px; }

.ScheduledUpdateForm-form .total {
  font-size: 14px;
  color: #525462;
  padding: 12px 0; }


.StandardSubscriptionForm-form .Selector {
  max-width: 400px; }

.StandardSubscriptionForm-form .DateInput {
  max-width: 400px;
  width: 100%; }
  .StandardSubscriptionForm-form .DateInput input {
    max-width: 400px; }

.StandardSubscriptionForm-form .InputErrorMessage {
  margin-bottom: 12px; }

.StandardSubscriptionForm-form .tax-rate-wrapper .Checkbox {
  margin-top: 10px; }

.StandardSubscriptionForm-form .Checkbox .InputErrorMessage {
  margin-bottom: 5px; }

.StandardSubscriptionForm-form .total {
  font-size: 14px;
  color: #525462;
  padding: 12px 0; }

.ChangeTrialForm-radiobtns .RadioBtn:last-of-type {
  margin-top: 10px; }

.ChangeTrialForm-remaining {
  margin-top: 30px; }
  .ChangeTrialForm-remaining .DescriptionTable {
    max-width: 300px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 0;
    padding: 5px;
    background: #f5f5f5;
    border-color: #ccc; }
    .ChangeTrialForm-remaining .DescriptionTable tr td:first-of-type {
      width: 50%; }


.AddCompanyFeatureForm .TextInput input,
.AddCompanyFeatureForm .NumberInput input {
  max-width: 400px; }

.CompanyFeatures .list-header {
  margin-bottom: 10px; }
  .CompanyFeatures .list-header div .Button {
    margin-top: 0; }

.DisableDateForm .form-inner {
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 1200px) {
    .DisableDateForm .form-inner {
      flex-direction: column;
      align-items: flex-start; } }
  .DisableDateForm .form-inner .Subtitle {
    margin: 10px 0; }
  .DisableDateForm .form-inner .form-row {
    width: 100%; }
  .DisableDateForm .form-inner .DateInput input {
    max-width: unset;
    min-width: unset;
    box-sizing: border-box; }
  .DisableDateForm .form-inner .hidden {
    visibility: hidden; }

.Settings section {
  margin-bottom: 10px; }

.Settings .heading {
  font-weight: 500; }

.Settings .description {
  color: #525462;
  font-size: 14px;
  margin-bottom: 10px; }

.Settings .actions .Button {
  margin-right: 20px; }

.Settings-data-wipe .data-type-selector {
  margin-bottom: 10px; }

.PricingPlanForm-form .Selector {
  max-width: 300px; }

.PricingPlanSettings-subtitle {
  font-weight: 500;
  font-size: 16px; }

.CouponForm-form .form-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  @media screen and (max-width: 1200px) {
    .CouponForm-form .form-inner {
      flex-direction: column;
      align-items: center; } }
  .CouponForm-form .form-inner .Subtitle {
    margin: 10px 0; }
  .CouponForm-form .form-inner .TextInput input,
  .CouponForm-form .form-inner .NumberInput input {
    max-width: unset;
    min-width: unset;
    box-sizing: border-box; }
  .CouponForm-form .form-inner .hidden {
    visibility: hidden; }
  .CouponForm-form .form-inner .left {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .CouponForm-form .form-inner .left {
        margin-right: 0;
        min-height: unset;
        width: 100%; } }
    .CouponForm-form .form-inner .left .Checkbox {
      margin-top: 0 !important; }
    .CouponForm-form .form-inner .left .Selector {
      max-width: unset; }
    .CouponForm-form .form-inner .left .trial-checkbox {
      margin-top: 20px; }
  .CouponForm-form .form-inner .divider {
    display: none;
    margin: 30px 30px 10px;
    background: #e3e3e3;
    width: 1px; }
    @media screen and (max-width: 1200px) {
      .CouponForm-form .form-inner .divider {
        display: none; } }
    .CouponForm-form .form-inner .divider.hidden {
      display: block;
      visibility: hidden; }
    .CouponForm-form .form-inner .divider.active {
      display: block; }
      @media screen and (max-width: 1200px) {
        .CouponForm-form .form-inner .divider.active {
          display: none; } }
  .CouponForm-form .form-inner .right {
    flex: 1 1; }
    @media screen and (max-width: 1200px) {
      .CouponForm-form .form-inner .right {
        margin-left: 0;
        min-height: unset;
        width: 100%; } }
    .CouponForm-form .form-inner .right.hidden {
      visibility: hidden; }
    .CouponForm-form .form-inner .right .Selector {
      max-width: unset; }
    .CouponForm-form .form-inner .right .DateInput {
      max-width: unset;
      min-width: unset;
      width: 100%; }
      .CouponForm-form .form-inner .right .DateInput input {
        max-width: unset;
        min-width: unset; }
    .CouponForm-form .form-inner .right .flex-row {
      display: flex;
      flex-wrap: wrap; }
      @media screen and (max-width: 1200px) {
        .CouponForm-form .form-inner .right .flex-row {
          align-items: flex-start; } }
      .CouponForm-form .form-inner .right .flex-row .NumberInput {
        flex-grow: 1;
        margin-right: 10px; }
        @media screen and (max-width: 1200px) {
          .CouponForm-form .form-inner .right .flex-row .NumberInput {
            max-width: unset;
            width: 50%;
            margin-right: 0; } }
        .CouponForm-form .form-inner .right .flex-row .NumberInput input {
          min-width: unset !important;
          margin-right: 10px; }
          @media screen and (max-width: 1200px) {
            .CouponForm-form .form-inner .right .flex-row .NumberInput input {
              width: 95% !important;
              margin-right: 0; } }
      .CouponForm-form .form-inner .right .flex-row .Selector {
        flex-grow: 1; }
        @media screen and (max-width: 1200px) {
          .CouponForm-form .form-inner .right .flex-row .Selector {
            max-width: unset;
            width: 50%; } }
        .CouponForm-form .form-inner .right .flex-row .Selector .selector-inner__control {
          min-height: calc(32px + 2px); }


.TaxRateForm .Selector {
  max-width: 300px; }

.TaxRateForm .custom-type-input {
  margin-top: 10px; }


.BillingConfiguration .section-header {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px; }

.BillingConfiguration .section-body {
  margin-top: 2px;
  margin-bottom: 25px; }
  .BillingConfiguration .section-body .list-header {
    margin-bottom: 5px; }

.CompanySidebar {
  margin: 20px 10px 40px 20px; }
  .CompanySidebar .NavLink {
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    color: #464854; }
    .CompanySidebar .NavLink header {
      display: flex;
      align-items: center;
      padding: 10px 0;
      margin: 10px 0; }
      .CompanySidebar .NavLink header .NavLink-icon {
        display: flex;
        align-items: center;
        width: 30px;
        margin-right: 10px; }
    .CompanySidebar .NavLink:hover {
      color: #111; }
    .CompanySidebar .NavLink-active {
      color: #2e86de; }
      .CompanySidebar .NavLink-active:hover {
        color: #2e86de; }
      .CompanySidebar .NavLink-active .NavLink-submenu {
        display: block; }
      .CompanySidebar .NavLink-active svg {
        fill: #2e86de; }
      .CompanySidebar .NavLink-active .CompanyConfig svg {
        fill: none;
        stroke: #2e86de; }

.version-cont__sidebar {
  position: fixed;
  bottom: 0;
  width: 249px;
  padding: 5px;
  background-color: #f5f5f5;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.08); }
  .version-cont__sidebar a {
    color: #525462;
    font-size: 11px;
    text-align: center;
    margin: 0;
    text-decoration: underline; }

.Company-plan {
  font-size: 12px;
  padding: 3px 5px 3px 20px;
  background-color: #f4f8fc; }
  .Company-plan .plan-type {
    color: #525462;
    font-weight: 500; }
  .Company-plan .acc-type {
    margin-top: 5px;
    font-weight: 400;
    color: #525462; }

a {
  color: inherit; }

.list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px; }
  .list-header > div {
    display: flex; }
    .list-header > div:first-of-type .Button {
      margin-right: 10px;
      margin-bottom: 0; }
    .list-header > div:first-of-type .CheckboxSelector.Selector {
      width: 160px;
      margin-left: 20px; }
    .list-header > div:last-of-type .Button {
      margin-right: 10px;
      margin-top: 0;
      margin-bottom: 0;
      flex-shrink: 0; }
    .list-header > div form {
      display: flex;
      align-items: center;
      position: relative; }
      .list-header > div form button[type='submit'] {
        -webkit-appearance: none;
        appearance: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        border-radius: 0 3px 3px 0;
        margin: 0;
        padding: 0 20px;
        position: relative;
        height: 34px;
        cursor: pointer;
        outline: 0;
        z-index: 2; }
      .list-header > div form .TextInput input {
        padding: 0 10px 0 15px;
        width: 250px;
        min-width: 350px; }
      .list-header > div form .TextInput .InputErrorMessage {
        display: none; }
      .list-header > div form button[type='button'] {
        -webkit-appearance: none;
        appearance: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        right: 70px;
        width: 40px;
        height: 34px;
        font-size: 20px;
        text-align: center;
        border: 0;
        background: transparent;
        outline: 0;
        opacity: 0.5;
        z-index: 1; }
        .list-header > div form button[type='button']:hover {
          opacity: 1; }
    .list-header > div .Selector {
      width: 150px;
      font-size: 12px;
      font-weight: 500; }
      .list-header > div .Selector .selector-inner__control {
        min-height: 34px; }
    .list-header > div .search-selector .Selector {
      width: 210px !important; }
    .list-header > div .columns-display-select .Selector {
      width: 190px !important; }

::-webkit-scrollbar {
  height: 16px;
  overflow: visible;
  width: 12px; }

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  border: solid transparent;
  border-width: 1px 1px 1px 6px;
  min-height: 28px;
  padding: 100px 0 0;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07); }

::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0 0 0 4px; }

.App {
  font-family: "IBM Plex Sans", "Helvetica", "Arial", sans-serif;
  color: #464854; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fafafa;
  position: relative;
}

