.OrderManagerForm {
  .existing-manager {
    margin: 10px 0 20px;

    .search {
      display: flex;
      align-items: center;
    }
  }
}
