.rotate-secret-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.rotate-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
}

.time-input-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.time-input {
  width: 100px;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.time-input-label {
  display: flex;
  flex-direction: column;
}

.time-input-span {
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.rotate-input-wrapper {
  margin-bottom: 1.5rem;
}

.rotate-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.init-checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
