.BusinessStarterPlanTypeBox {
  .PlanTypeBox-trial {
    margin-top: 10px;
    margin-bottom: 14px;
    font-weight: bold;
    color: #13b0fc;
  }
  .PlanTypeBox-price {
    margin-bottom: 40px;
  }
}
