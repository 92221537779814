@import '~styles/variables';

.PlanUpgradeContainer {
  &-wrapper {
    display: flex;
    max-width: 1120px;
    position: relative;

    .form-container {
      flex: 1;
      max-width: 400px;
      margin-right: 40px;

      .form-row {
        &.first {
          .Label {
            margin-top: 0;
          }
        }

        &.submit-btn {
          margin-bottom: 20px;
        }

        .payment-method-card {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          padding: 30px 20px 10px;
          border: 1px solid #ededed;
          border-radius: 4px;
          min-width: 200px;

          .card-details {
            display: flex;
            flex-direction: column;
            padding: 0 20px;
            flex: 1;
            margin-bottom: 20px;

            .card-last4 {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 10px;
              font-size: 13px;

              span {
                display: flex;
                align-items: center;
                margin-right: 5px;
              }
            }

            .card-expiry {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 10px;
              font-size: 10px;
              color: $text-color-secondary;

              .card-expiration-date {
                margin-left: 3px;
              }
            }
          }
        }
      }
    }
  }
}
