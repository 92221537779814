@import '~styles/variables';

.LicenseAPI {
  .license-keys-list {
    .key-products-row {
      display: flex;
      flex-wrap: wrap;

      a {
        margin-right: 5px;

        &:after {
          content: ',';
        }

        &:last-of-type {
          &::after {
            content: none;
          }
        }
      }
    }

    .key-container {
      display: flex;
      align-items: center;

      span {
        margin-right: auto;
      }

      .clipboard-btn {
        display: inline-flex;
        align-items: center;
        z-index: 2;
        background-color: transparent;
        opacity: 0.5;
        -webkit-appearance: none;
        appearance: none;
        border: none;
        width: 34x;
        cursor: pointer;
        outline: none;
        margin-left: 10px;

        &:hover {
          background-color: #e2e2e2;
          opacity: 1;
        }
      }
    }
  }
}

.title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.inline-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
  align-items: baseline;
}

.oauth-rotation-info {
  font-size: 12px;
  opacity: 0.85;
}
