@mixin flexMiddleCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin hasFocus {
  &:focus {
    outline: 1px dashed #777;
  }
}

@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}

@mixin opacity($value) {
  $IEValue: $value * 100;
  opacity: $value;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=' + $IEValue + ')';
  filter: alpha(opacity=$IEValue);
}

@mixin keyframe($animation_name) {
  @-webkit-keyframes #{$animation_name} {
    @content;
  }

  @-moz-keyframes #{$animation_name} {
    @content;
  }

  @-o-keyframes #{$animation_name} {
    @content;
  }

  @keyframes #{$animation_name} {
    @content;
  }
}

@mixin animate($animation, $duration, $easing, $delay) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-timing-function: $easing;
  -webkit-animation-fill-mode: forwards;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-timing-function: $easing;
  -moz-animation-fill-mode: forwards;

  -o-animation-delay: $delay;
  -o-animation-duration: $duration;
  -o-animation-name: $animation;
  -o-animation-timing-function: $easing;
  -o-animation-fill-mode: forwards;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-timing-function: $easing;
  animation-fill-mode: forwards;
}
